<template>
  <!-- 第一行 -->
  <div class="clo-1">
    <!-- 第一列 -->
    <div>
      <div class="main-item1">
        <div class="title">
          <h3>数据概览</h3>
        </div>
        <div class="main-item-process" style="position: relative;">
          <el-row>
            <el-col :span="12" class="main-item-process-left">产品种类：{{data_obj.pro_sum}}</el-col>
            <el-col :span="12" class="main-item-process-right">投入市场：{{data_obj.market}} / {{(data_obj.market/data_obj.pro_sum*100).toFixed(0)}}%</el-col>
          </el-row>
          <el-progress :percentage="100" status="success" />
        </div>
        <div class="main-item-list" >
          <div v-for="(item) in data" :key="item.key">
            <h6>{{item.title}}</h6>
            <h3>{{ item.num }}</h3>
          </div>
        </div>
      </div>
    </div>
    <!-- 第二列 -->
    <div class="center">
      <div>
        <div id="dayTrend-chart"></div>
      </div>
    </div>
    <!-- 第三列 -->
    <div>
      <div>
        <div class="selectYeardiv">
          <el-select style="width:90px;" v-model="derysortproduct.select1" class="m-2" placeholder="Select" size="small" @change="selectCycle">
            <el-option
              v-for="item in cycle_options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
          <el-select style="width:100px;" v-model="derysortproduct.select2" class="m-2" placeholder="Select" size="small" @change="selectDimension">
            <el-option
              v-for="item in dimension_options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </div>
        <div id="sort-product"></div>
      </div>
    </div>
  </div>
  <!-- 第二行 -->
  <div class="clo-2">
    <!-- 第一列 -->
    <div>
      <div>
        <!-- 同比增长率 -->
        <div class="selectYeardiv">
          <el-date-picker  size="small" style="width:100px;"
            v-model="defyear"
            type="year"
            :clearable="false"
            placeholder="选择一个年份"
          />
          <el-radio-group v-model="yearonyearselect" size="small" style="vertical-align: top;" @change="yearOnYearChange" >
            <el-radio-button label="数量" />
            <el-radio-button label="金额" />
          </el-radio-group>
        </div>
        <div id="YearOnYear-chart"></div>
      </div>
    </div>
    <!-- 第二列 -->
    <!-- 世界地图销售展示 -->
    <div class="center" id="map-chart"></div>
    <!-- 第三列 -->
    <div>
      <div>
        <div class="selectYeardiv">
          <el-date-picker  size="small" style="width:100px;"
            v-model="defratio"
            type="year"
            :clearable="false"
            placeholder="Pick a year"
          />
          <el-radio-group v-model="ringRatioselect" size="small" style="vertical-align: top;" @change="ringRatioChange" >
            <el-radio-button label="数量" />
            <el-radio-button label="金额" />
          </el-radio-group>
        </div>
        <!-- 环比增长率 -->
        <div id="RingRatio-chart"></div>
      </div>
    </div>
  </div>
</template>

<script>
import {ref,reactive,getCurrentInstance} from "vue"
// 引入legend
import 'echarts/lib/component/legend'
// 引入dataZoom
import 'echarts/lib/component/dataZoom'
const yearonyearselect =ref("金额"),ringRatioselect =ref("金额");
const derysortproduct=reactive({select1:'30',select2:'cost_growth_rate'});

const dimension_desc = ref('desc');//默认降序
const cycle_options = [
  { label: '一周',value: '7'},
  {label: '一个月',value: '30'},
  {label: '一个季度',value: '120'},
  {label: '一年',value: '365'},
  {label: '三年',value: '1095'}
]
const dimension_options = [
  { label: '成本周转率',value: 'cost_growth_rate'},
  {label: '周转天数',value: 'day_growth_rate'},
  {label: '库存周转率',value: 'storage_rate'},
  {label: '销售周转率',value: 'sales_growth_rate'}
]
export default {
    name:'OverView',
    setup() {
      const {appContext} = getCurrentInstance();
      const global = appContext.config.globalProperties;
      const echarts=appContext.config.globalProperties.$echarts;
      const http=appContext.config.globalProperties.$http;
      const defyear=ref((new Date().getFullYear())+'')
      const defratio=ref((new Date().getFullYear())+'')
      const data =reactive([
        {key:'sum_price',title:'销售额',num:0},
        {key:'profit',title:'利润',num:0},
        {key:'profit_ratio',title:'利润率%',num:0},
        {key:'warehouse',title:'仓库',num:0},
        {key:'storage',title:'库存',num:0},
        {key:'no_storage',title:'无库存',num:0},
        {key:'storage_less10',title:'库存<10',num:0},
        {key:'inpunt',title:'入库',num:0},
        {key:'output',title:'出库',num:0},
        {key:'order',title:'成交',num:0},
        {key:'shelf',title:'货架',num:0},
        {key:'customer',title:'客户',num:0},
        {key:'supplier',title:'供应商',num:0},
        {key:'staff',title:'员工',num:0},
        {key:'new_product',title:'新增产品',num:0}
      ])
      //数据概览顶部的产品种类和市场投入
      const data_obj=reactive({pro_sum:0,market:0});
      //加载概览数据
      http.get('/total/overview/get/overviewdata.do').then((res) => {
        let obj = res.data.plant[0];
        for(var d in data){
          data[d].num=obj[data[d].key];
        }
        data_obj.market=obj.market;
        data_obj.pro_sum=obj.pro_sum;
      })
      const table_data=reactive({
        yearOnYearData:{
          current:[],
          SamePeriod:[]
        },
        ringRatioData:{
          current:[],
          previous:[]
        },
        turnover:[],
        mapdata:[]
      });
     return {
      derysortproduct,
      cycle_options,
      dimension_options,
      dimension_desc,
      defyear,
      defratio,
      table_data,
      data,
      data_obj,
      echarts,
      global,
      http,
      yearonyearselect,
      ringRatioselect
     }
    },
    mounted(){
        this.loadDayTrendChart()
        this.loadProductSort()
        this.loadYearOnYear()
        this.loadRingRatio()
        this.loadMap()
    },
    watch:{
      defyear(newVal){//同比选择时间的组件值发生变化时重新获取数据
        if(newVal){
          const that = this;
          let yearonyear = this.echarts.getInstanceByDom(document.getElementById('YearOnYear-chart'));
          yearonyear.showLoading();
          this.http.get('/total/overview/get/yearOnYear.do',{params:{time:new Date(newVal).getFullYear(),cmd:1}}).then((res) => {
            let plant = res.data.plant;
            that.table_data.yearOnYearData=plant;
            yearonyear.hideLoading();
            yearonyear.setOption(
              this.getOptionByYearOnYear(plant,yearonyearselect.value=='数量'?'num':'sum_price')
            )
          })
        }
      },
      defratio(newVal){//环比选择时间的组件值发生变化时重新获取数据
        if(newVal){
          const that = this;
          let ringRatio = this.echarts.getInstanceByDom(document.getElementById('RingRatio-chart'));
          ringRatio.showLoading();
          this.http.get('/total/overview/get/ringRatio.do',{params:{year:new Date(newVal).getFullYear()}}).then((res) => {
            let plant = res.data.plant;
            that.table_data.ringRatioData=plant;
            ringRatio.hideLoading();
            ringRatio.setOption(
              this.getOptionByRingRatio(plant,ringRatioselect.value=='数量'?'num':'sum_price')
            )
          })
        }
      }
    },
    
    methods:{
      //30天销售业绩  
      loadDayTrendChart(){
        this.http.get('/total/overview/get/day30.do').then((res) => {
          let plant = res.data.plant;
          let dayTrendchart= this.echarts.init(
            document.getElementById('dayTrend-chart')
          )
          dayTrendchart.setOption(
            {
                title: {
                  text: '最近销售数据'
                },
                legend: {
                  data: [ '销售额','利润']
                },
                tooltip: {
                  trigger: 'axis',
                  axisPointer: {
                    type: 'cross',
                    label: {
                      backgroundColor: '#6a7985'
                    }
                  }
                },
                toolbox: this.global.echartsToolbox(),
                grid: {
                  left: '3%',
                  right: '4%',
                  bottom: '3%',
                  containLabel: true
                },
                xAxis: [
                  {
                    type: 'category',
                    data:(()=>{
                      let list = [];
                      plant.map((item)=>{
                        list.push(item.time);
                      })
                      return list;
                    })()
                  }
                ],
                yAxis: [
                  {
                    type: 'value'
                  }
                ],
                series: [
                  {
                    type: 'line',
                    stack: 'Total',
                    emphasis: {
                      focus: 'series'
                    },
                    ...this.global.echartLineColor("数量"),
                    name:'数量',
                    data:(()=>{
                      let list = [];
                      plant.map((item)=>{
                        list.push(item.num);
                      })
                      return list;
                    })()
                  },
                  {
                    type: 'line',
                    stack: 'Total',
                    emphasis: {
                      focus: 'series'
                    },
                    ...this.global.echartLineColor("销售额"),
                    name:'销售额',
                    data:(()=>{
                      let list = [];
                      plant.map((item)=>{
                        list.push(item.price);
                      })
                      return list;
                    })()
                  },
                  {
                    type: 'line',
                    stack: 'Total',
                    emphasis: {
                      focus: 'series'
                    },
                    ...this.global.echartLineColor("利润"),
                    name:'利润',
                    data:(()=>{
                      let list = [];
                      plant.map((item)=>{
                        list.push(item.profit);
                      })
                      return list;
                    })()
                  }
                ]
            }
          )
        })
      },
      //产品增长率
      loadProductSort(){
        const that=this;
        let sortproduct = this.echarts.init(
          document.getElementById('sort-product')
        )
        sortproduct.showLoading();
        this.http.get('/total/overview/get/turnover.do',{params:{days:30,orderby:`${that.derysortproduct.select2} ${that.dimension_desc}`}}).then((res) => {
          let plant = res.data.plant;
          that.table_data.turnover=plant;
          sortproduct.hideLoading();
          sortproduct.setOption(that.getSortProduct(that.table_data.turnover,'cost_growth_rate'))
        })
        sortproduct.on('click',function(){
          if(that.dimension_desc=='desc'){
            that.dimension_desc="asc"
          }else{
            that.dimension_desc="desc"
          }
          sortproduct.showLoading();
          that.http.get('/total/overview/get/turnover.do',{params:{days:that.derysortproduct.select1,orderby:`${that.derysortproduct.select2} ${that.dimension_desc}`}}).then((res) => {
            let plant = res.data.plant;
            that.table_data.turnover=plant;
            sortproduct.hideLoading();
            sortproduct.setOption(that.getSortProduct(that.table_data.turnover,that.derysortproduct.select2))
          })
          
        })
      },
      //选择周转时间维度周期
      selectCycle(val){
        const that= this;
        let sortproduct = this.echarts.getInstanceByDom(document.getElementById('sort-product'));
        sortproduct.showLoading();
        this.http.get('/total/overview/get/turnover.do',{params:{days:val,orderby:`${that.derysortproduct.select2} ${that.dimension_desc}`}}).then((res) => {
          let plant = res.data.plant;
          that.table_data.turnover=plant;
          sortproduct.hideLoading();
          sortproduct.setOption(that.getSortProduct(that.table_data.turnover,derysortproduct.select2))
        })
      },
      //切换周转业务
      selectDimension(val){
        const that= this;
        let sortproduct = this.echarts.getInstanceByDom(document.getElementById('sort-product'));
        sortproduct.showLoading();
        this.http.get('/total/overview/get/turnover.do',{params:{days:derysortproduct.select1,orderby:`${val} ${that.dimension_desc}`}}).then((res) => {
          let plant = res.data.plant;
          that.table_data.turnover=plant;
          sortproduct.hideLoading();
          sortproduct.setOption(that.getSortProduct(that.table_data.turnover,val))
        })
      },
      //设置产品周转率参数
      getSortProduct(data,key){
        // const that=this;
        // console.log(inverse);
        // if(inverse==undefined){inverse=true}
        
        return {
            title: {
              text: '周转率'
            },
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                type: 'shadow'
              },
              extraCssText:this.global.$echartsconfig.tooltip.extraCssText,
              formatter(params) {
                let param=params[0],data=param.data;
                let text=`<div style='font-size:12px; margin-left:40px;border: 1px solid white;border-radius: 5px;padding: 5px;'>成本周转率:${data.cost_growth_rate} 周转天数:${data.day_growth_rate}<br/>库存周转率:${data.storage_rate} 销售周转率:${data.sales_growth_rate}</div>`
                let circle = `<span style="display:inline-block;margin-right:5px;border-radius:50%;width:10px;height:10px;left:5px;background-color:`;//小圆点样式
                let data0 = `${circle}${param.color}"></span>${param.seriesName}：  ${param.value}`;//第二行显示
                return `${param.name}<br/>${data0}${text}`;
              }
            },
            dataZoom:[
              {
                yAxisIndex:0,
                type:'inside',
                startValue:0,
                endValue:9
              }
            ],
            toolbox: this.global.echartsToolbox(),
            // legend: {
            //   orient: "vertical",
            //   left: "right",
            //   top: "20%",
            //   textStyle: {
            //     color: "#0043ffa6",
            //     fontSize: 10,
            //   },
            //   itemGap: 4,
            //   itemWidth: 10,
            //   itemHeight: 5,
            //   height: 300,
            // },
            grid: {
              left: '3%',
              right: '4%',
              bottom: '13%',
              top:"10%",
              containLabel: true
            },
            xAxis: {
              type: 'value',
              boundaryGap: [0, 0.01]
            },
            yAxis: {
              type: 'category',
              inverse:true,//排序
              data:(()=>{
                let list = [];
                data.map((item)=>{
                  list.push(item.number);
                })
                return list;
              })()
            },
            series:{
                name:this.getSotrtlegendName(key),
                type: 'bar',
                data:(()=>{
                    let list = [];
                    data.map((item)=>{
                      list.push({value:item[key],cost_growth_rate:item.cost_growth_rate,day_growth_rate:item.day_growth_rate,storage_rate:item.storage_rate,sales_growth_rate:item.sales_growth_rate});
                    })
                    return list;
                  })()
            }
          }
      },
      //获取产品周转率图标中展示的series名称
      getSotrtlegendName(key){
        let name='';
        this.dimension_options.map((item)=>{
          if(item.value==key){
            name = item.label;
          }
        });
        return name;
      },
      //产品同比
      loadYearOnYear(){
        const that =this;
        const echarts = this.echarts
        let yearOnYear = echarts.init(
          document.getElementById('YearOnYear-chart')
        )
        yearOnYear.showLoading();
        this.http.get('/total/overview/get/yearOnYear.do',{params:{time:new Date(this.defyear).getFullYear(),cmd:1}}).then((res) => {
          let plant = res.data.plant;
          that.table_data.yearOnYearData=plant;
          yearOnYear.hideLoading();
          yearOnYear.setOption(
            this.getOptionByYearOnYear(plant,yearonyearselect.value=='数量'?'num':'sum_price')
          )
        })
        yearOnYear.on('click',(obj)=>{//向下钻取
          if(obj.name.indexOf('季度')!=-1&&obj.seriesIndex==1){
            yearOnYear.showLoading();
            this.http.get('/total/overview/get/yearOnYear.do',{params:{time:new Date(this.defyear).getFullYear(),cmd:2}}).then((res) => {
              let plant = res.data.plant;
              that.table_data.yearOnYearData=plant;
              yearOnYear.hideLoading();
              yearOnYear.setOption(
                this.getOptionByYearOnYear(plant,yearonyearselect.value=='数量'?'num':'sum_price')
              )
            })
            // tread.setOption(this.saleTotalOption()) 
          }else if(obj.name.indexOf('月')!=-1&&obj.seriesIndex==1){
            yearOnYear.showLoading();
            this.http.get('/total/overview/get/yearOnYear.do',{params:{time:obj.name.substring(0,7),cmd:3}}).then((res) => {
              let plant = res.data.plant;
              that.table_data.yearOnYearData=plant;
              yearOnYear.hideLoading();
              yearOnYear.setOption(
                this.getOptionByYearOnYear(plant,yearonyearselect.value=='数量'?'num':'sum_price')
              )
            })
          }
          
        })
        
      },
      //产品同比切换数量金额按钮改变值触发事件
      yearOnYearChange(label){
        let yearonyear = this.echarts.getInstanceByDom(document.getElementById('YearOnYear-chart'));
        if(label==='数量'){
          yearonyear.setOption(this.getOptionByYearOnYear(this.table_data.yearOnYearData,"num"));
        }else{
          yearonyear.setOption(this.getOptionByYearOnYear(this.table_data.yearOnYearData,"sum_price"));
        }
      },
      //设置产品同比参数
      getOptionByYearOnYear(data,key){
        const that = this;
        return {
              title: {
                text: '同比增长'
              },
              tooltip: {
                trigger: 'axis',
                confine:true,
                axisPointer: {
                  type: 'shadow',
                  label: {
                    show: true
                  }
                },
                extraCssText:that.global.$echartsconfig.tooltip.extraCssText,
                formatter(params) {
                  let tags = that.yearonyearselect=='数量'?'个':'￥'
                  let param1=params[0], param2=params[1];
                  let obj1=param1.data,obj2=param2.data;
                  let circle1 = `<span style="display:inline-block;margin-right:5px;border-radius:50%;width:10px;height:10px;left:5px;background-color:`;//小圆点样式
                  let data1 = `${circle1}${param1.color}"></span>${param1.seriesName}：  ${param1.value} ${tags}<br/>
                  <div style='border: 1px solid white;border-radius: 5px;padding: 5px;'><span style='font-size:12px;margin-left: 45px;'>交易订单：${obj1.order_num}笔,销售量：${obj1.num}个,销售额:${obj1.sum_price}￥</psan><br/>
                    <span style='font-size:12px;margin-left: 45px;'> 利润:${obj1.profit||0.00} ￥, 利润率:%${obj1.profit_rate||0.00} </span></div><br/>`;//第二行显示
                  let circle2 = `<span style="display:inline-block;margin-right:5px;border-radius:50%;width:10px;height:10px;left:5px;background-color:`;//小圆点样式
                  let data2 = `${circle2}${param2.color}"></span>${param2.seriesName}：  ${param2.value} ${tags}<br/>
                  <div style='border: 1px solid white;border-radius: 5px;padding: 5px;'><span style='font-size:12px;margin-left: 45px;'>交易订单：${obj2.order_num}笔,销售量：${obj2.num}个,销售额:${obj2.sum_price}￥</psan><br/>
                    <span style='font-size:12px;margin-left: 45px;'> 利润:${obj2.profit||0.00} ￥, 利润率:%${obj2.profit_rate||0.00} </span><br/>
                    <span style='font-size:12px;margin-left: 45px;'> 同比销售额增长：  ${param1.value<=0?'100.00%':((param2.value-param1.value)/param1.value*100).toFixed(2)+'%'},
                      同比利润率增长：${(obj2.profit_rate-obj1.profit_rate).toFixed(2)} % </span></div>`;//第一行显示
                 // let circle3 = `<span style="display:inline-block;margin-right:5px;border-radius:50%;width:10px;height:10px;left:5px;background-color:`;//小圆点样式
                 // let data3 = `${circle3}red"></span>同比增长：  ${param1.value<=0?'100.00%':((param2.value-param1.value)/param1.value*100).toFixed(2)+'%'}`;//第三行显示
                 //添加一个统计数据，展示选择这的年度总计
                 
                 let totaldata_text=`<div style='border: 1px solid white;border-radius: 5px;padding: 5px;'>${(that.defyear instanceof Date)? new Date(that.defyear).getFullYear():that.defyear}年总计<div  style='font-size:12px;margin-left: 45px;'>交易订单：${obj2.sum_order_num}笔,销售量:${obj2.sum_num}<br/> 销售额:${obj2.sum_sale_price}￥成本:${obj2.sum_cost_price}￥<br/> 利润:${obj2.sum_profit}￥ 利润率:${obj2.sum_profit_rate}%</div></div>`
                  return `${totaldata_text}${param2.name}<br/>${data2}<br/>${param1.name}<br/>${data1}`;
                }
              },
              toolbox: this.global.echartsToolbox(),
              calculable: true,
              grid: {
                top: '12%',
                left: '1%',
                right: '10%',
                bottom: '5%',
                containLabel: true
              },
              xAxis: 
              {
                type: 'category',
                data:(()=>{
                  let list = [];
                  data.current.map((item,i)=>{
                    list.push(item.name+" 同比 "+data.SamePeriod[i].name);
                  })
                  return list;
                })(),
                axisLabel: {
                  show: true,
                  fontSize:9,
                  rotate:40,
                  formatter: function(value) {
                    var current = value.split(" 同比 ")[0],samePeriod = value.split(" 同比 ")[1];
                    return samePeriod+"\n"+current
                  }
                }
              },
              yAxis: [
                {
                  type: 'value',
                  // axisLabel: {
                  //   formatter: function (a) {
                  //     a = +a;
                  //     return isFinite(a) ? echarts.format.addCommas(+a / 1000) : '';
                  //   }
                  // }
                }
              ],
              series: [
                {
                  name: '同期',
                  type: 'bar',
                  data:(()=>{
                    let list = [];
                    data.SamePeriod.map((item)=>{
                      list.push({value:item[key],...item});
                    })
                    return list;
                  })()
                },
                {
                  name: '本期',
                  type: 'bar',
                  data:(()=>{
                    let list = [];
                    data.current.map((item)=>{
                      list.push({value:item[key],...item});
                    })
                    return list;
                  })()
                }
              ]
            }
      },
      //产品环比
      loadRingRatio(){
        const echarts=this.echarts,that=this;
        let RingRatio = echarts.init(
          document.getElementById('RingRatio-chart')
        )
        RingRatio.showLoading();
        this.http.get('/total/overview/get/ringRatio.do',{params:{year:new Date(this.defratio).getFullYear()}}).then((res) => {
          let plant = res.data.plant;
          that.table_data.ringRatioData=plant;
          RingRatio.hideLoading();
          RingRatio.setOption(
            this.getOptionByRingRatio(that.table_data.ringRatioData,ringRatioselect.value=='数量'?'num':'sum_price')
          )
        })
      },
      ringRatioChange(label){
        let ringRatio = this.echarts.getInstanceByDom(document.getElementById('RingRatio-chart'));
        if(label==='数量'){
          ringRatio.setOption(this.getOptionByRingRatio(this.table_data.ringRatioData,"num"));
        }else{
          ringRatio.setOption(this.getOptionByRingRatio(this.table_data.ringRatioData,"sum_price"));
        }
      },
      getOptionByRingRatio(data,key){
        const that=this;
        return {
            title: {
              text: '环比增长'
            },
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                type: 'shadow',
                label: {
                  show: true
                }
              },
              extraCssText:this.global.$echartsconfig.tooltip.extraCssText,
              formatter(params) {
                let tags = that.ringRatioselect=='数量'?'个':'￥'
                let param1=params[0], param2=params[1];
                let obj1=param1.data,obj2=param2.data;
                let circle1 = `<span style="display:inline-block;margin-right:5px;border-radius:50%;width:10px;height:10px;left:5px;background-color:`;//小圆点样式
                let data1 = `${circle1}${param1.color}"></span>${param1.seriesName}：  ${param1.value} ${tags}<br/>
                  <div style='border: 1px solid white;border-radius: 5px;padding: 5px;'><span style='font-size:12px;margin-left: 45px;'>
                    销售量：${obj1.num}个,销售额:${obj1.sum_price}￥</psan><br/>
                    <span style='font-size:12px;margin-left: 45px;'> 利润:${obj1.profit||0.00} ￥, 利润率:%${obj1.profit_ratio||0.00} </span></div><br/>`;//第二行显示
                let circle2 = `<span style="display:inline-block;margin-right:5px;border-radius:50%;width:10px;height:10px;left:5px;background-color:`;//小圆点样式
                let data2 = `${circle2}${param2.color}"></span>${param2.seriesName}：  ${param2.value} ${tags}<br/>
                  <div style='border: 1px solid white;border-radius: 5px;padding: 5px;'><span style='font-size:12px;margin-left: 45px;'>销售量：${obj2.num}个,销售额:${obj2.sum_price}￥</psan><br/>
                    <span style='font-size:12px;margin-left: 45px;'> 利润:${obj2.profit||0.00} ￥, 利润率:%${obj2.profit_ratio||0.00} </span><br/>
                    <span style='font-size:12px;margin-left: 45px;'> 销售环比增长：  ${param1.value<=0?'100.00%':((param2.value-param1.value)/param1.value*100).toFixed(2)+'%'},
                      利润率环比增长：${(obj2.profit_ratio-obj1.profit_ratio).toFixed(2)} % </span></div>`;//第三行显示
                //let circle3 = `<span style="display:inline-block;margin-right:5px;border-radius:50%;width:10px;height:10px;left:5px;background-color:`;//小圆点样式
                //let data3 = `${circle3}red"></span>环比增长：  ${param1.value<=0?'100.00%':((param2.value-param1.value)/param1.value*100).toFixed(2)+'%'}`;//第三行显示
                return `${param2.name}<br/>${data2}<br/>${param1.name}<br/>${data1}`;
              }
            },
            toolbox: this.global.echartsToolbox(),
            calculable: true,
            grid: {
              top: '12%',
              left: '1%',
              right: '10%',
              bottom: '5%',
              containLabel: true
            },
            xAxis: 
            {
              type: 'category',
              data:(()=>{
                let list = [];
                data.current.map((item,i)=>{
                  list.push(item.name+"环比"+data.previous[i].name);
                })
                return list;
              })(),
              axisLabel: {
                show: true,
                fontSize:9,
                rotate:40,
                formatter: function(value) {
                  var current = value.split("环比")[0],previous = value.split("环比")[1];
                  return previous+"\n"+current
                }
              }
            },
            yAxis: [
              {
                type: 'value'
              }
            ],
            series: [
              {
                name: '上期',
                type: 'bar',
                data:(()=>{
                  let list = [];
                  data.previous.map((item)=>{
                    list.push({value:item[key],...item});
                  })
                  return list;
                })()
              },
              {
                name: '本期',
                type: 'bar',
                data:(()=>{
                  let list = [];
                  data.current.map((item)=>{
                    list.push({value:item[key],...item});
                  })
                  return list;
                })()
              }
            ]
          }
      },  
      //销售分布
      loadMap(){
        const that = this;
        const echarts=this.echarts
        let map = echarts.init(
          document.getElementById('map-chart')
        )
        this.http.get('./world.json').then((res) => {//本地加载世界地图坐标绘制数据
          map.showLoading();
          echarts.registerMap('WORLD', res.data);
          //加载销售数据
          that.http.get('/total/overview/get/saleForMap.do').then((res) => {
            let plant = res.data.plant;
            that.table_data.mapdata=plant;
            map.hideLoading();
            map.setOption(that.getOpetionByMap(that.table_data.mapdata))
          }) 
        }) 
      },
      //返回地图的参数设置
      getOpetionByMap(data){
        return {
              title: {
                text: '销售分布',
              },
              tooltip: {
                trigger: 'item',
                extraCssText:this.global.$echartsconfig.tooltip.extraCssText,
                formatter(params){
                  if(params.data){
                    return `${params.data.name}(${params.data.zh_name})<div style='margin-left: 40px;font-size:12px;border: 1px solid white;border-radius: 5px;padding: 5px;'>客户:${params.data.customer_num}个<br/>订单:
                      ${params.data.order_num}笔<br/> 产品:${params.data.product_num}个 <br/>销售额:${params.data.value}￥<br/> 
                      成本:${params.data.cost_price}￥<br/> 利润:${params.data.profit}￥<br/> 利润率:${params.data.profit_ratio}%</div>`
                  }else{
                    return params.name+"<br/>无"
                  }
                }
              },
              toolbox: this.global.echartsToolbox({magicType:false}),
              visualMap: {
                min: 0,
                max: 100000,
                text: ['High', 'Low'],
                realtime: false,
                calculable: true,
                top:'30%',
                inRange: {
                  color: ['white','pink', 'red', 'crimson']
                }
              },
              series: [
                {
                  name: '销售数据',
                  type: 'map',
                  map: 'WORLD',
                  data: data,
                  // 自定义名称映射
                  nameMap: {}
                }
              ]
            }
      }
    }
}
</script>

<style scoped>
/* div{
   transform: translateX(-50%) rotate(-90deg); 
   transform: scale(0.8); 
} */
/* .test{
  transform: translateX(-50%) rotate(-20deg);
  text-align: center;
  width: 100px;
} */
#map-chart{
  height: 250px;
}
#YearOnYear-chart,#RingRatio-chart{
  height: 250px;
}
.clo-2{
  height: 250px;
}
.clo-2>div{
  width: 30%;
  /* border: 1px solid; */
  height: 100%;
  float: left;
}
#sort-product{
  height: 250px;
}
#dayTrend-chart{
  height: 250px;
}
.clo-1{
  height: 250px;
  width: 100%;
}
.clo-1>div{
  width: 30%;
  /* border: 1px solid; */
  height: 100%;
  float: left;
}
.center{
  width: 40% !important;
}

.main-item1{
  /* background-color: white; */
  height: 200px;
}
.main-item-list{
  margin-left: 5px;
}
.main-item-list div{
  padding-top: 2px;
}
#data-overview{
  height: 150px;
  width: 100%;
}
@media screen and (min-width:1200px){
  #YearOnYear-chart,#RingRatio-chart{
    height: 350px;
  }
  #map-chart{
    height: 350px;
  }
  .clo-2{
    height: 350px;
  }
  #sort-product{
    height: 300px;
  }
  #dayTrend-chart{
    height: 300px;
  }
  .clo-1{
    height: 300px;
  }
  .main-item-list div{
    height: 40px;
    padding-top: 5px;
  }
  .main-item1{
    height: 300px;
  }
  
}
@media screen and (min-width:1400px){
  #YearOnYear-chart,#RingRatio-chart{
    height: 400px;
  }
  #map-chart{
    height: 450px;
  }
  .clo-2{
    height: 400px;
  }
  #sort-product{
    height: 350px;
  }
  #dayTrend-chart{
    height: 350px;
  }
  .clo-1{
    height: 350px;
  }
  .main-item-list div{
    height: 50px;
    padding-top: 10px;
  }
  .main-item1{
    height: 330px;
  }
}
</style>