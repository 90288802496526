import {createRouter, createWebHashHistory} from "vue-router";
// import OverviewData from '@/views/OverviewData.vue'
// import ProAnalysis from '@/views/ProAnalysis.vue'
// import PurAnalysis from '@/views/PurAnalysis.vue'
import SalesAnalysis from '@/views/SalesAnalysis.vue'
import SelfTotal from '@/views/SelfTotal.vue'
// import SysMonitoring from '@/views/SysMonitoring.vue'
const routes=[
    {
        path:"/",
        meta:{permission:true,roleid:[0,10,8],show:false,active:'nav-active'},
        component:SelfTotal
    },
    // {
    //     path:"/OverviewData",
    //     name:'总览数据',
    //     meta:{permission:true,roleid:[0,10,8],show:false,active:'active'},
    //     component:OverviewData
    // },
    {
        path:"/SelfTotal",
        name:'个人统计',
        meta:{show:true},
        component:SelfTotal
    },
    // {
    //     path:"/ProAnalysis",
    //     name:'产品分析',
    //     meta:{permission:true,roleid:[0,10,8],show:false},
    //     component:ProAnalysis
    // },
    {
        path:"/SalesAnalysis",
        name:'销售分析',
        meta:{show:true},
        component:SalesAnalysis
    },
    // {
    //     path:"/PurAnalysis",
    //     name:'采购分析',
    //     meta:{permission:true,roleid:[0,10,8],show:false},
    //     component:PurAnalysis
    // },
    // {
    //     path:"/SysMonitoring",
    //     name:'系统监控',
    //     meta:{permission:true,roleid:[0,8],show:false},
    //     component:SysMonitoring
    // }
]
const router = createRouter({
    history: createWebHashHistory(),
    linkExactActiveClass: 'nav-active',
    routes: routes
})
router.beforeEach((to,from,next)=>{
    let flag =true;
    if(to.path=="/"){
        next()
    }else{
        router.getRoutes().map(function(r){
            if(r.path==to.path){
                flag=false;
                next()
            }
        });
        if(flag){//乱输入的地址都转入个人统计页面。
            router.replace("/SelfTotal");
            // next("/SelfTotal");
        }
    }
    
})
// router.afterEach((to,from)=>{
//     console.log(to,from,1);
// })
export default router;
