<template>
<!-- :style="{transform: 'scale('+transforma.x+','+transforma.y+')',margin:transforma.margin}" -->
    <nav class="navbar navbar-default" >
      <div class="container">
        <div id="navbar" class="collapse navbar-collapse">
          
          <!-- <ul class="nav navbar-nav">
          <li><router-link to="/OverviewData" >总览数据</router-link></li>
          <li><router-link to="/SelfTotal" >个人统计</router-link></li>
          <li><router-link to="/ProAnalysis" >产品分析</router-link></li>
          <li><router-link to="/SalesAnalysis" >销售分析</router-link></li>
          <li><router-link to="/PurAnalysis" active-class="active">采购分析</router-link></li>
          <li><router-link to="/SysMonitoring" >系统监控</router-link></li>
        </ul> -->
        <ul class="nav navbar-nav">
          <li v-for="(item,i) in routes" :key="i" >
            <router-link v-if="item.meta.show" :to="item.path" >{{item.name}}</router-link>
          </li>
          <li v-show="openNewpage"><a href="/dist/index.html" target="_blank">全屏打开</a></li>
        </ul>
        </div>
      </div>
    </nav>
    <!-- 指定组件呈现的位置 -->
    <router-view></router-view>
  
</template>

<script>
import http from 'axios'
import {ref,reactive,toRaw,getCurrentInstance} from "vue"
import OverviewData from '@/views/OverviewData.vue'
import ProAnalysis from '@/views/ProAnalysis.vue'
import PurAnalysis from '@/views/PurAnalysis.vue'
import SalesAnalysis from '@/views/SalesAnalysis.vue'
import SelfTotal from '@/views/SelfTotal.vue'
import SysMonitoring from '@/views/SysMonitoring.vue'
const routes=reactive([
    {
        path:"/OverviewData",
        name:'总览数据',
        meta:{permission:true,roleid:[0,10,8],show:false},
        component:OverviewData
    },
    {
        path:"/SelfTotal",
        name:'个人统计',
        meta:{show:false,active:'nav-active'},
        component:SelfTotal
    },
    {
        path:"/ProAnalysis",
        name:'产品分析',
        meta:{permission:true,roleid:[0,10,8],show:false},
        component:ProAnalysis
    },
    {
        path:"/SalesAnalysis",
        name:'销售分析',
        meta:{show:false},
        component:SalesAnalysis
    },
    {
        path:"/PurAnalysis",
        name:'采购分析',
        meta:{permission:true,roleid:[0,10,8],show:false},
        component:PurAnalysis
    },
    {
        path:"/SysMonitoring",
        name:'系统监控',
        meta:{permission:true,roleid:[0,8],show:false},
        component:SysMonitoring
    }
])
let openNewpage=ref(false);
export default {
  name: 'App',
  setup(){
      
      return {
        http,
        routes,
        openNewpage
      }
  },
  components: {
  },
  mounted(){
    const {appContext} = getCurrentInstance();
    const global = appContext.config.globalProperties;
    const that=this;
    if(window.parent.location.href.indexOf("/dist/")==-1){
      this.openNewpage=true;
    }else{
      this.openNewpage=false;
    }
    http.get('/total/overview/get/users.do').then((res) => {
      let plant = res.data.plant;
      if(res.data.success){//初始化路由
          global.$user=plant.user;
          global.$list_user=plant.list_users;
          routes.map(function(d){
              if(d.meta.permission){
                if(d.meta.roleid&&plant.user.role_id){
                  if(d.meta.roleid.indexOf(plant.user.role_id)!=-1){//用户没权限时删除这个模块
                    d.meta.show=true;
                    that.$router.addRoute(toRaw(d));//动态添加模块
                  }
                }else{
                  d.meta.show=true;
                  that.$router.addRoute(toRaw(d));//动态添加模块
                }
              }else{
                d.meta.show=true;
                that.$router.addRoute(toRaw(d));//动态添加模块
              }
          });
      }else{//未登陆，返回到首页
        window.location.href="/index.html";
      }
    });
  },
  methods:{
  }
}
</script>

<style>
.pagination{
  height: 30px;
  width: 100%;
  position: relative;
}
.pagination .el-pagination{
    position: absolute;
    right: 0px;
    top: 0px;
}
.example-showcase .el-loading-mask {
  z-index: 9;
}

body{
  /* background-color: #f5f5f5; */
  min-width: 1000px;
}
/* 上面的导航栏不管什么宽度都以顶部显示 */
@media (min-width: 200px){
  .navbar-collapse.collapse {
    display: block !important;
    height: auto !important;
    padding-bottom: 0;
    overflow: visible !important;
  }
  .navbar-nav > li {
      float: left;
  }
}
/* 选择饿了么的日期组件悬浮在同比环比图形之上 */
.selectYeardiv{
  position: relative;left: 100px;z-index: 99;height: 0px;top: 0px;width:200px;
}
.caret-wrapper {
  width: 2px !important;
}
.main-item-process-right{
  text-align: center;
}
.dx-picker-time{
  width: 100px !important;
  height: 33px !important;
  margin-left: 5px;
}
.title>h3{
  margin-left: 5px;
  font-size: revert;
  font-weight: 900;
  margin-top: 3px;
}
.nav-active{
  color: black !important;
  background-color: #b6cbe4 !important;
  font-weight: 1000;
  font-size: 17px;
}
.main-item-list div{
  float: left;
}
.main-item-list div{
  width: calc(30% - 16px);
  text-align: center;
  margin-bottom: 1px;
  height: 30px;
  margin-left: 1px;
  background-size: 2px 9px, 9px 2px, 2px 9px, 9px 2px;
  background-color: #337ab7;
} 
.main-item-list div h6{
  font-size: 13px;
  font-stretch: normal;
  line-height: 13px;
  letter-spacing: 0px;
  line-height: 13px;
  padding: 0;
  margin: 0;
}
.main-item-list div h3{
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  letter-spacing: 0px;
  padding: 0;
  margin: 0;
  color: #f2dede;
}
/* 重写bootstrap样式 */
.navbar-default{
  background-color: white;
}
.navbar{
  margin-bottom: 5px;
}
</style>
