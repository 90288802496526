<template>
    <el-dialog
        style="width:800px;height: 550px;"
      v-model="data.centerDialogVisible"
      title=""
      destroy-on-close
      @close="backclose"
      :close-on-click-modal="true"
      :draggable="true"
      center
    >
        <div style="float:left;width:35%;color: black;" v-loading="data.loading">
            <div style="font-size:12px;">
                <h3 style="margin: 0px;">{{data.productBase.number}}</h3>
                <ul style="margin:0px;list-style:none;padding:5px;">
                    <li><strong>型号:</strong>{{data.productBase.model}} </li>
                    <li><strong>名称:</strong>{{data.productBase.name}} </li>
                    <el-tooltip
                    class="box-item"
                    effect="dark"
                    :content="data.productBase.remark"
                    placement="bottom-end"
                    >
                        <li style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis;"><strong>备注:</strong>{{data.productBase.remark}} </li>
                    </el-tooltip>
                    <li><strong>售卖价:</strong>{{data.productBase.sell_price}}</li>
                </ul>
                <el-carousel :interval="5000" arrow="always" height="180px">
                    <el-carousel-item v-for="item in data.productBase.images" :key="item">
                    <div style="display: flex;height: 100%; width: 100%; margin: 5px;">
                        <img style="align-self: center;margin: 0 auto;max-width: 100%;max-height: 100%;" :src="item.url" />
                    </div>
                    </el-carousel-item>
                </el-carousel>
            </div>
            <div>
                <!-- 产品分布 -->
                <div id="productSpread-chart" :actived="data.actived=true" style="width:200px;height:200px;"></div>
            </div>
        </div>
        <div style="float:left;width:65%;color: black;" v-loading="data.loading">
            <div style="font-size:12px;">
                <table class="table-total">
                    <tr>
                        <th>订单量/占比(笔)</th><th>销售数量/占比(PCS)</th><th>购买客户/占比(数量)</th>
                    </tr>
                    <tr>
                        <td>{{data.productBase.order_num}} / {{data.productBase.order_num_ratio}}%</td><td>{{data.productBase.product_num}} / {{data.productBase.product_num_ratio}}%</td><td>{{data.productBase.buy_customer_num}} / {{data.productBase.customer_ratio}}%</td>
                    </tr>
                    <tr>
                        <th>销售额/占比(￥)</th><th>销售成本/占比(￥)</th><th>利润/利润率/占比(￥)</th>
                    </tr>
                    <tr>
                        <td>{{data.productBase.sale_price}} / {{data.productBase.sale_price_ratio}}%</td><td>{{data.productBase.cost_price}} / {{data.productBase.cost_price_ratio}}%</td><td>{{data.productBase.profit}} / {{data.productBase.profit_rate}}% / {{data.productBase.profit_ratio}}%</td>
                    </tr>
                </table>
            </div>
            <div>
                <div id="ProtductTotal-chart" :activid="data.activedSaleTotal=true" style="width:500px;height:200px;"></div>
            </div>
            <div>
                <div id="ProductHistoricalTrend-chart" :activid="data.activedHistorical=true" style="width:100%;height:200px;"></div>
            </div>
        </div>
    </el-dialog>
  </template>

<script>
import {reactive,getCurrentInstance} from "vue"
export default {
    name:'CustProPanel',
    setup(){
        const data = reactive({
            loading:true,
            centerDialogVisible:false,
            showcustomer:false,
            productBase:{

            },//产品基本信息
            productSpread:[],//产品分布数据
            productSaleTotal:[],//产品销售统计数据
            staffid:0,
            number:"",
            actived:false,
            activedHistorical:false,
            activedSaleTotal:false
        })
        const {appContext} = getCurrentInstance();
        const global = appContext.config.globalProperties;
        const echarts=appContext.config.globalProperties.$echarts;
        const http=appContext.config.globalProperties.$http;
        
        return {
            data,
            echarts,
            http,
            global
        }
    },
    watch:{
        "data.actived"(newVal){
            if(newVal){
                this.loadProductSpread(this.data.staffid,this.data.number);
            }
           
        },
        "data.activedHistorical"(newVal){
            if(newVal){
                this.loadHistoricalTrend(this.data.staffid,this.data.number);
            }
        },
        "data.activedSaleTotal"(newVal){
            if(newVal){
                this.loadSaleTotal(this.data.staffid,this.data.number);
            }
        }
    },
    mounted(){
    },
    methods:{
        backclose(){
            let productSpreadchart = document.getElementById('productSpread-chart');
            if(productSpreadchart){
                productSpreadchart = this.echarts.getInstanceByDom(productSpreadchart)
                if(productSpreadchart){
                    productSpreadchart.dispose();
                }
            }
            this.data.showcustomer=false;
            this.data.number="";
            this.data.staffid=0;
            this.data.actived=false;
            this.data.activedHistorical=false;
            this.data.activedSaleTotal=false;
        },
        open(staffid,number){
            const that=this;
            this.data.loading=true;
            this.data.centerDialogVisible=true;
            this.data.number=number;
            this.data.staffid=staffid;
            this.http.get('/total/sale/product/get/Total.do',{params:{staffid:staffid,number:number}}).then((res) => {
                that.data.loading=false;
                if(res.data.success){
                    that.data.productBase=res.data.plant[0];
                }
            })
        },
        loadProductSpread(staffid,number){//加载产品客户分布图
            const that = this
            //产品类型
            let productSpread = that.echarts.init(
                document.getElementById('productSpread-chart')
            )
            productSpread.showLoading();
            this.http.get('/total/sale/product/get/spreadProduct.do',{params:{staffid:staffid,number:number}}).then((res) => {
                productSpread.hideLoading();
                that.data.productSpread= res.data.plant;
                let count = res.data.plant.length;
                productSpread.setOption(
                {
                    title: {
                        text: '分布客户',
                        left: 'left',
                        textStyle:{
                            fontSize: 13,
                        },
                        top:'0%'
                    },
                    toolbox: {
                        show: true,
                        feature: {
                            dataView: { show: true, readOnly: true },
                        }
                    },
                    tooltip: {
                        trigger: 'item',
                        confine:true,
                        formatter(params) {
                            let circle1 = `<span style="display:inline-block;margin-right:5px;border-radius:50%;width:10px;height:10px;left:5px;background-color:`;//小圆点样式
                            let data1 = `${circle1}${params.color}"></span>购买：  ${params.value} 个`;//第一行行显示
                            return `${params.name} <br/>${data1}<br/>`;
                        }
                    },
                    series: [
                        {
                        name: '购买客户',
                        type: 'pie',
                        radius: ['30%', '80%'],
                        label: { //  饼图图形上的文本标签
                            normal: { // normal 是图形在默认状态下的样式
                                show: true,
                                position: 'center',
                                color: '#000',
                                fontSize: 9,
                                fontWeight: 'bold',
                                formatter(){
                                    return `${count}人购买过`;
                                }
                            }
                        },
                        data: that.data.productSpread,
                        emphasis: {
                            itemStyle: {
                            shadowBlur: 10,
                            shadowOffsetX: 0,
                            shadowColor: 'rgba(0, 0, 0, 0.5)'
                            }
                        }
                        }
                    ]
                    }
                )
            })
        },
        loadHistoricalTrend(staffid,number){//加载客户购买历史
            this.http.get('/total/sale/product/get/getHistorical.do',{params:{staffid:staffid,number:number}}).then((res) => {
            let plant = res.data.plant;
            let historical= this.echarts.init(
                document.getElementById('ProductHistoricalTrend-chart')
            )
            historical.setOption(
                {
                    title: {
                    text: '历史销售'
                    },
                    legend: {
                    data: [ '销售额','销售成本','利润','单价']
                    },
                    tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        label: {
                        backgroundColor: '#6a7985'
                        }
                    }
                    },
                    toolbox: this.global.echartsToolbox(),
                    grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                    },
                    dataZoom: [
                        {
                            type: 'inside',
                            start: plant.length>30?70:0,
                            end: 100
                        },
                        {
                            type: 'inside',
                            start: plant.length>30?70:0,
                            end: 100
                        }
                    ],
                    xAxis: [
                    {
                        type: 'category',
                        data:(()=>{
                        let list = [];
                        plant.map((item)=>{
                            list.push(item.time);
                        })
                        return list;
                        })()
                    }
                    ],
                    yAxis: [
                    {
                        type: 'value'
                    }
                    ],
                    series: [
                    {
                        type: 'line',
                        stack: 'Total',
                        emphasis: {
                            focus: 'series'
                        },
                        ...this.global.echartLineColor("数量"),
                        name:'数量',
                        smooth: true,
                        data:(()=>{
                        let list = [];
                        plant.map((item)=>{
                            list.push(item.product_num);
                        })
                        return list;
                        })()
                    },
                    {
                        type: 'line',
                        stack: 'Total',
                        emphasis: {
                        focus: 'series'
                        },
                        ...this.global.echartLineColor("单价"),
                        name:'单价',
                        smooth: true,
                        data:(()=>{
                        let list = [];
                        plant.map((item)=>{
                            list.push(item.unit_price);
                        })
                        return list;
                        })()
                    },
                    {
                        type: 'line',
                        stack: 'Total',
                        emphasis: {
                            focus: 'series'
                        },
                        ...this.global.echartLineColor("销售额"),
                        name:'销售额',
                        smooth: true,
                        data:(()=>{
                        let list = [];
                        plant.map((item)=>{
                            list.push(item.sale_price);
                        })
                        return list;
                        })()
                    },{
                        type: 'line',
                        stack: 'Total',
                        emphasis: {
                        focus: 'series'
                        },
                        ...this.global.echartLineColor("销售成本"),
                        name:'销售成本',
                        smooth: true,
                        data:(()=>{
                        let list = [];
                        plant.map((item)=>{
                            list.push(item.cost_price);
                        })
                        return list;
                        })()
                    },
                    {
                        type: 'line',
                        stack: 'Total',
                        emphasis: {
                            focus: 'series'
                        },
                        ...this.global.echartLineColor("利润"),
                        name:'利润',
                        smooth: true,
                        data:(()=>{
                        let list = [];
                        plant.map((item)=>{
                            list.push(item.profit);
                        })
                        return list;
                        })()
                    }
                    ]
                }
            )
            })
        },
        //销售统计
        loadSaleTotal(staffid,number){
            const that=this
            const echarts = this.echarts
            let protductTotal = echarts.init(
            document.getElementById('ProtductTotal-chart')
            )
            protductTotal.showLoading();
            this.http.get('/total/sale/product/get/saleTotal.do',{params:{staffid:staffid,number:number,cmd:1,}}).then((res) => {
            let plant = res.data.plant;
            that.data.productSaleTotal=plant;
            protductTotal.hideLoading();
            protductTotal.setOption(that.saleTotalOption(that.data.productSaleTotal))
            })
            protductTotal.on('click',(obj)=>{//当图形为年或月的时候，向下钻取
                if(obj.data.keys==='year'){
                    protductTotal.showLoading();
                    that.http.get('/total/sale/product/get/saleTotal.do',{params:{staffid:staffid,number:number,cmd:2,time:obj.data.time}}).then((res) => {
                    let plant = res.data.plant;
                    that.data.productSaleTotal=plant;
                    protductTotal.hideLoading();
                    protductTotal.setOption(that.saleTotalOption(that.data.productSaleTotal))
                    })
                }else if(obj.data.keys==='quarter'){//点击季度的时候
                    protductTotal.showLoading();
                    that.http.get('/total/sale/product/get/saleTotal.do',{params:{staffid:staffid,number:number,cmd:3,time:obj.data.time.substring(0,4)}}).then((res) => {
                    let plant = res.data.plant;
                    that.data.productSaleTotal=plant;
                    protductTotal.hideLoading();
                    protductTotal.setOption(that.saleTotalOption(that.data.productSaleTotal))
                    })
                }else if(obj.data.keys==='month'){//点击月的时候
                    protductTotal.showLoading();
                    that.http.get('/total/sale/product/get/saleTotal.do',{params:{staffid:staffid,number:number,cmd:4,time:obj.data.time}}).then((res) => {
                    let plant = res.data.plant;
                    that.data.productSaleTotal=plant;
                    protductTotal.hideLoading();
                    protductTotal.setOption(that.saleTotalOption(that.data.productSaleTotal))
                    })
                }
            
            })
        },
        saleTotalOption(data){//销售统计图形参数配置
            return {
                title: {
                    text: '销售统计'
                },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    },
                    extraCssText:this.global.$echartsconfig.tooltip.extraCssText,
                    formatter(params) {
                        let param1=params[0],data=param1.data;
                        let circle1 = `<span style="display:inline-block;margin-right:5px;border-radius:50%;width:10px;height:10px;left:5px;background-color:`;//小圆点样式
                        let data1 = `${circle1}${param1.color}"></span>${param1.seriesName}：  ${param1.value/10}`;//第一行行显示
                        let param2=params[1];
                        let circle2 = `<span style="display:inline-block;margin-right:5px;border-radius:50%;width:10px;height:10px;left:5px;background-color:`;//小圆点样式
                        let data2 = `${circle2}${param2.color}"></span>${param2.seriesName}：  ${param2.value}`;//第二行显示
                        let param3=params[2];
                        let circle3 = `<span style="display:inline-block;margin-right:5px;border-radius:50%;width:10px;height:10px;left:5px;background-color:`;//小圆点样式
                        let data3 = `${circle3}${param3.color}"></span>${param3.seriesName}：  ${param3.value}`;//第二行显示
                        let text=`<div style='font-size:12px; margin-left:40px;border: 1px solid white;border-radius: 5px;padding: 5px;'>销售量:${data.num}个 销售额:${data.sum_price}￥<br/> 利润:${data.profit}￥ 利润率:${data.profit_ratio}% </div>`
                        return `${param1.name} <br/>${data1}<br/>${data2}<br/>${data3}${text}`;
                    }
                },
                // legend: {
                //   data: [ '销售量','销售额','利润额']
                // },
                toolbox: this.global.echartsToolbox(),
                calculable: true,
                grid: {
                    top: '15%',
                    left: '1%',
                    right: '10%',
                    bottom: '1%',
                    containLabel: true
                },
                xAxis: [
                {
                    type: 'category',
                    data:(()=>{
                    let list = [];
                    data.map((item)=>{
                        list.push(item.name);
                    })
                    return list;
                    })(),
                    axisLabel: {
                    show: true,
                    rotate:40,
                    fontSize:9,
                    formatter: function(value) {
                        return value
                    }
                    }
                }
                ],
                yAxis: [
                {
                    type: 'value'
                }
                ],
                series: [
                {
                    name: '销售量',
                    type: 'bar',
                    stack: 'total',
                    data:(()=>{
                    let list = [];
                    data.map((item)=>{
                        list.push({value:item.num*10,...item});
                    })
                    return list;
                    })()
                },
                {
                    name: '销售额',
                    type: 'bar',
                    stack: 'total',
                    data:(()=>{
                    let list = [];
                    data.map((item)=>{
                        list.push({value:item.sum_price,...item});
                    })
                    return list;
                    })()
                },
                {
                    name: '利润额',
                    type: 'bar',
                    stack: 'total',
                    data:(()=>{
                    let list = [];
                    data.map((item)=>{
                        list.push({value:item.profit,...item});
                    })
                    return list;
                    })()
                }
                ]
            }
        },
        closePanel(){
            this.backclose();
        },
    },
    beforeUpdate(){
        this.data.productBase={
            "images":[],
            "sum_product_num": 0,
            "sum_sale_price": "0.00",
            "sell_price": "0.00",
            "sum_cost_price": "0.00",
            "remark": "",
            "customer_num": 0,
            "order_num_ratio": "0.00",
            "profit_rate": "0.00",
            "number": "",
            "customer_ratio": "0.00",
            "sum_profit": "0.00",
            "model": "",
            "id": 0,
            "profit": "0.00",
            "cost_price": "0.00",
            "cost_price_ratio": "0.00",
            "product_num_ratio": "0.00",
            "product_num": 0,
            "sale_price": "0.00",
            "name": "",
            "sum_order_num": 0,
            "sale_price_ratio": "0.00",
            "order_num": 0,
            "profit_ratio": "0.00",
            "buy_customer_num": 0
        }
    },
    updated(){
        
    },
    beforeUnmount(){
        this.backclose();
    }
}
</script>

<style scoped>
.table-total{
    margin: 5px;
    width: 100%;
    text-align:left;
}
.table-total td,th{
    width: 80px;
}
.dialog-footer button:first-child {
  margin-right: 10px;
}

.el-carousel__item h3 {
  color: #475669;
  opacity: 0.75;
  margin: 0;
  text-align: center;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
@media screen and (min-width:1200px){

}
@media screen and (min-width:1400px){

}
</style>