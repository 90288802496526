<template>
    <el-dialog
        style="width:800px;height: 550px;"
      v-model="data.centerDialogVisible"
      title=""
      destroy-on-close
      @close="backclose"
      :close-on-click-modal="true"
      :draggable="true"
      center
    >
        <div style="height:300px;">
            <div style="float:left;width:35%;color: black;" v-loading="data.loading">
                <div style="font-size:12px;">
                    <h3 style="margin: 0px;">{{data.supplierBase.name}}</h3>
                    <ul style="margin:0px;list-style:none;padding:5px;">
                        <li><strong>别名:</strong>{{data.supplierBase.alias}} <strong>地区:</strong>{{data.supplierBase.area}}</li>
                        <li><strong>电话:</strong>{{data.supplierBase.mobile}} </li>
                        <li><strong>邮箱:</strong>{{data.supplierBase.email}}</li>
                        <el-tooltip
                        class="box-item"
                        effect="dark"
                        :content="data.supplierBase.address"
                        placement="bottom-end"
                        >
                        <li style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis;"><strong>地址:</strong>{{data.supplierBase.address}}</li>
                        </el-tooltip>
                    </ul>
                </div>
                <div>
                    <!-- 产品分布 -->
                    <div id="productSpread-chart" :actived="data.actived=true" style="width:200px;height:200px;"></div>
                </div>
            </div>
            <div style="float:left;width:65%;color: black;" v-loading="data.loading">
                <div style="font-size:12px;height: 90px;">
                    <table class="table-total">
                        <tr>
                            <th>订单量/占比(笔)</th><th>产品数量/占比(PCS)</th><th>产品覆盖/占比(数量)</th>
                        </tr>
                        <tr>
                            <td>{{data.supplierBase.order_num}} / {{data.supplierBase.order_ratio}}%</td><td>{{data.supplierBase.product_num}} / {{data.supplierBase.product_num_ratio}}%</td><td>{{data.supplierBase.product_type_num}} / {{data.supplierBase.product_ratio}}%</td>
                        </tr>
                        <tr>
                            <th>采购额/占比(￥)</th>
                        </tr>
                        <tr>
                            <td>{{data.supplierBase.pur_price}} / {{data.supplierBase.pur_price_ratio}}%</td>
                        </tr>
                    </table>
                </div>
                <div>
                    <div id="supplier-pur-total" :activid="data.activedTotal=true" style="width:500px;height:200px;"></div>
                </div>
            </div>
        </div>
        <div id="HistoricalTrend-chart" :activid="data.activedHistorical=true" style="width:750px;height:180px;"></div>
    </el-dialog>
  </template>

<script>
import {reactive,getCurrentInstance} from "vue"
export default {
    name:'PurPanel',
    setup(){
        const data = reactive({
            loading:true,
            centerDialogVisible:false,
            showsupplier:false,
            supplierBase:{},//采购后基本信息
            productSpread:[],//产品分布数据
            purTotaldata:[],//采购统计
            supplierid:0,
            actived:false,
            activedTotal:false,
            activedHistorical:false
        })
        const {appContext} = getCurrentInstance();
        const global = appContext.config.globalProperties;
        const echarts=appContext.config.globalProperties.$echarts;
        const http=appContext.config.globalProperties.$http;
        return {
            data,
            echarts,
            http,
            global,
        }
    },
    watch:{
        "data.actived"(newVal){
            if(newVal){
                this.loadProductSpread(this.data.supplierid);
            }
           
        },
        "data.activedHistorical"(newVal){
            if(newVal){
                this.echarts.init(//初始化图表
                    document.getElementById('HistoricalTrend-chart')
                )
                this.loadHistoricalTrend(this.data.supplierid);
            }
        },
        "data.activedTotal"(newVal){
            if(newVal){
                this.echarts.init(//初始化图表
                    document.getElementById('HistoricalTrend-chart')
                )
                this.loadPurTotal(this.data.supplierid);
            }
        }
    },
    mounted(){
    },
    methods:{
        backclose(){
            let productSpreadchart = document.getElementById('productSpread-chart');
            if(productSpreadchart){
                productSpreadchart = this.echarts.getInstanceByDom(productSpreadchart)
                if(productSpreadchart){
                    productSpreadchart.dispose();
                }
            }
            this.data.showsupplier=false;
            this.data.supplierid=0;
            this.data.actived=false;
            this.data.activedTotal=false;
            this.data.activedHistorical=false;
        },
        open(supplierid){
            const that=this;
            this.data.loading=true;
            this.data.centerDialogVisible=true;
            this.data.supplierid=supplierid;
            this.http.get('/total/pur/supplier/get/Total.do',{params:{supplierid:supplierid}}).then((res) => {
                that.data.loading=false;
                if(res.data.success){
                    that.data.supplierBase=res.data.plant[0];
                }
            })
        },
        loadProductSpread(supplierid){//加载客户产品分布图
            if(!document.getElementById('productSpread-chart')){
                return;
            }
            const that = this
            //产品类型
            let productSpread = that.echarts.init(
                document.getElementById('productSpread-chart')
            )
            productSpread.showLoading();
            this.http.get('/total/pur/supplier/get/productSpread.do',{params:{supplierid:supplierid}}).then((res) => {
                productSpread.hideLoading();
                that.data.productSpread= res.data.plant;
                let count = res.data.plant.length;
                productSpread.setOption(
                {
                    title: {
                        text: '分布产品',
                        left: 'left',
                        textStyle:{
                            fontSize: 13,
                        },
                        top:'0%'
                    },
                    toolbox: {
                        show: true,
                        feature: {
                            dataView: { show: true, readOnly: true },
                        }
                    },
                    tooltip: {
                        trigger: 'item',
                        confine:true,
                        formatter(params) {
                            let circle1 = `<span style="display:inline-block;margin-right:5px;border-radius:50%;width:10px;height:10px;left:5px;background-color:`;//小圆点样式
                            let data1 = `${circle1}${params.color}"></span>采购：  ${params.value} 个`;//第一行行显示
                            return `${params.name} <br/>${data1}<br/>`;
                        }
                    },
                    series: [
                        {
                        name: '采购产品',
                        type: 'pie',
                        radius: ['30%', '80%'],
                        label: { //  饼图图形上的文本标签
                            normal: { // normal 是图形在默认状态下的样式
                                show: true,
                                position: 'center',
                                color: '#000',
                                fontSize: 9,
                                fontWeight: 'bold',
                                formatter(){
                                    return `采购${count}种产品`;
                                }
                            }
                        },
                        data: that.data.productSpread,
                        emphasis: {
                            itemStyle: {
                            shadowBlur: 10,
                            shadowOffsetX: 0,
                            shadowColor: 'rgba(0, 0, 0, 0.5)'
                            }
                        }
                        }
                    ]
                    }
                )
            });
        },
        loadHistoricalTrend(supplierid){//加载客户购买历史
            let dayTrendchart= this.echarts.getInstanceByDom(
                document.getElementById('HistoricalTrend-chart')
            )
            dayTrendchart.showLoading();
            this.http.get('/total/pur/supplier/get/historicalTrend.do',{params:{supplierid:supplierid}}).then((res) => {
                dayTrendchart.hideLoading();
                let plant = res.data.plant;
                dayTrendchart.setOption(
                    {
                        title: {
                        text: '历史采购'
                        },
                        legend: {
                        data: [ '采购量','采购额']
                        },
                        tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'cross',
                            label: {
                            backgroundColor: '#6a7985'
                            }
                        }
                        },
                        toolbox: this.global.echartsToolbox(),
                        grid: {
                        left: '3%',
                        right: '4%',
                        bottom: '3%',
                        containLabel: true
                        },
                        dataZoom: [
                            {
                                type: 'inside',
                                start: plant.length>30?70:0,
                                end: 100
                            },
                            {
                                type: 'inside',
                                start: plant.length>30?70:0,
                                end: 100
                            }
                        ],
                        xAxis: [
                            {
                                type: 'category',
                                data:(()=>{
                                let list = [];
                                plant.map((item)=>{
                                    list.push(item.time);
                                })
                                return list;
                                })()
                            }
                        ],
                        yAxis: [
                        {
                            type: 'value'
                        }
                        ],
                        series: [
                        {
                            type: 'line',
                            stack: 'Total',
                            emphasis: {
                            focus: 'series'
                            },
                            ...this.global.echartLineColor("采购量"),
                            name:'采购量',
                            data:(()=>{
                            let list = [];
                            plant.map((item)=>{
                                list.push(item.product_num);
                            })
                            return list;
                            })()
                        },
                        {
                            type: 'line',
                            stack: 'Total',
                            emphasis: {
                            focus: 'series'
                            },
                            ...this.global.echartLineColor("采购额"),
                            name:'采购额',
                            data:(()=>{
                            let list = [];
                            plant.map((item)=>{
                                list.push(item.pur_price);
                            })
                            return list;
                            })()
                        }]
                    }
                )
            })
        },
        loadPurTotal(supplierid){//供应商采购统计
            const that=this
            const echarts = this.echarts
            let buyTotalTread = echarts.init(
            document.getElementById('supplier-pur-total')
            )
            buyTotalTread.showLoading();
            this.http.get('/total/pur/get/purTotal.do',{params:{cmd:1,supplierid:supplierid}}).then((res) => {
            let plant = res.data.plant;
            that.data.purTotaldata=plant;
            buyTotalTread.hideLoading();
            buyTotalTread.setOption(that.saleTotalOption(that.data.purTotaldata))
            })
            buyTotalTread.on('click',(obj)=>{//当图形为年或月的时候，向下钻取
            if(obj.data.keys==='year'){
                buyTotalTread.showLoading();
                that.http.get('/total/pur/get/purTotal.do',{params:{cmd:2,time:obj.data.time,supplierid:supplierid}}).then((res) => {
                let plant = res.data.plant;
                that.data.purTotaldata=plant;
                buyTotalTread.hideLoading();
                buyTotalTread.setOption(that.saleTotalOption(that.data.purTotaldata))
                })
            }else if(obj.data.keys==='quarter'){//点击季度的时候
                buyTotalTread.showLoading();
                that.http.get('/total/pur/get/purTotal.do',{params:{cmd:3,time:obj.data.time.substring(0,4),supplierid:supplierid}}).then((res) => {
                let plant = res.data.plant;
                that.data.purTotaldata=plant;
                buyTotalTread.hideLoading();
                buyTotalTread.setOption(that.saleTotalOption(that.data.purTotaldata))
                })
            }else if(obj.data.keys==='month'){//点击月的时候
                buyTotalTread.showLoading();
                that.http.get('/total/pur/get/purTotal.do',{params:{cmd:4,time:obj.data.time,supplierid:supplierid}}).then((res) => {
                let plant = res.data.plant;
                that.data.purTotaldata=plant;
                buyTotalTread.hideLoading();
                buyTotalTread.setOption(that.saleTotalOption(that.data.purTotaldata))
                })
            }
            
            })
        },
        saleTotalOption(data){//采购统计图形参数配置
            return {
                title: {
                text: '采购统计'
                },
                tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow'
                },
                extraCssText:this.global.$echartsconfig.tooltip.extraCssText,
                formatter(params) {
                    let param1=params[0],data=param1.data;
                    let circle1 = `<span style="display:inline-block;margin-right:5px;border-radius:50%;width:10px;height:10px;left:5px;background-color:`;//小圆点样式
                    let data1 = `${circle1}${param1.color}"></span>${param1.seriesName}：  ${param1.value/10}`;//第一行行显示
                    let param2=params[1];
                    let circle2 = `<span style="display:inline-block;margin-right:5px;border-radius:50%;width:10px;height:10px;left:5px;background-color:`;//小圆点样式
                    let data2 = `${circle2}${param2.color}"></span>${param2.seriesName}：  ${param2.value}`;//第二行显示
                    let text=`<div style='font-size:12px; margin-left:40px;border: 1px solid white;border-radius: 5px;padding: 5px;'>采购量:${data.num}个 采购额:${data.sum_price}￥</div>`
                    return `${param1.name} <br/>${data1}<br/>${data2}<br/>${text}`;
                }
                },
                // legend: {
                //   data: [ '采购量','采购额']
                // },
                toolbox: this.global.echartsToolbox(),
                calculable: true,
                grid: {
                top: '20%',
                left: '1%',
                right: '10%',
                bottom: '0%',
                containLabel: true
                },
                xAxis: [
                {
                    type: 'category',
                    data:(()=>{
                    let list = [];
                    data.map((item)=>{
                        list.push(item.name);
                    })
                    return list;
                    })(),
                    axisLabel: {
                    show: true,
                    rotate:40,
                    fontSize:9,
                    formatter: function(value) {
                        return value
                    }
                    }
                }
                ],
                yAxis: [
                {
                    type: 'value'
                }
                ],
                series: [
                {
                    name: '采购量',
                    type: 'bar',
                    stack: 'total',
                    data:(()=>{
                    let list = [];
                    data.map((item)=>{
                        list.push({value:item.num*10,...item});
                    })
                    return list;
                    })()
                },
                {
                    name: '采购额',
                    type: 'bar',
                    stack: 'total',
                    data:(()=>{
                    let list = [];
                    data.map((item)=>{
                        list.push({value:item.sum_price,...item});
                    })
                    return list;
                    })()
                }
                ]
            }
        },
        closePanel(){
            this.backclose();
        },
    },
    beforeUpdate(){
        this.data.supplierBase={
            address: "",
            alias: "",
            area: "",
            current_order: 0,
            current_product_num: 0,
            current_pur_price: "0.00",
            email: "",
            id: 0,
            mobile: "",
            name: "",
            order_num: 0,
            order_ratio: "0.00",
            product_num: 0,
            product_num_ratio: "0.00",
            product_ratio: "0.00",
            product_type_num: 0,
            pur_price: "0.00",
            pur_price_ratio: "0.00",
            sum_product_type: 0
        }
    },
    updated(){
        
    },
    beforeUnmount(){
        this.backclose();
    }
}
</script>

<style scoped>
.table-total{
    margin: 5px;
    width: 100%;
    text-align:left;
}
.table-total td,th{
    width: 80px;
}
.dialog-footer button:first-child {
  margin-right: 10px;
}
@media screen and (min-width:1200px){

}
@media screen and (min-width:1400px){

}
</style>