<template>
  <div v-show="showproduct"  class="panel panel-default">
  <div class="panel-body" v-loading="data.loading">
    <img @click="closepanel" style="width:30px;height:30px;top: 0px;right: 0px;position: absolute;cursor:pointer" src="@/assets/img/close.png" />
    <div style="height: 400px;">
        <div class="productrow">
          <el-carousel :interval="5000" arrow="always" height="180px">
            <el-carousel-item v-for="item in data.images" :key="item">
              <div style="display: flex;height: 100%; width: 100%; margin: 5px;">
                <img style="align-self: center;margin: 0 auto;max-width: 100%;max-height: 100%;" :src="item.url" />
              </div>
            </el-carousel-item>
          </el-carousel> 
          <div style="width:200%;">
            <div style="width:180px;height:200px;float:left;" id="customerDistribution"></div>
            <div style="width:180px;height:200px;float:left;" id="price-wave"></div>
          </div>
          
        </div>
        <div class="productrow">
          <div style="height: 90px;">
            <div class="panel-info-title">产品统计</div>
            <ul class="productinfo">
              <li><strong>编号:</strong>{{data.productnumber}}</li>
              <li><strong>库存:</strong>{{data.productTotal.storage}} pcs</li>
              <li><strong>采购:</strong>{{data.productTotal.purchase_quantity}} pcs/{{data.productTotal.purchase_amount}} ￥</li>
              <li><strong>销售:</strong>{{data.productTotal.sales_quantity}} pcs/{{data.productTotal.sales_amount}} ￥</li>
              <li><strong>利润:</strong>{{data.productTotal.profit}} ￥/{{data.productTotal.profit_rate}} %</li>
              <li><strong>销售占比:</strong>pcs {{data.productTotal.sale_num_ratio}}% / ￥{{data.productTotal.sale_amount_ratio}}%</li>
              <li><strong>退货:</strong>{{data.productTotal.give_back}} pcs</li>
              <li><strong>废弃:</strong>{{data.productTotal.abandonment}} pcs</li>
              <li><strong>调整:</strong>(增){{data.productTotal.adjustment_in}} pcs/(减){{data.productTotal.adjustment_out}} pcs</li>
            </ul>
          </div>
          <div style="height: 80px;">
            <div class="panel-info-title">采购报表</div>
            <ul class="productinfo">
              <li><strong>7天:</strong> {{data.purchase.day_7_purchase_num}}pcs/{{data.purchase.day_7_purchase_amount}}￥ </li>
              <li><strong>30天:</strong> {{data.purchase.day_30_purchase_num}}pcs/{{data.purchase.day_30_purchase_amount}}￥ </li>
              <li><strong>120天:</strong>{{data.purchase.day_120_purchase_num}}pcs/{{data.purchase.day_120_purchase_amount}}￥ </li>
              <li><strong>1年:</strong> {{data.purchase.year_1_purchase_num}}pcs/{{data.purchase.year_1_purchase_amount}}￥ </li>
              <li><strong>2年:</strong> {{data.purchase.year_2_purchase_num}}pcs/{{data.purchase.year_2_purchase_amount}}￥ </li>
              <li><strong>3年:</strong> {{data.purchase.year_3_purchase_num}}pcs/{{data.purchase.year_3_purchase_amount}}￥ </li>
            </ul>
          </div>
          <div style="height: 120px;">
            <div class="panel-info-title">销售报表</div>
            <ul class="turnover">
              <li><strong>7天:</strong> {{data.sale.day_7_sale_num}}pcs/{{data.sale.day_7_sale_amount}}￥  利润 {{data.sale.day_7_profit}}￥/{{data.sale.day_7_profit_rate}} %  </li>
              <li><strong>30天:</strong> {{data.sale.day_30_sale_num}}pcs/{{data.sale.day_30_sale_amount}}￥  利润 {{data.sale.day_30_profit}}￥/{{data.sale.day_30_profit_rate}} %  </li>
              <li><strong>120天:</strong>{{data.sale.day_120_sale_num}}pcs/{{data.sale.day_120_sale_amount}}￥  利润 {{data.sale.day_120_profit}}￥/{{data.sale.day_120_profit_rate}} %  </li>
              <li><strong>1年:</strong> {{data.sale.year_1_sale_num}}pcs/{{data.sale.year_1_sale_amount}}￥  利润 {{data.sale.year_1_profit}}￥/{{data.sale.year_1_profit_rate}} %  </li>
              <li><strong>2年:</strong> {{data.sale.year_2_sale_num}}pcs/{{data.sale.year_2_sale_amount}}￥  利润 {{data.sale.year_2_profit}}￥/{{data.sale.year_2_profit_rate}} %  </li>
              <li><strong>3年:</strong> {{data.sale.year_3_sale_num}}pcs/{{data.sale.year_3_sale_amount}}￥  利润 {{data.sale.year_3_profit}}￥/{{data.sale.year_3_profit_rate}} %  </li>
            </ul>
          </div>
          <div style="height: 120px;">
            <div class="panel-info-title">周转率</div>
            <ul class="turnover">
              <li><strong>7天:</strong><span>成本:{{data.day7turnover.cost_growth_rate}} 天数:{{data.day7turnover.day_growth_rate}} 库存:{{data.day7turnover.storage_rate}} 销售:{{data.day7turnover.sales_growth_rate}}</span></li>
              <li><strong>30天:</strong><span>成本:{{data.day30turnover.cost_growth_rate}} 天数:{{data.day30turnover.day_growth_rate}} 库存:{{data.day30turnover.storage_rate}} 销售:{{data.day30turnover.sales_growth_rate}}</span></li>
              <li><strong>120天:</strong><span>成本:{{data.day120turnover.cost_growth_rate}} 天数:{{data.day120turnover.day_growth_rate}} 库存:{{data.day120turnover.storage_rate}} 销售:{{data.day120turnover.sales_growth_rate}}</span></li>
              <li><strong>1年:</strong><span>成本:{{data.year1turnover.cost_growth_rate}} 天数:{{data.year1turnover.day_growth_rate}} 库存:{{data.year1turnover.storage_rate}} 销售:{{data.year1turnover.sales_growth_rate}}</span></li>
              <li><strong>2年:</strong><span>成本:{{data.year2turnover.cost_growth_rate}} 天数:{{data.year2turnover.day_growth_rate}} 库存:{{data.year2turnover.storage_rate}} 销售:{{data.year2turnover.sales_growth_rate}}</span></li>
              <li><strong>3年:</strong><span>成本:{{data.year3turnover.cost_growth_rate}} 天数:{{data.year3turnover.day_growth_rate}} 库存:{{data.year3turnover.storage_rate}} 销售:{{data.year3turnover.sales_growth_rate}}</span></li>
            </ul>
          </div>
          
        </div>
    </div>
    <div>
      <div id="unit-price"></div>
      <div id="sales-history"></div>
    </div>
  </div>
</div>
</template>

<script>
// 产品详情页面模板
import {ref,reactive,getCurrentInstance} from "vue"
let showproduct=ref(false);
export default {
    name:'ProductPanel',
    setup() {
      const {appContext} = getCurrentInstance();
      const global=appContext.config.globalProperties;
      const echarts=appContext.config.globalProperties.$echarts;
      const http=appContext.config.globalProperties.$http;
      const data = reactive({
        productnumber:'',
        images:[],
        productTotal:{},
        purchase:{},
        sale:{},
        day7turnover:{cost_growth_rate:0.00,day_growth_rate:0.00,storage_rate:0.00,sales_growth_rate:0.00},
        day30turnover:{cost_growth_rate:0.00,day_growth_rate:0.00,storage_rate:0.00,sales_growth_rate:0.00},
        day120turnover:{cost_growth_rate:0.00,day_growth_rate:0.00,storage_rate:0.00,sales_growth_rate:0.00},
        year1turnover:{cost_growth_rate:0.00,day_growth_rate:0.00,storage_rate:0.00,sales_growth_rate:0.00},
        year2turnover:{cost_growth_rate:0.00,day_growth_rate:0.00,storage_rate:0.00,sales_growth_rate:0.00},
        year3turnover:{cost_growth_rate:0.00,day_growth_rate:0.00,storage_rate:0.00,sales_growth_rate:0.00},
        loading:true,
        customerDistribution:[]
      })
      const open=(number)=>{//加载产品信息
        data.loading=true;
        data.productnumber='';
        data.images=[];
        data.productTotal={};
        data.purchase={};
        data.sale={};
        http.get('/total/pro/get/productinfo.do',{params:{number:number}}).then((res) => {
          if(res.data.success){
            data.productnumber=number
            data.images = res.data.plant.images;
            data.productTotal= res.data.plant.productTotal[0];
            data.purchase= res.data.plant.purchase[0];
            data.sale= res.data.plant.sale[0];
          }
        })
        data.day7turnover={cost_growth_rate:0.00,day_growth_rate:0.00,storage_rate:0.00,sales_growth_rate:0.00},
        data.day30turnover={cost_growth_rate:0.00,day_growth_rate:0.00,storage_rate:0.00,sales_growth_rate:0.00},
        data.day120turnover={cost_growth_rate:0.00,day_growth_rate:0.00,storage_rate:0.00,sales_growth_rate:0.00},
        data.year1turnover={cost_growth_rate:0.00,day_growth_rate:0.00,storage_rate:0.00,sales_growth_rate:0.00},
        data.year2turnover={cost_growth_rate:0.00,day_growth_rate:0.00,storage_rate:0.00,sales_growth_rate:0.00},
        data.year3turnover={cost_growth_rate:0.00,day_growth_rate:0.00,storage_rate:0.00,sales_growth_rate:0.00}
        //7天周转率
        http.get('/total/overview/get/turnover.do',{params:{number:number,days:7}}).then((res) => {
          if(res.data.success&&res.data.plant.length>0){
            data.day7turnover=res.data.plant[0];
          }
        })
        //30天周转率
        http.get('/total/overview/get/turnover.do',{params:{number:number,days:30}}).then((res) => {
          if(res.data.success&&res.data.plant.length>0){
            data.day30turnover=res.data.plant[0];
          }
        })
        //120天周转率
        http.get('/total/overview/get/turnover.do',{params:{number:number,days:120}}).then((res) => {
          if(res.data.success&&res.data.plant.length>0){
            data.day120turnover=res.data.plant[0];
          }
        })
        //一年周转率
        http.get('/total/overview/get/turnover.do',{params:{number:number,days:365}}).then((res) => {
          if(res.data.success&&res.data.plant.length>0){
            data.year1turnover=res.data.plant[0];
          }
        })
        //两年周转率
        http.get('/total/overview/get/turnover.do',{params:{number:number,days:730}}).then((res) => {
          if(res.data.success&&res.data.plant.length>0){
            data.year2turnover=res.data.plant[0];
          }
        })
        //三年周转率
        http.get('/total/overview/get/turnover.do',{params:{number:number,days:1095}}).then((res) => {
          if(res.data.success&&res.data.plant.length>0){
            data.year3turnover=res.data.plant[0];
          }
        })
        //成本波动
        http.get('/total/pro/get/priceWave.do',{params:{number:number}}).then((res) => {
          let plant = res.data.plant;
          let priceWave= echarts.init(
            document.getElementById('price-wave')
          )
          priceWave.setOption(
            {
                title: {
                  text: '成本波动',
                  left: 'left',
                  textStyle:{
                    fontSize: 13,
                  },
                  top:'10%'
                },
                tooltip: {
                  trigger: 'axis',
                  axisPointer: {
                    type: 'cross',
                    label: {
                      backgroundColor: '#6a7985'
                    }
                  }
                },
                grid: {
                  top:'30%',
                  left: '1%',
                  right: '1%',
                  bottom: '10%',
                  containLabel: true
                },
                xAxis: [
                  {
                    type: 'category',
                    data:(()=>{
                      let list = [];
                      plant.map((item)=>{
                        list.push(item.time);
                      })
                      return list;
                    })()
                  }
                ],
                yAxis: [
                  {
                    type: 'value'
                  }
                ],
                series: [
                  {
                    type: 'line',
                    stack: 'Total',
                    areaStyle: {},
                    emphasis: {
                      focus: 'series'
                    },
                    name:'售卖成本',
                    data:plant
                  }
                ]
            }
          )
        })
        
        //销售单价波动
        http.get('/total/pro/get/unitprice.do',{params:{number:number}}).then((res) => {
          let plant = res.data.plant;
          let unitprice= echarts.init(
            document.getElementById('unit-price')
          )
          
          unitprice.setOption(
            {
                title: {
                  text: '销售单价波动',
                  left: 'left',
                  textStyle:{
                      fontSize: 13,
                  },
                  top:'10%'
                },
                tooltip: {
                  trigger: 'axis',
                  axisPointer: {
                    type: 'cross',
                    label: {
                      backgroundColor: '#6a7985'
                    }
                  }
                },
                grid: {
                  // top:'20%',
                  left: '1%',
                  right: '1%',
                  bottom: '0%',
                  containLabel: true
                },
                xAxis: [
                  {
                    type: 'category',
                    data:(()=>{
                      let list = [];
                      plant.map((item)=>{
                        list.push(item.time);
                      })
                      return list;
                    })()
                  }
                ],
                yAxis: [
                  {
                    type: 'value'
                  }
                ],
                series: [
                  {
                    type: 'line',
                    stack: 'Total',
                    areaStyle: {},
                    emphasis: {
                      focus: 'series'
                    },
                    name:'售卖单价',
                    data:plant
                  }
                ]
            }
          )
        })
        //加载产品客户分布图
        let distribution = echarts.init(
            document.getElementById('customerDistribution')
        )
        distribution.showLoading();
        http.get('/total/pro/get/customerDistribution.do',{params:{number:number}}).then((res) => {
            distribution.hideLoading();
            data.customerDistribution= res.data.plant;
            let count = res.data.plant.length;
            distribution.setOption(
            {
                title: {
                    text: '分布客户',
                    left: 'left',
                    textStyle:{
                        fontSize: 13,
                    },
                    top:'10%'
                },
                toolbox: {
                    show: true,
                    feature: {
                        dataView: { show: true, readOnly: true },
                    }
                },
                tooltip: {
                    trigger: 'item',
                    confine:true,
                    formatter(params) {
                        let circle1 = `<span style="display:inline-block;margin-right:5px;border-radius:50%;width:10px;height:10px;left:5px;background-color:`;//小圆点样式
                        let data1 = `${circle1}${params.color}"></span>购买：  ${params.value} 个`;//第一行行显示
                        return `${params.name} <br/>${data1}<br/>`;
                    }
                },
                series: [
                    {
                    name: '购买客户',
                    type: 'pie',
                    radius: ['30%', '80%'],
                    label: { //  饼图图形上的文本标签
                        normal: { // normal 是图形在默认状态下的样式
                            show: true,
                            position: 'center',
                            color: '#000',
                            fontSize: 9,
                            fontWeight: 'bold',
                            formatter(){
                                return `${count}人购买过`;
                            }
                        }
                    },
                    data: data.customerDistribution,
                    emphasis: {
                        itemStyle: {
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                        }
                    }
                    }
                ]
            }
            )
        })
        //产品销售历史
        http.get('/total/pro//get/getSalesHistory.do',{params:{number:number}}).then((res) => {
                let plant = res.data.plant;
                let dayTrendchart= echarts.init(
                    document.getElementById('sales-history')
                )
                dayTrendchart.setOption(
                    {
                        title: {
                            text: '历史销售',
                            left: 'left',
                            textStyle:{
                                fontSize: 13,
                            },
                            top:'10%'
                        },
                        legend: {
                          data: [ '销售额','销售成本','利润','单价'],
                          top:'10%'
                        },
                        tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'cross',
                            label: {
                            backgroundColor: '#6a7985'
                            }
                        }
                        },
                        toolbox: false,
                        grid: {
                        left: '3%',
                        right: '4%',
                        bottom: '3%',
                        containLabel: true
                        },
                        dataZoom: [
                            {
                                type: 'inside',
                                start: plant.length>30?70:0,
                                end: 100
                            },
                            {
                                type: 'inside',
                                start: plant.length>30?70:0,
                                end: 100
                            }
                        ],
                        xAxis: [
                        {
                            type: 'category',
                            data:(()=>{
                            let list = [];
                            plant.map((item)=>{
                                list.push(item.time);
                            })
                            return list;
                            })()
                        }
                        ],
                        yAxis: [
                        {
                            type: 'value'
                        }
                        ],
                        series: [
                        {
                            type: 'line',
                            stack: 'Total',
                            emphasis: {
                            focus: 'series'
                            },
                            ...global.echartLineColor("数量"),
                            name:'数量',
                            data:(()=>{
                            let list = [];
                            plant.map((item)=>{
                                list.push(item.product_num);
                            })
                            return list;
                            })()
                        },{
                            type: 'line',
                            stack: 'Total',
                            emphasis: {
                            focus: 'series'
                            },
                            ...global.echartLineColor("单价"),
                            name:'单价',
                            data:(()=>{
                            let list = [];
                            plant.map((item)=>{
                                list.push(item.unit_price);
                            })
                            return list;
                            })()
                        },
                        {
                            type: 'line',
                            stack: 'Total',
                            emphasis: {
                            focus: 'series'
                            },
                            ...global.echartLineColor("销售额"),
                            name:'销售额',
                            data:(()=>{
                            let list = [];
                            plant.map((item)=>{
                                list.push(item.sale_price);
                            })
                            return list;
                            })()
                        },{
                            type: 'line',
                            stack: 'Total',
                            emphasis: {
                            focus: 'series'
                            },
                            ...global.echartLineColor("销售成本"),
                            name:'销售成本',
                            data:(()=>{
                            let list = [];
                            plant.map((item)=>{
                                list.push(item.cost_price);
                            })
                            return list;
                            })()
                        },
                        {
                            type: 'line',
                            stack: 'Total',
                            emphasis: {
                              focus: 'series'
                            },
                            ...global.echartLineColor("利润"),
                            name:'利润',
                            data:(()=>{
                            let list = [];
                            plant.map((item)=>{
                                list.push(item.profit);
                            })
                            return list;
                            })()
                        }
                        ]
                    }
                )
            })

      }
      return {
        showproduct,
        open,
        echarts,
        data
      }
    },
    watch:{
      "data.productnumber"(newval){
        if(newval){
          this.data.loading=false;
        }
      }
      
    },
    mounted(){
    },
    methods:{
      closepanel(){
        this.showproduct=false;
        let unitprice = this.echarts.getInstanceByDom(
          document.getElementById('unit-price')
        )
        unitprice.dispose();
        let pricewave = this.echarts.getInstanceByDom(
          document.getElementById('price-wave')
        )
        pricewave.dispose();
      }
    },
    beforeUnmount(){//摧毁时将面板展示标识关闭
        this.showproduct=false;
    },
}
</script>

<style scoped>
#unit-price,#sales-history{
  width:380px;height:200px;float:left;
}
.panel-info-title{
  writing-mode: tb-rl;width: 0px;position: absolute;height: 100px;font-size: 15px;font-weight: 900;
}
.turnover{
  list-style: none;
  width: 100%;
  font-size: 12px;
  margin: 0px;
  padding-left: 10px;
}
.turnover >li{
  width: 100%;
}
.turnover >li >span{
  margin-left: 20px;
}
.turnover >li >strong{
  width: 50px;
  display: inline-block;
}
.productinfo{
  list-style: none;
  width: 100%;
  font-size: 12px;
  margin: 0px;
  padding-left: 10px;
}
.productinfo >li{
  float: left;
  width: 50%;
}
.productrow{
  width: 50%;float: left;
  height: 400px;
  padding: 20px;
}

.el-carousel__item h3 {
  color: #475669;
  opacity: 0.75;
  margin: 0;
  text-align: center;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
.panel{
  left: 50%;
  align-items: center;
  transform: translateX(-50%);
  position: absolute;
  z-index: 999;
  /* width: 75%; */
  min-width: 800px;
  max-width: 850px;
  height: 650px;
}
@media screen and (min-width:1200px){

}
@media screen and (min-width:1400px){

}
</style>