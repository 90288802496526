<template>
  <div>
    系统监控

  </div>
</template>

<script>
export default {
    name:'SysMonitoring'
}
</script>

<style>

</style>