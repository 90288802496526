import { createApp } from 'vue'
// axios
import axios from 'axios'
import mate from './Mate'
// import ElementPlus from 'element-plus'
import { 
  ElButton,
  ElRow,
  ElDatePicker,
  ElCol,
  ElProgress,
  ElTable,
  ElTableColumn,
  ElTabs,
  ElTabPane,
  ElSelect,
  ElOption,
  ElRadioGroup,
  ElRadioButton,
  ElLoading,
  // ElMenuItem,
  // ElMenu,
  // ElInfiniteScroll,
  ElPagination,
  ElCarousel,
  ElCarouselItem,
  ElInput,
  ElTooltip,
  ElDialog,
  ElPopover
} from 'element-plus';
// import infiniteScroll from "vue-infinite-scroll";

// 引入 echarts 核心模块，核心模块提供了 echarts 使用必须要的接口。
import * as echarts from 'echarts/core';
import { BarChart,LineChart,PieChart,MapChart } from 'echarts/charts';
// 引入提示框，标题，直角坐标系，数据集，内置数据转换器组件，组件后缀都为 Component
import {
    TitleComponent,
    TooltipComponent,
    GridComponent,
    DatasetComponent,
    TransformComponent,
    LegendComponent,
    ToolboxComponent,
    VisualMapComponent
  } from 'echarts/components';
// 标签自动布局，全局过渡动画等特性
import { LabelLayout, UniversalTransition } from 'echarts/features';
// 引入 Canvas 渲染器，注意引入 CanvasRenderer 或者 SVGRenderer 是必须的一步
import { CanvasRenderer } from 'echarts/renderers';
// 注册必须的组件
echarts.use([
    TitleComponent,
    TooltipComponent,
    GridComponent,
    DatasetComponent,
    TransformComponent,
    BarChart,
    LabelLayout,
    UniversalTransition,
    CanvasRenderer,
    LegendComponent,
    ToolboxComponent,
    LineChart,
    PieChart,
    MapChart,
    VisualMapComponent
  ]);
import 'element-plus/dist/index.css'
import App from './App.vue'
// import VueRouter from 'vue-router'
import router from './router'
const app = createApp(App) 
app.use(router)
app.use(mate)
app.use(ElLoading)
// app.use(ElInfiniteScroll);
// app.use(VueRouter)
// app.use(ElementPlus)
app.component(ElPopover.name,ElPopover)
app.component(ElDialog.name,ElDialog)
app.component(ElTooltip.name,ElTooltip)
app.component(ElInput.name,ElInput)
app.component(ElCarousel.name,ElCarousel)
app.component(ElCarouselItem.name,ElCarouselItem)
app.component(ElPagination.name,ElPagination)
app.component(ElButton.name,ElButton)
app.component(ElRow.name,ElRow)
app.component(ElDatePicker.name,ElDatePicker)
app.component(ElCol.name,ElCol)
app.component(ElProgress.name,ElProgress)
app.component(ElTable.name,ElTable)
app.component(ElTableColumn.name,ElTableColumn)
app.component(ElTabs.name,ElTabs)
app.component(ElTabPane.name,ElTabPane)
app.component(ElSelect.name, ElSelect);
app.component(ElOption.name, ElOption);
app.component(ElRadioGroup.name, ElRadioGroup);
app.component(ElRadioButton.name, ElRadioButton);
app.mount('#app')
app.config.globalProperties.$http = axios;
app.config.globalProperties.$echarts=echarts
app.config.globalProperties.$echartsconfig={
    tooltip:{
      extraCssText:"background-color:#000000a3;color:white;"
    }
}
