<template>
  <div class="main">
    <div class="left-div">
      <div class="item1">
        <div class="title">
          <h3>数据概览</h3>
        </div>
        <div class="main-item-process" style="position: relative;">
          <el-row>
            <el-col :span="12" class="main-item-process-left">存储:{{data_obj.storage}}</el-col>
            <el-col :span="12" class="main-item-process-right">覆盖:{{data_obj.cover_ratio}}/{{(data_obj.cover_ratio/data_obj.product_class*100).toFixed(2)}}%</el-col>
          </el-row>
          <el-progress :percentage="100" status="success" />
        </div>
        <div class="main-item-list" >
          <div v-for="(item,i) in data" :key="i">
            <h6>{{item.title}}</h6>
            <h3>{{ item.num }}</h3>
          </div>
        </div>
        <div class="main-item-process" style="position: relative;">
          <el-row>
            <el-col :span="12" class="main-item-process-left">类型:{{data_obj.product_type}}</el-col>
            <el-col :span="12" class="main-item-process-right">种类:{{data_obj.product_class}}</el-col>
          </el-row>
          <el-progress :percentage="100" status="success" />
        </div>
        <div>
          <!-- 产品类型分组 -->
          <div id="producttype-chart"></div>
        </div>
        <div> 
          <!-- 产品销售排行 -->
          <div>
            <div class="selectYeardiv">
              <!-- <el-radio-group v-model="rankingselect" size="small" style="vertical-align: top;" @change="rankingRatioChange">
                <el-radio-button label="num" >数量</el-radio-button>
                <el-radio-button label="sum_price" >销售额</el-radio-button>
                <el-radio-button label="cost" >成本</el-radio-button>
                <el-radio-button label="profit" >利润</el-radio-button>
              </el-radio-group> -->
              <el-date-picker  size="small" style="width:100px;"
                v-model="picktimeforsalesort.starttime"
                :clearable="picktimeforsalesort.clearable"
                :editable="picktimeforsalesort.editable"
                type="year"
                placeholder="起始时间"
              />
              <el-select style="width:100px;" v-model="rankingselect" class="m-2" placeholder="Select" size="small" @change="rankingRatioChange">
                <el-option
                  v-for="item in dimension_options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </div>
            <div id="productRanking"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="right-div">
      <!-- 产品数据基本报表 -->
      <div class="title">
        <div style="position: relative;top: 0px;">
          <strong style="margin-right: 15px;">产品报表</strong>
          <el-input v-model="searchval" style="width:150px;" 
          @clear="()=>{
            this.loadProductReport();
          }"
          size="small" 
          placeholder="number key" 
          clearable />
          <el-button size="small" style="margin-left: 5px;"
          @click="()=>{
            this.loadProductReport();
          }"
          >关键字搜索</el-button>
        </div>
      </div>
      <div>
        <el-table
          v-loading="loading"
          ref="singleTableRef"
          element-loading-text="Loading..."
          :element-loading-svg="svg"
          element-loading-svg-view-box="-10, -10, 50, 50"
          @header-click="(column)=>{
            if(column.sortable){
              this.tatble_sort.property=column.property;
              this.tatble_sort.order=column.order;
              this.tatble_page_config.start=0;//排序后重置分页起始页
              this.tatble_page_config.defaultCurrentPage=1;//排序后设为当前第一页
              this.loadProductReport();
            }
          }"
          :data="table_data.product_report_data"
          @row-click="rowclick"
          @row-dblclick="(row)=>{
            this.productPanel.showproduct=true;//展示面板
            this.productPanel.open(row.number);
          }"
          @row-contextmenu="contextmenu"
          :highlight-current-row="true"
          style="width: 100%;overflow: auto" class="custom-loading-svg product_report_data" size="small">
          <el-table-column
            prop="number"
            label="产品"
            >
          </el-table-column>
          <el-table-column label="销售数量">
            <el-table-column
              prop="day_num_10"
              sortable="true"
              :sort-orders="tablesort"
              label="10天"
              >
            </el-table-column>
            <el-table-column
              prop="day_num_30"
              sortable="true"
              :sort-orders="tablesort"
              label="30天"
              >
            </el-table-column>
            <el-table-column
              prop="day_num_120"
              sortable="true"
              :sort-orders="tablesort"
              label="季度"
              >
            </el-table-column>
            <el-table-column
              prop="day_num_365"
              sortable="true"
              :sort-orders="tablesort"
              label="年"
              >
            </el-table-column>
            <el-table-column
              prop="sum_num"
              sortable="true"
              :sort-orders="tablesort"
              label="总数"
              >
            </el-table-column>
          </el-table-column>
          <el-table-column
            prop="storage"
            sortable="true"
            :sort-orders="tablesort"
            label="余量"
            >
          </el-table-column>
          <el-table-column
            prop="in_sum_num"
            sortable="true"
            :sort-orders="tablesort"
            label="采购"
            >
          </el-table-column>
        </el-table>
        <div class="pagination">
          <el-pagination layout="prev, pager, next, jumper, ->, total" 
          :total="tatble_page_config.totalProperty" 
          :page-size="tatble_page_config.limit"
          :current-page="tatble_page_config.defaultCurrentPage"
          @current-change="(page)=>{
            this.tatble_page_config.start=(page-1)*this.tatble_page_config.limit;
            this.tatble_page_config.defaultCurrentPage=page;
            this.loadProductReport();
          }" />
        </div>
      </div>
      <div>
        <div style="float:left;width:50%;">
          <div class="selectYeardiv">
            <el-date-picker  size="small" style="width:100px;"
              v-model="pickerconfig.defyear"
              :clearable="pickerconfig.clearable"
              :editable="pickerconfig.editable"
              type="year"
              placeholder="选择一个年份"
            />
            <el-radio-group v-model="table_data.yearonyearselect" size="small" style="vertical-align: top;"  @change="yearOnYearChange" >
              <el-radio-button label="num">数量</el-radio-button>
              <el-radio-button label="sum_price">金额</el-radio-button>
            </el-radio-group>
          </div>
          <div id="YearOnYear-chart"></div>
        </div>
        <div style="float:left;width:50%;">
          <div class="selectYeardiv">
            <el-date-picker  size="small" style="width:100px;"
              v-model="pickerconfig.defradio"
              type="year"
              :clearable="pickerconfig.clearable"
              :editable="pickerconfig.editable"
              placeholder="选择一个年份"
            />
            <el-radio-group v-model="table_data.ringRatioselect" size="small" style="vertical-align: top;" @change="ringRatioChange" >
              <el-radio-button label="num">数量</el-radio-button>
              <el-radio-button label="sum_price">金额</el-radio-button>
            </el-radio-group>
          </div>
          <!-- 环比增长率 -->
          <div id="RingRatio-chart"></div>
        </div>  
      </div>
      
    </div>
  </div>
  <!-- 右键表格菜单 -->
    <ul class="dropdown-menu">
      <li><a href="javascript:void(0)" @click="productinforeport">详情报表</a></li>
    </ul>
    <!-- 产品详情组件 -->
    <ProductPanel ref="productPanel"></ProductPanel>
</template>

<script>
import {ref,reactive,getCurrentInstance} from "vue"
// import { ElTable } from 'element-plus'
import ProductPanel from '@/components/ProductPanel.vue'
const defbutton1=ref('同比增长率')
const loading = ref(false)
let number='';
let pro_number=ref('');
const singleTableRef = ref(null);
const svg = `
        <path class="path" d="
          M 30 15
          L 28 17
          M 25.61 25.61
          A 15 15, 0, 0, 1, 15 30
          A 15 15, 0, 1, 1, 27.99 7.5
          L 15 15
        " style="stroke-width: 4px; fill: rgba(0, 0, 0, 0)"/>`
const picktimeforsalesort=reactive({editable:false,clearable:true,starttime:''});//销售排行的时间选择默认未选择时间
const dimension_options = [
  { label: '数量',value: 'num'},
  {label: '销售额',value: 'sum_price'},
  {label: '成本',value: 'cost'},
  {label: '利润',value: 'profit'},
  {label: '利润率',value: 'cost_ratio'},
  {label: '销售利润率',value: 'sale_ratio'}
]
const searchval=ref("");//搜索关键字
export default {
    name:'ProAnalysis',
    components: {ProductPanel},
    setup() {
      const {appContext} = getCurrentInstance();
      const global = appContext.config.globalProperties;
      const echarts=appContext.config.globalProperties.$echarts;
      const http=appContext.config.globalProperties.$http;
      const ranking_desc = ref('desc');//默认降序 产品排行
      const rankingselect=ref('num');//默认展示产品的数量排行
      const pickerconfig = reactive({editable:false,clearable:false,defyear:(new Date().getFullYear())+'',defradio:(new Date().getFullYear())+''});
      const table_data = reactive({
        totalType:[],//产品类型统计
        productRanking:[],//产品销售排行数据
        product_report_data:[],//产品报表数据
        yearOnYearData:[],//同比数据库
        ringRatioData:[],//环比数据
        yearonyearselect:'sum_price',//同比按钮切换
        ringRatioselect:'sum_price',//环比按钮切换数据
      })
      const productPanel = ref(null);
      const data = reactive([
        {key:'input',title:'入库',num:0},
        {key:'output',title:'出库',num:0},
        {key:'adjustment_input',title:'调整(增)',num:0},
        {key:'adjustment_output',title:'调整(减)',num:0},
        {key:'assemble_output',title:'组装(出)',num:0},
        {key:'back_input',title:'退货',num:0},
        {key:'discard',title:'废弃',num:0},
        {key:'allocation',title:'调拨',num:0}
      ]);
      const tatble_page_config=reactive({limit:10,start:0,totalProperty:0,defaultCurrentPage:1});
      const tatble_sort=reactive({property:'day_num_10',order:'desc'});//记住表格的排序方式
      const data_obj = reactive({product_type:0,product_class:0,storage:0,cover_ratio:0});
      //加载概览数据
      http.get('/total/pro/get/data.do').then((res) => {
        let obj = res.data.plant[0];
        for(var d in data){
          data[d].num=obj[data[d].key];
        }
        data_obj.product_type=obj.product_type;
        data_obj.product_class=obj.product_class;
        data_obj.storage=obj.storage;
        data_obj.cover_ratio=obj.cover_ratio;
      })
      return {
          defbutton1,
          pickerconfig,
          table_data,
          dimension_options,
          data,
          data_obj,
          global,
          echarts,
          tablesort:['ascending', 'descending'],
          http,
          loading,
          svg,
          tatble_page_config,
          tatble_sort,
          ranking_desc,
          rankingselect,
          picktimeforsalesort,
          pro_number,
          productPanel,
          searchval,
          singleTableRef
        }
    },
    mounted(){
      this.initpage();
      this.loadProductType()
      this.loadProductReport()
      this.loadProductSort()
      document.addEventListener('click',this.foo);
    },
    watch:{
      "pickerconfig.defyear"(newVal){//同比选择时间的组件值发生变化时重新获取数据
        if(newVal){
          const that = this;
          let yearonyear = this.echarts.getInstanceByDom(document.getElementById('YearOnYear-chart'));
          yearonyear.showLoading();
          this.http.get('/total/pro/get/yearOnYear.do',{params:{number:number,time:new Date(newVal).getFullYear(),cmd:1}}).then((res) => {
            let plant = res.data.plant;
            that.table_data.yearOnYearData=plant;
            yearonyear.hideLoading();
            yearonyear.setOption(
              this.getOptionByYearOnYear(plant,that.table_data.yearonyearselect)
            )
          })
        }
      },
      "pickerconfig.defradio"(newVal){//环比选择时间的组件值发生变化时重新获取数据
        if(newVal){
          const that = this;
          let ringRatio = this.echarts.getInstanceByDom(document.getElementById('RingRatio-chart'));
          ringRatio.showLoading();
          this.http.get('/total/pro/get/ringRatio.do',{params:{number:number,year:new Date(newVal).getFullYear()}}).then((res) => {
            let plant = res.data.plant;
            that.table_data.ringRatioData=plant;
            ringRatio.hideLoading();
            ringRatio.setOption(
              this.getOptionByRingRatio(plant,that.table_data.ringRatioselect)
            )
          })
        }
      },
      "picktimeforsalesort.starttime"(newVal){
        let productRanking = this.echarts.getInstanceByDom(document.getElementById('productRanking'));
        const that= this;
        productRanking.showLoading();
        this.http.get('/total/pro/get/productRanking.do',{params:{starttime:new Date(newVal).getFullYear(),orderby:`${that.rankingselect} ${that.ranking_desc}`}}).then((res) => {
          let plant = res.data.plant;
          that.table_data.productRanking=plant;
          productRanking.hideLoading();
          productRanking.setOption(that.getProductSortOption(that.table_data.productRanking,that.rankingselect))
        })
      }
    },
    methods:{
      initpage(){
        this.echarts.init(//渲染同比图
          document.getElementById('YearOnYear-chart')
        )
        this.loadYearOnYearClick();//加载同比图点击事件
        this.echarts.init(//渲染环比图
          document.getElementById('RingRatio-chart')
        )
      },
      loadProductType(){//产品分类图形加载
        const that = this
        //产品类型
        let producttype = that.echarts.init(
          document.getElementById('producttype-chart')
        )
        this.http.get('/total/pro/get/totalType.do').then((res) => {
          that.table_data.totalType= res.data.plant;
          producttype.setOption(
            {
              tooltip: {
                trigger: 'item',
                confine:true,
              },
              legend: {
                orient: "vertical",
                left: "left",
                top: "4%",
                textStyle: {
                  color: "#0043ffa6",
                  fontSize: 10,
                },
                itemGap: 4,
                itemWidth: 10,
                itemHeight: 5,
                height: 300,
              },
              series: [
                {
                  name: '产品类型',
                  type: 'pie',
                  radius: ['30%', '80%'],
                  label:{
                    show:false
                  },
                  data: that.table_data.totalType,
                  emphasis: {
                    itemStyle: {
                      shadowBlur: 10,
                      shadowOffsetX: 0,
                      shadowColor: 'rgba(0, 0, 0, 0.5)'
                    }
                  }
                }
              ]
            }
          )
        })
      },
      loadProductReport(){//加载产品报表
        const that = this;
        this.loading=true;
        this.http.get('/total/pro/get/proinfo.do',{params:{query:that.searchval,...that.tatble_page_config,orderby:`${that.tatble_sort.property} ${that.tatble_sort.order=='ascending'? 'asc':'desc'}`}}).then((res) => {
          that.loading=false;
          that.table_data.product_report_data=res.data.plant;
          that.tatble_page_config.totalProperty=res.data.totalProperty[0].count;
          if(res.data.totalProperty[0].count>0){
            that.singleTableRef.setCurrentRow(that.table_data.product_report_data[0])
            number=that.table_data.product_report_data[0].number
            this.yearOnYear();//加载第一条的同比数据
            this.loadRingRatio();//加载第一条的环比数据。
          }
        })
      },  
      rowclick(row){//点击产品时加载
        number=row.number;
        const that= this;
        //加载同比数据
        let yearOnYear = this.echarts.getInstanceByDom(document.getElementById('YearOnYear-chart'));
        yearOnYear.showLoading();
        this.http.get('/total/pro/get/yearOnYear.do',{params:{number:number,time:new Date(this.pickerconfig.defyear).getFullYear(),cmd:1}}).then((res) => {
          let plant = res.data.plant;
          that.table_data.yearOnYearData=plant;
          yearOnYear.hideLoading();
          this.yearOnYearChange();//重新加载同比数据
        })
        //加载环比数据
        let RingRatio = this.echarts.getInstanceByDom(document.getElementById('RingRatio-chart'));
        RingRatio.showLoading();
        this.http.get('/total/pro/get/ringRatio.do',{params:{number:number,year:new Date(this.pickerconfig.defradio).getFullYear()}}).then((res) => {
          let plant = res.data.plant;
          that.table_data.ringRatioData=plant;
          RingRatio.hideLoading();
          this.ringRatioChange();//重新加载同比数据
        })
      },  
      yearOnYear(){//产品同比
        const that =this;
        const echarts = this.echarts
        let yearOnYear = echarts.getInstanceByDom(
          document.getElementById('YearOnYear-chart')
        )
        yearOnYear.showLoading();
        this.http.get('/total/pro/get/yearOnYear.do',{params:{number:number,time:new Date(this.pickerconfig.defyear).getFullYear(),cmd:1}}).then((res) => {
          let plant = res.data.plant;
          that.table_data.yearOnYearData=plant;
          yearOnYear.hideLoading();
          yearOnYear.setOption(
            this.getOptionByYearOnYear(plant,that.table_data.yearonyearselect)
          )
        })
      },
      loadYearOnYearClick(){//点击同比图时间
        const that =this;
        const yearOnYear = this.echarts.getInstanceByDom(
          document.getElementById('YearOnYear-chart')
        )
        yearOnYear.on('click',(obj)=>{//向下钻取
          if(obj.name.indexOf('季度')!=-1&&obj.seriesIndex==1){
            yearOnYear.showLoading();
            this.http.get('/total/pro/get/yearOnYear.do',{params:{number:number,time:new Date(this.pickerconfig.defyear).getFullYear(),cmd:2}}).then((res) => {
              let plant = res.data.plant;
              that.table_data.yearOnYearData=plant;
              yearOnYear.hideLoading();
              yearOnYear.setOption(
                this.getOptionByYearOnYear(plant,that.table_data.yearonyearselect)
              )
            })
            // tread.setOption(this.saleTotalOption()) 
          }else if(obj.name.indexOf('月')!=-1&&obj.seriesIndex==1){
            yearOnYear.showLoading();
            this.http.get('/total/pro/get/yearOnYear.do',{params:{number:number,time:obj.name.substring(0,7),cmd:3}}).then((res) => {
              let plant = res.data.plant;
              that.table_data.yearOnYearData=plant;
              yearOnYear.hideLoading();
              yearOnYear.setOption(
                this.getOptionByYearOnYear(plant,that.table_data.yearonyearselect)
              )
            })
          }
          
        })
      },
      //产品同比切换数量金额按钮改变值触发事件
      yearOnYearChange(){
        let yearonyear = this.echarts.getInstanceByDom(document.getElementById('YearOnYear-chart'));
        yearonyear.setOption(this.getOptionByYearOnYear(this.table_data.yearOnYearData,this.table_data.yearonyearselect));
      },
      //设置产品同比参数
      getOptionByYearOnYear(data,key){
        const that = this;
        return {
              title: {
                text: '同比增长'
              },
              tooltip: {
                trigger: 'axis',
                axisPointer: {
                  type: 'shadow',
                  label: {
                    show: true
                  }
                },
                extraCssText:that.global.$echartsconfig.tooltip.extraCssText,
                formatter(params) {
                  let tags = that.table_data.yearonyearselect=='num'?'个':'￥'
                  let param1=params[0], param2=params[1];
                  let obj1=param1.data,obj2=param2.data;
                  let circle1 = `<span style="display:inline-block;margin-right:5px;border-radius:50%;width:10px;height:10px;left:5px;background-color:`;//小圆点样式
                  let data1 = `${circle1}${param1.color}"></span>${param1.seriesName}：  ${param1.value} ${tags}<br/>
                  <div style='border: 1px solid white;border-radius: 5px;padding: 5px;'><span style='font-size:12px;margin-left: 45px;'>交易订单：${obj1.order_num}笔,销售量：${obj1.num}个,销售额:${obj1.sum_price}￥</psan><br/>
                    <span style='font-size:12px;margin-left: 45px;'> 利润:${obj1.profit||0.00} ￥, 利润率:%${obj1.profit_rate||0.00} </span></div><br/>`;//第二行显示
                  let circle2 = `<span style="display:inline-block;margin-right:5px;border-radius:50%;width:10px;height:10px;left:5px;background-color:`;//小圆点样式
                  let data2 = `${circle2}${param2.color}"></span>${param2.seriesName}：  ${param2.value} ${tags}<br/>
                  <div style='border: 1px solid white;border-radius: 5px;padding: 5px;'><span style='font-size:12px;margin-left: 45px;'>交易订单：${obj2.order_num}笔,销售量：${obj2.num}个,销售额:${obj2.sum_price}￥</psan><br/>
                    <span style='font-size:12px;margin-left: 45px;'> 利润:${obj2.profit||0.00} ￥, 利润率:%${obj2.profit_rate||0.00} </span><br/>
                    <span style='font-size:12px;margin-left: 45px;'> 销售同比增长：  ${param1.value<=0?'100.00%':((param2.value-param1.value)/param1.value*100).toFixed(2)+'%'},
                      利润率同比增长：${(obj2.profit_rate-obj1.profit_rate).toFixed(2)} % </span></div>`;//第一行显示
                 // let circle3 = `<span style="display:inline-block;margin-right:5px;border-radius:50%;width:10px;height:10px;left:5px;background-color:`;//小圆点样式
                 // let data3 = `${circle3}red"></span>同比增长：  ${param1.value<=0?'100.00%':((param2.value-param1.value)/param1.value*100).toFixed(2)+'%'}`;//第三行显示
                 let totaldata_text=`<div style='border: 1px solid white;border-radius: 5px;padding: 5px;'>${(that.pickerconfig.defyear instanceof Date)? new Date(that.pickerconfig.defyear).getFullYear():that.pickerconfig.defyear}年总计<div  style='font-size:12px;margin-left: 45px;'>交易订单：${obj2.sum_order_num}笔,销售量:${obj2.sum_num}<br/> 销售额:${obj2.sum_sale_price}￥成本:${obj2.sum_cost_price}￥<br/> 利润:${obj2.sum_profit}￥ 利润率:${obj2.sum_profit_rate}%</div></div>`
                  return `${totaldata_text}${param2.name}<br/>${data2}<br/>${param1.name}<br/>${data1}`;
                }
              },
              toolbox: this.global.echartsToolbox(),
              calculable: true,
              grid: {
                top: '12%',
                left: '1%',
                right: '10%',
                bottom: '5%',
                containLabel: true
              },
              xAxis: 
              {
                type: 'category',
                data:(()=>{
                  let list = [];
                  data.current.map((item,i)=>{
                    list.push(item.name+" 同比 "+data.SamePeriod[i].name);
                  })
                  return list;
                })(),
                axisLabel: {
                  show: true,
                  // interval: 0,
                  // color: '#ffffff',
                  fontSize:9,
                  rotate:40,
                  formatter: function(value) {
                    var current = value.split(" 同比 ")[0],samePeriod = value.split(" 同比 ")[1];
                    return samePeriod+"\n"+current
                  }
                }
              },
              yAxis: [
                {
                  type: 'value',
                  // axisLabel: {
                  //   formatter: function (a) {
                  //     a = +a;
                  //     return isFinite(a) ? echarts.format.addCommas(+a / 1000) : '';
                  //   }
                  // }
                }
              ],
              series: [
                {
                  name: '同期',
                  type: 'bar',
                  data:(()=>{
                    let list = [];
                    data.SamePeriod.map((item)=>{
                      list.push({value:item[key],...item});
                    })
                    return list;
                  })()
                },
                {
                  name: '本期',
                  type: 'bar',
                  data:(()=>{
                    let list = [];
                    data.current.map((item)=>{
                      list.push({value:item[key],...item});
                    })
                    return list;
                  })()
                }
              ]
            }
      },
      //产品环比
      loadRingRatio(){
        const echarts=this.echarts,that=this;
        let RingRatio = echarts.getInstanceByDom(
          document.getElementById('RingRatio-chart')
        )
        RingRatio.showLoading();
        this.http.get('/total/pro/get/ringRatio.do',{params:{number:number,year:new Date(this.pickerconfig.defradio).getFullYear()}}).then((res) => {
          let plant = res.data.plant;
          that.table_data.ringRatioData=plant;
          RingRatio.hideLoading();
          RingRatio.setOption(
            this.getOptionByRingRatio(that.table_data.ringRatioData,that.table_data.ringRatioselect)
          )
        })
      },
      ringRatioChange(){
        let ringRatio = this.echarts.getInstanceByDom(document.getElementById('RingRatio-chart'));
        ringRatio.setOption(this.getOptionByRingRatio(this.table_data.ringRatioData,this.table_data.ringRatioselect));
      },
      getOptionByRingRatio(data,key){
        const that=this;
        return {
            title: {
              text: '环比增长'
            },
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                type: 'shadow',
                label: {
                  show: true
                }
              },
              extraCssText:that.global.$echartsconfig.tooltip.extraCssText,
              formatter(params) {
                let tags = that.table_data.ringRatioselect=='num'?'个':'￥'
                let param1=params[0], param2=params[1];
                let obj1=param1.data,obj2=param2.data;
                let circle1 = `<span style="display:inline-block;margin-right:5px;border-radius:50%;width:10px;height:10px;left:5px;background-color:`;//小圆点样式
                let data1 = `${circle1}${param1.color}"></span>${param1.seriesName}：  ${param1.value} ${tags}<br/>
                  <div style='border: 1px solid white;border-radius: 5px;padding: 5px;'><span style='font-size:12px;margin-left: 45px;'>
                    销售量：${obj1.num}个,销售额:${obj1.sum_price}￥</psan><br/>
                    <span style='font-size:12px;margin-left: 45px;'> 利润:${obj1.profit||0.00} ￥, 利润率:%${obj1.profit_ratio||0.00} </span></div><br/>`;//第二行显示
                let circle2 = `<span style="display:inline-block;margin-right:5px;border-radius:50%;width:10px;height:10px;left:5px;background-color:`;//小圆点样式
                let data2 = `${circle2}${param2.color}"></span>${param2.seriesName}：  ${param2.value} ${tags}<br/>
                  <div style='border: 1px solid white;border-radius: 5px;padding: 5px;'><span style='font-size:12px;margin-left: 45px;'>销售量：${obj2.num}个,销售额:${obj2.sum_price}￥</psan><br/>
                    <span style='font-size:12px;margin-left: 45px;'> 利润:${obj2.profit||0.00} ￥, 利润率:%${obj2.profit_ratio||0.00} </span><br/>
                    <span style='font-size:12px;margin-left: 45px;'> 销售环比增长：  ${param1.value<=0?'100.00%':((param2.value-param1.value)/param1.value*100).toFixed(2)+'%'},
                      利润率环比增长：${(obj2.profit_ratio-obj1.profit_ratio).toFixed(2)} % </span></div>`;//第三行显示
                //let circle3 = `<span style="display:inline-block;margin-right:5px;border-radius:50%;width:10px;height:10px;left:5px;background-color:`;//小圆点样式
                //let data3 = `${circle3}red"></span>环比增长：  ${param1.value<=0?'100.00%':((param2.value-param1.value)/param1.value*100).toFixed(2)+'%'}`;//第三行显示
                return `${param2.name}<br/>${data2}<br/>${param1.name}<br/>${data1}`;
              }
            },
            toolbox: this.global.echartsToolbox(),
            calculable: true,
            grid: {
              top: '12%',
              left: '1%',
              right: '10%',
              bottom: '5%',
              containLabel: true
            },
            xAxis: 
            {
              type: 'category',
              data:(()=>{
                let list = [];
                data.current.map((item,i)=>{
                  list.push(item.name+"环比"+data.previous[i].name);
                })
                return list;
              })(),
              axisLabel: {
                show: true,
                // interval: 0,
                fontSize:9,
                rotate:40,
                formatter: function(value) {
                  var current = value.split("环比")[0],previous = value.split("环比")[1];
                  return previous+"\n"+current
                }
              }
            },
            yAxis: [
              {
                type: 'value'
              }
            ],
            series: [
              {
                name: '上期',
                type: 'bar',
                data:(()=>{
                  let list = [];
                  data.previous.map((item)=>{
                    list.push({value:item[key],...item});
                  })
                  return list;
                })()
              },
              {
                name: '本期',
                type: 'bar',
                data:(()=>{
                  let list = [];
                  data.current.map((item)=>{
                    list.push({value:item[key],...item});
                  })
                  return list;
                })()
              }
            ]
          }
      }, 
      //销售最好产品
      loadProductSort(){
        const that=this;
        let productRanking = this.echarts.init(
          document.getElementById('productRanking')
        )
        productRanking.showLoading();
        this.http.get('/total/pro/get/productRanking.do',{params:{starttime:new Date(that.picktimeforsalesort.starttime||"1979-01-01").getFullYear(),orderby:`${that.rankingselect} ${that.ranking_desc}`}}).then((res) => {
          let plant = res.data.plant;
          that.table_data.productRanking=plant;
          productRanking.hideLoading();
          productRanking.setOption(that.getProductSortOption(that.table_data.productRanking,that.rankingselect))
        })
        productRanking.on("click",function(){
          if(that.ranking_desc=='desc'){
            that.ranking_desc="asc"
          }else{
            that.ranking_desc="desc"
          }
          productRanking.showLoading();
          that.http.get('/total/pro/get/productRanking.do',{params:{starttime:new Date(that.picktimeforsalesort.starttime||"1979-01-01").getFullYear(),orderby:`${that.rankingselect} ${that.ranking_desc}`}}).then((res) => {
            let plant = res.data.plant;
            that.table_data.productRanking=plant;
            productRanking.hideLoading();
            productRanking.setOption(that.getProductSortOption(that.table_data.productRanking,that.rankingselect))
          })
        })
      },
      //销售产品排行图标参数设置
      getProductSortOption(data,key){
        const that=this;
        return {
            title: {
              text: 'Top产品'
            },
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                type: 'shadow'
              },
              confine:true,
              extraCssText:that.global.$echartsconfig.tooltip.extraCssText,
              formatter(params) {
                let param=params[0],data=param.data;
                //console.log(param);
                let title="时间:总统计";
                if(that.picktimeforsalesort.starttime){
                  title=`时间:${new Date(that.picktimeforsalesort.starttime).getFullYear()}年 至 今`
                }
                let text=`<div style='font-size:12px; margin-left:40px;border: 1px solid white;border-radius: 5px;padding: 5px;'>销售总量:${data.num}个 销售总额:${data.sum_price}￥<br/>成本总额:${data.cost_price}￥ 总利润:${data.profit}￥<br/>利润率:${data.cost_ratio}% 销售利润率:${data.sale_ratio}%</div>`
                if(that.rankingselect=='num'){//数量
                  let circle = `<span style="display:inline-block;margin-right:5px;border-radius:50%;width:10px;height:10px;left:5px;background-color:`;//小圆点样式
                  let data0 = `${circle}${param.color}"></span>销售数量:  ${param.value}`;
                  return `${title} <br/>${param.name}<br/>${data0}${text}`;
                }else if(that.rankingselect=='sum_price'){//销售额
                  let circle = `<span style="display:inline-block;margin-right:5px;border-radius:50%;width:10px;height:10px;left:5px;background-color:`;//小圆点样式
                  let data0 = `${circle}${param.color}"></span>销售额:  ${param.value}`;
                  return `${title} <br/>${param.name}<br/>${data0}${text}`;
                }else if(that.rankingselect=='cost_price'){//成本
                  let circle = `<span style="display:inline-block;margin-right:5px;border-radius:50%;width:10px;height:10px;left:5px;background-color:`;//小圆点样式
                  let data0 = `${circle}${param.color}"></span>成本:  ${param.value}`;
                  return `${title} <br/>${param.name}<br/>${data0}${text}`;
                }else if(that.rankingselect=='profit'){//利润
                  let circle = `<span style="display:inline-block;margin-right:5px;border-radius:50%;width:10px;height:10px;left:5px;background-color:`;//小圆点样式
                  let data0 = `${title} <br/>${circle}${param.color}"></span>利润:  ${param.value}`;
                  return `${param.name}<br/>${data0}${text}`;
                }else if(that.rankingselect=='cost_ratio'){//成本利润率
                  let circle = `<span style="display:inline-block;margin-right:5px;border-radius:50%;width:10px;height:10px;left:5px;background-color:`;//小圆点样式
                  let data0 = `${title} <br/>${circle}${param.color}"></span>利润率:  ${param.value} %`;
                  return `${title} <br/>${param.name}<br/>${data0}${text}`;
                }else if(that.rankingselect=='sale_ratio'){//销售利润率
                  let circle = `<span style="display:inline-block;margin-right:5px;border-radius:50%;width:10px;height:10px;left:5px;background-color:`;//小圆点样式
                  let data0 = `${title} <br/>${circle}${param.color}"></span>销售利润率:  ${param.value} %`;
                  return `${title} <br/>${param.name}<br/>${data0}${text}`;
                }
                
              }
            },
            toolbox: this.global.echartsToolbox(),
            legend: {},
            dataZoom:[
              {
                yAxisIndex:0,
                type:'inside',
                startValue:0,
                endValue:9
              }
            ],
            grid: {
              left: '3%',
              right: '4%',
              bottom: '13%',
              top:"10%",
              containLabel: true
            },
            xAxis: {
              type: 'value',
              boundaryGap: [0, 0.01],
              axisLabel:{
                fontSize:9
              }
            },
            yAxis: {
              type: 'category',
              inverse:true,//排序
              data:(()=>{
                let list = [];
                data.map((item)=>{
                  list.push(item.number);
                })
                return list;
              })()
            },
            series: 
              {
                type: 'bar',
                data:(()=>{
                  let list = [];
                  data.map((item)=>{
                    list.push({value:item[key],...item});
                  })
                  return list;
                })()
              }
          }
      },
      //切换排行数据维度，数量和销售额
      rankingRatioChange(label){
        let productRanking = this.echarts.getInstanceByDom(document.getElementById('productRanking'));
        const that= this;
        productRanking.showLoading();
        this.http.get('/total/pro/get/productRanking.do',{params:{starttime:new Date(that.picktimeforsalesort.starttime||"1997-01-01").getFullYear(),orderby:`${label} ${that.ranking_desc}`}}).then((res) => {
          let plant = res.data.plant;
          that.table_data.productRanking=plant;
          productRanking.hideLoading();
          productRanking.setOption(that.getProductSortOption(that.table_data.productRanking,label))
        })
      },
      //右键表格行数据
      contextmenu(row, _, event){
        event.preventDefault();
        this.pro_number=row.number;//记录右键时点击的那行产品编号
        const menu = document.querySelector('.dropdown-menu');
        menu.style.top=event.pageY-10+"px";
        menu.style.left=event.pageX+"px";
        menu.style.display="block";
      },
      foo(){//点击任意地方隐藏右键的菜单
        const menu = document.querySelector('.dropdown-menu');
        if(menu){
          menu.style.display="none";
        }
      },
      productinforeport(){//点击菜单
        this.productPanel.showproduct=true;//展示面板
        this.productPanel.open(this.pro_number);
      }
    }
}
</script>

<style scoped>
#YearOnYear-chart,#RingRatio-chart{
  height: 250px;
  width: 100%;
}
.main-item-list{
  margin-left: 5px;
  height: 100px;
}
#productRanking{
  height: 230px;
}
#producttype-chart{
  height: 265px;
}
.product_report_data{
  height: 360px;
}
.item1{
  height: 150px;
}
.main{
  width: 100%;
  height: 100%;
}
.main >div{
  width: 33%;
  /* height: 100%; */
  /* background-color: red; */
  /* border: 1px solid black; */
  float: left;
}
.main>.left-div{
  width: 30%;
}
.main>.right-div{
  width: 70%;
}
@media screen and (min-width:1200px){
  #producttype-chart{
    height: 262px;
  }
  #productRanking{
    height: 280px;
  }
  #YearOnYear-chart,#RingRatio-chart{
    height: 280px;
  }
}
@media screen and (min-width:1400px){
  #productRanking{
    height: 280px;
  }
  #YearOnYear-chart,#RingRatio-chart{
    height: 280px;
  }
}
</style>