<template>
  <!-- 第一行 -->
  <div class="clo-1">
    <!-- 第一列 -->
    <div>
      <div class="main-item1">
        <div class="title">
          <h3>数据概览</h3>
        </div>
        <div class="main-item-process" style="position: relative;">
          <el-row>
            <el-col :span="12" class="main-item-process-left">产品种类:{{data_obj.product_num}}</el-col>
            <el-col :span="12" class="main-item-process-right">覆盖率: {{data_obj.cover_num}}/ {{data_obj.cover_ratio}}%</el-col>
          </el-row>
          <el-progress :percentage="100" status="success" />
        </div>
        <div class="main-item-list" >
          <div v-for="(item,i) in data" :key="i">
            <h6>{{item.title}}</h6>
            <h3>{{ item.num }}</h3>
          </div>
        </div>
        <div class="spaceinfo">
            <span>磁盘</span>:<span>{{spaceinfo.total_space}}G</span>
            <span>使用</span>:<span>{{spaceinfo.use_space}}G</span>
            <span>空闲</span>:<span>{{spaceinfo.free_space}}G</span>
              <el-button type="primary" size="small" :loading="loading" @click="clearSapce">清理</el-button>
            <el-progress :text-inside="true" :stroke-width="26" :percentage="spaceinfo.use_space_ratio" style="width:80%" />
        </div>
      </div>
    </div>
    <!-- 第二列 -->
    <div class="center">
      <div>
        <div id="dayTrend-chart"></div>
      </div>
    </div>
    <!-- 第三列 -->
    <div>
      <div>
        <div class="selectYeardiv">
          <!-- <el-radio-group v-model="rankingselect" size="small" style="vertical-align: top;" @change="rankingRatioChange">
            <el-radio-button label="num" >数量</el-radio-button>
            <el-radio-button label="sum_price" >销售金额</el-radio-button>
          </el-radio-group> -->
          <el-date-picker  size="small" style="width:100px;"
            v-model="picktimeforsalesort.starttime"
            :clearable="picktimeforsalesort.clearable"
            :editable="picktimeforsalesort.editable"
            type="year"
            placeholder="起始时间"
          />
          <el-select style="width:100px;" v-model="rankingselect" class="m-2" placeholder="Select" size="small" @change="rankingRatioChange">
            <el-option
              v-for="item in dimension_options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </div>
        <div id="productRanking"></div>
      </div>
    </div>
  </div>
  <!-- 第二行 -->
  <div class="clo-2">
    <!-- 第一列 -->
    <div>
      <div>
        <div class="selectYeardiv">
          <el-date-picker  size="small" style="width:100px;"
            v-model="pickerconfig.defyear"
            :clearable="pickerconfig.clearable"
            :editable="pickerconfig.editable"
            type="year"
            placeholder="选择一个年份"
          />
          <el-radio-group v-model="table_data.yearonyearselect" size="small" style="vertical-align: top;" >
            <el-radio-button label="num">数量</el-radio-button>
            <el-radio-button label="sum_price">金额</el-radio-button>
          </el-radio-group>
        </div>
        <!-- 同比增长 -->
        <div id="YearOnYear-chart"></div>
      </div>
    </div>
    <!-- 第二列 -->
    <!-- 销售统计 -->
    <div class="center" id="Tread-chart">
    </div>
    
    <!-- 第三列 -->
    <div>
      <div>
        <div class="selectYeardiv">
          <el-date-picker  size="small" style="width:100px;"
            v-model="pickerconfig.defradio"
            type="year"
            :clearable="pickerconfig.clearable"
            :editable="pickerconfig.editable"
            placeholder="选择一个年份"
          />
          <el-radio-group v-model="table_data.ringRatioselect" size="small" style="vertical-align: top;" >
            <el-radio-button label="num">数量</el-radio-button>
            <el-radio-button label="sum_price">金额</el-radio-button>
          </el-radio-group>
        </div>
        <!-- 环比增长率 -->
        <div id="RingRatio-chart"></div>
      </div>
    </div>
  </div>
</template>

<script>
//reactive
import {ref,reactive,getCurrentInstance} from "vue"
const defbutton=ref('')
const loading = ref(false)
const picktimeforsalesort=reactive({editable:false,clearable:true,starttime:''});//销售排行的时间选择默认未选择时间
const dimension_options = [
  { label: '数量',value: 'num'},
  {label: '销售额',value: 'sum_price'},
  {label: '成本',value: 'cost_price'},
  {label: '利润',value: 'profit'},
  {label: '利润率',value: 'cost_ratio'},
  {label: '销售利润率',value: 'sale_ratio'}
]
export default {
    name:'SelfTotal',
    setup() {
      const {appContext} = getCurrentInstance();
      const global = appContext.config.globalProperties;
      const echarts=appContext.config.globalProperties.$echarts;
      const http=appContext.config.globalProperties.$http;
      const ranking_desc = ref('desc');//默认降序 产品排行
      const rankingselect=ref('num');//默认展示产品的数量排行
      const pickerconfig = reactive({editable:false,clearable:false,defyear:(new Date().getFullYear())+'',defradio:(new Date().getFullYear())+''});
      const table_data = reactive({
        productRanking:[],//产品销售排行数据
        yearOnYearData:[],//同比数据
        ringRatioData:[],//环比数据
        saleTotal:[],//销售统计数据
        yearonyearselect:'sum_price',//同比按钮切换
        ringRatioselect:'sum_price',//环比按钮切换数据
      })
      const spaceinfo = reactive({total_space:0,free_space:0,use_space:0,use_space_ratio:0});
      //数据概览的数据
      const data =reactive([
        {key:'sale_price',title:'销售额',num:"0"},
        {key:'profit',title:'利润',num:"0"},
        {key:'profit_ratio',title:'利润率%',num:"0"},
        {key:'order_num',title:'交易',num:"0"},
        {key:'out_num',title:'出库',num:"0"},
        {key:'customer_num',title:'客户',num:"0"},
        {key:'effective_customer',title:'成交客户',num:"0"},
        {key:'potential_customer',title:'潜在客户',num:"0"}
      ])
      //数据概览线条上面的数据
      const data_obj = reactive({product_num:0,cover_num:0,cover_ratio:0});
      //加载概览数据
      http.get('/total/self/get/selfTotal.do').then((res) => {
        let obj = res.data.plant[0];
        if(obj){
          for(var d in data){
            data[d].num=obj[data[d].key];
          }
          data_obj.product_num=obj.product_num;
          data_obj.cover_num=obj.cover_num;
          data_obj.cover_ratio=obj.cover_ratio;
        }
      })
     return {
      data,
      data_obj,
      ranking_desc,
      pickerconfig,
      spaceinfo,
      rankingselect,
      table_data,
      defbutton,
      echarts,
      global,
      http,
      loading,
      dimension_options,
      picktimeforsalesort,
     }
    },
    mounted(){
      this.loadDayTrendChart()
      this.loadProductSort()
      this.loadYearOnYear()
      this.loadRingRatio()
      this.loadSaleTotal()
      this.loadSpace()
    },
    watch:{
      "pickerconfig.defyear"(newVal){//同比选择时间的组件值发生变化时重新获取数据
        if(newVal){
          const that = this;
          let yearonyear = this.echarts.getInstanceByDom(document.getElementById('YearOnYear-chart'));
          yearonyear.showLoading();
          this.http.get('/total/self/get/yearOnYear.do',{params:{time:new Date(newVal).getFullYear(),cmd:1}}).then((res) => {
            let plant = res.data.plant;
            that.table_data.yearOnYearData=plant;
            yearonyear.hideLoading();
            yearonyear.setOption(
              this.getOptionByYearOnYear(plant,that.table_data.yearonyearselect)
            )
          })
        }
      },
      "table_data.yearonyearselect"(label){//切换数量和金额
        let yearonyear = this.echarts.getInstanceByDom(document.getElementById('YearOnYear-chart'));
        yearonyear.setOption(this.getOptionByYearOnYear(this.table_data.yearOnYearData,label));
      },
      "pickerconfig.defradio"(newVal){//环比选择时间的组件值发生变化时重新获取数据
        if(newVal){
          const that = this;
          let ringRatio = this.echarts.getInstanceByDom(document.getElementById('RingRatio-chart'));
          ringRatio.showLoading();
          this.http.get('/total/self/get/ringRatio.do',{params:{year:new Date(newVal).getFullYear()}}).then((res) => {
            let plant = res.data.plant;
            that.table_data.ringRatioData=plant;
            ringRatio.hideLoading();
            ringRatio.setOption(
              this.getOptionByRingRatio(plant,that.table_data.ringRatioselect)
            )
          })
        }
      },
      "table_data.ringRatioselect"(label){//切换数量和金额
        let ringRatio = this.echarts.getInstanceByDom(document.getElementById('RingRatio-chart'));
        ringRatio.setOption(this.getOptionByRingRatio(this.table_data.ringRatioData,label));
      },
      "picktimeforsalesort.starttime"(newVal){
        let productRanking = this.echarts.getInstanceByDom(document.getElementById('productRanking'));
        const that= this;
        productRanking.showLoading();
        this.http.get('/total/self/get/productRanking.do',{params:{starttime:new Date(newVal).getFullYear(),orderby:`${that.rankingselect} ${that.ranking_desc}`}}).then((res) => {
          let plant = res.data.plant;
          that.table_data.productRanking=plant;
          productRanking.hideLoading();
          productRanking.setOption(that.getProductSortOption(that.table_data.productRanking,that.rankingselect))
        })
      }
    },
    methods:{
      //30天销售业绩  
      loadDayTrendChart(){
        this.http.get('/total/self/get/day30.do').then((res) => {
          let plant = res.data.plant;
          let dayTrendchart= this.echarts.init(
            document.getElementById('dayTrend-chart')
          )
          dayTrendchart.setOption(
            {
                title: {
                  text: '最近销售数据'
                },
                legend: {
                  data: [ '销售额','利润']
                },
                tooltip: {
                  trigger: 'axis',
                  axisPointer: {
                    type: 'cross',
                    label: {
                      backgroundColor: '#6a7985'
                    }
                  }
                },
                toolbox: this.global.echartsToolbox(),
                grid: {
                  left: '3%',
                  right: '4%',
                  bottom: '3%',
                  containLabel: true
                },
                xAxis: [
                  {
                    type: 'category',
                    data:(()=>{
                      let list = [];
                      plant.map((item)=>{
                        list.push(item.time);
                      })
                      return list;
                    })()
                  }
                ],
                yAxis: [
                  {
                    type: 'value'
                  }
                ],
                series: [
                  {
                    type: 'line',
                    stack: 'Total',
                    emphasis: {
                      focus: 'series'
                    },
                    ...this.global.echartLineColor("数量"),
                    name:'数量',
                    data:(()=>{
                      let list = [];
                      plant.map((item)=>{
                        list.push(item.num);
                      })
                      return list;
                    })()
                  },
                  {
                    type: 'line',
                    stack: 'Total',
                    emphasis: {
                      focus: 'series'
                    },
                    ...this.global.echartLineColor("销售额"),
                    name:'销售额',
                    data:(()=>{
                      let list = [];
                      plant.map((item)=>{
                        list.push(item.price);
                      })
                      return list;
                    })()
                  },
                  {
                    type: 'line',
                    stack: 'Total',
                    emphasis: {
                      focus: 'series'
                    },
                    ...this.global.echartLineColor("利润"),
                    name:'利润',
                    data:(()=>{
                      let list = [];
                      plant.map((item)=>{
                        list.push(item.profit);
                      })
                      return list;
                    })()
                  }
                ]
            }
          )
        })
      },
      //销售最好产品
      loadProductSort(){
        const that=this;
        let productRanking = this.echarts.init(
          document.getElementById('productRanking')
        )
        productRanking.showLoading();
        this.http.get('/total/self/get/productRanking.do',{params:{starttime:new Date(that.picktimeforsalesort.starttime||"1979-01-01").getFullYear(),orderby:`${that.rankingselect} ${that.ranking_desc}`}}).then((res) => {
          let plant = res.data.plant;
          that.table_data.productRanking=plant;
          productRanking.hideLoading();
          productRanking.setOption(that.getProductSortOption(that.table_data.productRanking,that.rankingselect))
        })
        productRanking.on("click",function(){
          if(that.ranking_desc=='desc'){
            that.ranking_desc="asc"
          }else{
            that.ranking_desc="desc"
          }
          productRanking.showLoading();
          that.http.get('/total/self/get/productRanking.do',{params:{starttime:new Date(that.picktimeforsalesort.starttime||"1979-01-01").getFullYear(),orderby:`${that.rankingselect} ${that.ranking_desc}`}}).then((res) => {
            let plant = res.data.plant;
            that.table_data.productRanking=plant;
            productRanking.hideLoading();
            productRanking.setOption(that.getProductSortOption(that.table_data.productRanking,that.rankingselect))
          })
        })
      },
      //销售产品排行图标参数设置
      getProductSortOption(data,key){
        const that=this;
        return {
            title: {
              text: 'Top产品'
            },
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                type: 'shadow'
              },
              extraCssText:this.global.$echartsconfig.tooltip.extraCssText,
              formatter(params) {
                let param=params[0],data=param.data;
                //console.log(param);
                let title="时间:总统计";
                if(that.picktimeforsalesort.starttime){
                  title=`时间:${new Date(that.picktimeforsalesort.starttime).getFullYear()}年 至 今`
                }
                let text=`<div style='font-size:12px; margin-left:40px;border: 1px solid white;border-radius: 5px;padding: 5px;'>销售总量:${data.num}个 销售总额:${data.sum_price}￥<br/>成本总额:${data.cost_price}￥ 总利润:${data.profit}￥<br/>利润率:${data.cost_ratio}% 销售利润率:${data.sale_ratio}%</div>`
                if(that.rankingselect=='num'){//数量
                  let circle = `<span style="display:inline-block;margin-right:5px;border-radius:50%;width:10px;height:10px;left:5px;background-color:`;//小圆点样式
                  let data0 = `${circle}${param.color}"></span>销售数量:  ${param.value}`;
                  return `${title} <br/>${param.name}<br/>${data0}${text}`;
                }else if(that.rankingselect=='sum_price'){//销售额
                  let circle = `<span style="display:inline-block;margin-right:5px;border-radius:50%;width:10px;height:10px;left:5px;background-color:`;//小圆点样式
                  let data0 = `${circle}${param.color}"></span>销售额:  ${param.value}`;
                  return `${title} <br/>${param.name}<br/>${data0}${text}`;
                }else if(that.rankingselect=='cost_price'){//成本
                  let circle = `<span style="display:inline-block;margin-right:5px;border-radius:50%;width:10px;height:10px;left:5px;background-color:`;//小圆点样式
                  let data0 = `${circle}${param.color}"></span>成本:  ${param.value}`;
                  return `${title} <br/>${param.name}<br/>${data0}${text}`;
                }else if(that.rankingselect=='profit'){//利润
                  let circle = `<span style="display:inline-block;margin-right:5px;border-radius:50%;width:10px;height:10px;left:5px;background-color:`;//小圆点样式
                  let data0 = `${title} <br/>${circle}${param.color}"></span>利润:  ${param.value}`;
                  return `${param.name}<br/>${data0}${text}`;
                }else if(that.rankingselect=='cost_ratio'){//成本利润率
                  let circle = `<span style="display:inline-block;margin-right:5px;border-radius:50%;width:10px;height:10px;left:5px;background-color:`;//小圆点样式
                  let data0 = `${title} <br/>${circle}${param.color}"></span>利润率:  ${param.value} %`;
                  return `${title} <br/>${param.name}<br/>${data0}${text}`;
                }else if(that.rankingselect=='sale_ratio'){//销售利润率
                  let circle = `<span style="display:inline-block;margin-right:5px;border-radius:50%;width:10px;height:10px;left:5px;background-color:`;//小圆点样式
                  let data0 = `${title} <br/>${circle}${param.color}"></span>销售利润率:  ${param.value} %`;
                  return `${title} <br/>${param.name}<br/>${data0}${text}`;
                }
              }
            },
            toolbox: this.global.echartsToolbox(),
            legend: {},
            dataZoom:[
              {
                yAxisIndex:0,
                type:'inside',
                startValue:0,
                endValue:9
              }
            ],
            grid: {
              left: '3%',
              right: '4%',
              bottom: '13%',
              top:"10%",
              containLabel: true
            },
            xAxis: {
              type: 'value',
              boundaryGap: [0, 0.01]
            },
            yAxis: {
              type: 'category',
              inverse:true,//排序
              data:(()=>{
                let list = [];
                data.map((item)=>{
                  list.push(item.number);
                })
                return list;
              })()
            },
            series: 
              {
                type: 'bar',
                data:(()=>{
                  let list = [];
                  data.map((item)=>{
                    list.push({value:item[key],...item});
                  })
                  return list;
                })()
              }
          }
      },
      //切换排行数据维度，数量和销售额
      rankingRatioChange(label){
        let productRanking = this.echarts.getInstanceByDom(document.getElementById('productRanking'));
        const that= this;
        productRanking.showLoading();
        this.http.get('/total/self/get/productRanking.do',{params:{starttime:new Date(that.picktimeforsalesort.starttime||"1979-01-01").getFullYear(),orderby:`${label} ${that.ranking_desc}`}}).then((res) => {
          let plant = res.data.plant;
          that.table_data.productRanking=plant;
          productRanking.hideLoading();
          productRanking.setOption(that.getProductSortOption(that.table_data.productRanking,label))
        })
      },
       //同比
      loadYearOnYear(){
        const that =this;
        const echarts = this.echarts
        let yearOnYear = echarts.init(
          document.getElementById('YearOnYear-chart')
        )
        yearOnYear.showLoading();
        this.http.get('/total/self/get/yearOnYear.do',{params:{time:new Date(this.pickerconfig.defyear).getFullYear(),cmd:1}}).then((res) => {
          let plant = res.data.plant;
          that.table_data.yearOnYearData=plant;
          yearOnYear.hideLoading();
          yearOnYear.setOption(
            this.getOptionByYearOnYear(plant,that.table_data.yearonyearselect)
          )
        })
        yearOnYear.on('click',(obj)=>{//向下钻取
          if(obj.name.indexOf('季度')!=-1&&obj.seriesIndex==1){
            yearOnYear.showLoading();
            this.http.get('/total/self/get/yearOnYear.do',{params:{time:new Date(this.pickerconfig.defyear).getFullYear(),cmd:2}}).then((res) => {
              let plant = res.data.plant;
              that.table_data.yearOnYearData=plant;
              yearOnYear.hideLoading();
              yearOnYear.setOption(
                this.getOptionByYearOnYear(plant,that.table_data.yearonyearselect)
              )
            })
            // tread.setOption(this.saleTotalOption()) 
          }else if(obj.name.indexOf('月')!=-1&&obj.seriesIndex==1){
            yearOnYear.showLoading();
            this.http.get('/total/self/get/yearOnYear.do',{params:{time:obj.name.substring(0,7),cmd:3}}).then((res) => {
              let plant = res.data.plant;
              that.table_data.yearOnYearData=plant;
              yearOnYear.hideLoading();
              yearOnYear.setOption(
                this.getOptionByYearOnYear(plant,that.table_data.yearonyearselect)
              )
            })
          }
          
        })
        
      },
      //设置产品同比参数
      getOptionByYearOnYear(data,key){
        const that = this;
        return {
              title: {
                text: '同比增长'
              },
              tooltip: {
                trigger: 'axis',
                axisPointer: {
                  type: 'shadow',
                  label: {
                    show: true
                  }
                },
                confine:true,
                // position:[120,0],
                extraCssText:this.global.$echartsconfig.tooltip.extraCssText,
                formatter(params) {
                  let tags = that.table_data.yearonyearselect=='num'?'个':'￥'
                  let param1=params[0], param2=params[1];
                  let obj1=param1.data,obj2=param2.data;
                  let circle1 = `<span style="display:inline-block;margin-right:5px;border-radius:50%;width:10px;height:10px;left:5px;background-color:`;//小圆点样式
                  let data1 = `${circle1}${param1.color}"></span>${param1.seriesName}：  ${param1.value} ${tags}<br/>
                  <div style='border: 1px solid white;border-radius: 5px;padding: 5px;'><span style='font-size:12px;margin-left: 45px;'>销售量：${obj1.num}个,销售额:${obj1.sum_price}￥</psan><br/>
                    <span style='font-size:12px;margin-left: 45px;'> 利润:${obj1.profit||0.00} ￥, 利润率:%${obj1.profit_rate||0.00} </span></div><br/>`;//第二行显示
                  let circle2 = `<span style="display:inline-block;margin-right:5px;border-radius:50%;width:10px;height:10px;left:5px;background-color:`;//小圆点样式
                  let data2 = `${circle2}${param2.color}"></span>${param2.seriesName}：  ${param2.value} ${tags}<br/>
                  <div style='border: 1px solid white;border-radius: 5px;padding: 5px;'><span style='font-size:12px;margin-left: 45px;'>销售量：${obj2.num}个,销售额:${obj2.sum_price}￥</psan><br/>
                    <span style='font-size:12px;margin-left: 45px;'> 利润:${obj2.profit||0.00} ￥, 利润率:%${obj2.profit_rate||0.00} </span><br/>
                    <span style='font-size:12px;margin-left: 45px;'> 同比销售额增长：  ${param1.value<=0?'100.00%':((param2.value-param1.value)/param1.value*100).toFixed(2)+'%'},
                      同比利润率增长：${(obj2.profit_rate-obj1.profit_rate).toFixed(2)} % </span></div>`;//第一行显示
                 // let circle3 = `<span style="display:inline-block;margin-right:5px;border-radius:50%;width:10px;height:10px;left:5px;background-color:`;//小圆点样式
                 // let data3 = `${circle3}red"></span>同比增长：  ${param1.value<=0?'100.00%':((param2.value-param1.value)/param1.value*100).toFixed(2)+'%'}`;//第三行显示
                  return `${param2.name}<br/>${data2}<br/>${param1.name}<br/>${data1}`;
                }
              },
              toolbox: this.global.echartsToolbox(),
              calculable: true,
              grid: {
                top: '12%',
                left: '1%',
                right: '10%',
                bottom: '5%',
                containLabel: true
              },
              xAxis: 
              {
                type: 'category',
                data:(()=>{
                  let list = [];
                  data.current.map((item,i)=>{
                    list.push(item.name+" 同比 "+data.SamePeriod[i].name);
                  })
                  return list;
                })(),
                axisLabel: {
                  show: true,
                  // interval: 0,
                  // color: '#ffffff',
                  rotate:40,
                  fontSize:9,
                  formatter: function(value) {
                    var current = value.split(" 同比 ")[0],samePeriod = value.split(" 同比 ")[1];
                    return samePeriod+"\n"+current
                  }
                }
              },
              yAxis: [
                {
                  type: 'value',
                  // axisLabel: {
                  //   formatter: function (a) {
                  //     a = +a;
                  //     return isFinite(a) ? echarts.format.addCommas(+a / 1000) : '';
                  //   }
                  // }
                }
              ],
              series: [
                {
                  name: '同期',
                  type: 'bar',
                  data:(()=>{
                    let list = [];
                    data.SamePeriod.map((item)=>{
                      list.push({value:item[key],...item});
                    })
                    return list;
                  })()
                },
                {
                  name: '本期',
                  type: 'bar',
                  data:(()=>{
                    let list = [];
                    data.current.map((item)=>{
                      list.push({value:item[key],...item});
                    })
                    return list;
                  })()
                }
              ]
            }
      },
      //环比
      loadRingRatio(){
        const echarts=this.echarts,that=this;
        let RingRatio = echarts.init(
          document.getElementById('RingRatio-chart')
        )
        RingRatio.showLoading();
        this.http.get('/total/self/get/ringRatio.do',{params:{year:new Date(that.pickerconfig.defradio).getFullYear()}}).then((res) => {
          let plant = res.data.plant;
          that.table_data.ringRatioData=plant;
          RingRatio.hideLoading();
          RingRatio.setOption(
            this.getOptionByRingRatio(that.table_data.ringRatioData,that.table_data.ringRatioselect)
          )
        })
      },
      getOptionByRingRatio(data,key){
        const that=this;
        return {
            title: {
              text: '环比增长'
            },
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                type: 'shadow',
                label: {
                  show: true
                }
              },
              extraCssText:this.global.$echartsconfig.tooltip.extraCssText,
              formatter(params) {
                let tags = that.table_data.ringRatioselect=='num'?'个':'￥'
                let param1=params[0], param2=params[1];
                let obj1=param1.data,obj2=param2.data;
                let circle1 = `<span style="display:inline-block;margin-right:5px;border-radius:50%;width:10px;height:10px;left:5px;background-color:`;//小圆点样式
                let data1 = `${circle1}${param1.color}"></span>${param1.seriesName}：  ${param1.value} ${tags}<br/>
                  <div style='border: 1px solid white;border-radius: 5px;padding: 5px;'><span style='font-size:12px;margin-left: 45px;'>
                    销售量：${obj1.num}个,销售额:${obj1.sum_price}￥</psan><br/>
                    <span style='font-size:12px;margin-left: 45px;'> 利润:${obj1.profit||0.00} ￥, 利润率:%${obj1.profit_ratio||0.00} </span></div><br/>`;//第二行显示
                let circle2 = `<span style="display:inline-block;margin-right:5px;border-radius:50%;width:10px;height:10px;left:5px;background-color:`;//小圆点样式
                let data2 = `${circle2}${param2.color}"></span>${param2.seriesName}：  ${param2.value} ${tags}<br/>
                  <div style='border: 1px solid white;border-radius: 5px;padding: 5px;'><span style='font-size:12px;margin-left: 45px;'>销售量：${obj2.num}个,销售额:${obj2.sum_price}￥</psan><br/>
                    <span style='font-size:12px;margin-left: 45px;'> 利润:${obj2.profit||0.00} ￥, 利润率:%${obj2.profit_ratio||0.00} </span><br/>
                    <span style='font-size:12px;margin-left: 45px;'> 销售环比增长：  ${param1.value<=0?'100.00%':((param2.value-param1.value)/param1.value*100).toFixed(2)+'%'},
                      利润率环比增长：${(obj2.profit_ratio-obj1.profit_ratio).toFixed(2)} % </span></div>`;//第三行显示
                //let circle3 = `<span style="display:inline-block;margin-right:5px;border-radius:50%;width:10px;height:10px;left:5px;background-color:`;//小圆点样式
                //let data3 = `${circle3}red"></span>环比增长：  ${param1.value<=0?'100.00%':((param2.value-param1.value)/param1.value*100).toFixed(2)+'%'}`;//第三行显示
                return `${param2.name}<br/>${data2}<br/>${param1.name}<br/>${data1}`;
              }
            },
            toolbox: this.global.echartsToolbox(),
            calculable: true,
            grid: {
              top: '12%',
              left: '1%',
              right: '10%',
              bottom: '5%',
              containLabel: true
            },
            xAxis: 
            {
              type: 'category',
              data:(()=>{
                let list = [];
                data.current.map((item,i)=>{
                  list.push(item.name+"环比"+data.previous[i].name);
                })
                return list;
              })(),
              axisLabel: {
                show: true,
                fontSize:9,
                rotate:40,
                formatter: function(value) {
                  var current = value.split("环比")[0],previous = value.split("环比")[1];
                  return previous+"\n"+current
                }
              }
            },
            yAxis: [
              {
                type: 'value'
              }
            ],
            series: [
              {
                name: '上期',
                type: 'bar',
                data:(()=>{
                  let list = [];
                  data.previous.map((item)=>{
                    list.push({value:item[key],...item});
                  })
                  return list;
                })()
              },
              {
                name: '本期',
                type: 'bar',
                data:(()=>{
                  let list = [];
                  data.current.map((item)=>{
                    list.push({value:item[key],...item});
                  })
                  return list;
                })()
              }
            ]
          }
      },  
      //销售统计
      loadSaleTotal(){
        const that=this
        const echarts = this.echarts
        let tread = echarts.init(
          document.getElementById('Tread-chart')
        )
        tread.showLoading();
        this.http.get('/total/self/get/saleTotal.do',{params:{cmd:1}}).then((res) => {
          let plant = res.data.plant;
          that.table_data.saleTotal=plant;
          tread.hideLoading();
          tread.setOption(that.saleTotalOption(that.table_data.saleTotal))
        })
        tread.on('click',(obj)=>{//当图形为年或月的时候，向下钻取
          if(obj.data.keys==='year'){
            tread.showLoading();
            that.http.get('/total/self/get/saleTotal.do',{params:{cmd:2,time:obj.data.time}}).then((res) => {
              let plant = res.data.plant;
              that.table_data.saleTotal=plant;
              tread.hideLoading();
              tread.setOption(that.saleTotalOption(that.table_data.saleTotal))
            })
          }else if(obj.data.keys==='quarter'){//点击季度的时候
            tread.showLoading();
            that.http.get('/total/self/get/saleTotal.do',{params:{cmd:3,time:obj.data.time.substring(0,4)}}).then((res) => {
              let plant = res.data.plant;
              that.table_data.saleTotal=plant;
              tread.hideLoading();
              tread.setOption(that.saleTotalOption(that.table_data.saleTotal))
            })
          }else if(obj.data.keys==='month'){//点击月的时候
            tread.showLoading();
            that.http.get('/total/self/get/saleTotal.do',{params:{cmd:4,time:obj.data.time}}).then((res) => {
              let plant = res.data.plant;
              that.table_data.saleTotal=plant;
              tread.hideLoading();
              tread.setOption(that.saleTotalOption(that.table_data.saleTotal))
            })
          }
          
        })
      },
      saleTotalOption(data){//销售统计图形参数配置
        return {
            title: {
              text: '销售统计'
            },
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                type: 'shadow'
              },
              extraCssText:this.global.$echartsconfig.tooltip.extraCssText,
              formatter(params) {
                let param1=params[0],data=param1.data;
                let circle1 = `<span style="display:inline-block;margin-right:5px;border-radius:50%;width:10px;height:10px;left:5px;background-color:`;//小圆点样式
                let data1 = `${circle1}${param1.color}"></span>${param1.seriesName}：  ${param1.value/10}`;//第一行行显示
                let param2=params[1];
                let circle2 = `<span style="display:inline-block;margin-right:5px;border-radius:50%;width:10px;height:10px;left:5px;background-color:`;//小圆点样式
                let data2 = `${circle2}${param2.color}"></span>${param2.seriesName}：  ${param2.value}`;//第二行显示
                let param3=params[2];
                let circle3 = `<span style="display:inline-block;margin-right:5px;border-radius:50%;width:10px;height:10px;left:5px;background-color:`;//小圆点样式
                let data3 = `${circle3}${param3.color}"></span>${param3.seriesName}：  ${param3.value}`;//第二行显示
                let text=`<div style='font-size:12px; margin-left:40px;border: 1px solid white;border-radius: 5px;padding: 5px;'>销售量:${data.num}个 销售额:${data.sum_price}￥<br/> 利润:${data.profit}￥ 利润率:${data.profit_ratio}% </div>`
                return `${param1.name} <br/>${data1}<br/>${data2}<br/>${data3}${text}`;
              }
            },
            // legend: {
            //   data: [ '销售量','销售额','利润额']
            // },
            toolbox: this.global.echartsToolbox(),
            calculable: true,
            grid: {
              top: '12%',
              left: '1%',
              right: '10%',
              bottom: '5%',
              containLabel: true
            },
            xAxis: [
              {
                type: 'category',
                data:(()=>{
                  let list = [];
                  data.map((item)=>{
                    list.push(item.name);
                  })
                  return list;
                })(),
                axisLabel: {
                  show: true,
                  rotate:40,
                  fontSize:9,
                  formatter: function(value) {
                    return value
                  }
                }
              }
            ],
            yAxis: [
              {
                type: 'value'
              }
            ],
            series: [
              {
                name: '销售量',
                type: 'bar',
                stack: 'total',
                data:(()=>{
                  let list = [];
                  data.map((item)=>{
                    list.push({value:item.num*10,...item});
                  })
                  return list;
                })()
              },
              {
                name: '销售额',
                type: 'bar',
                stack: 'total',
                data:(()=>{
                  let list = [];
                  data.map((item)=>{
                    list.push({value:item.sum_price,...item});
                  })
                  return list;
                })()
              },
              {
                name: '利润额',
                type: 'bar',
                stack: 'total',
                data:(()=>{
                  let list = [];
                  data.map((item)=>{
                    list.push({value:item.profit,...item});
                  })
                  return list;
                })()
              }
            ]
          }
      },
      loadSpace(){//加载服务器磁盘信息（固定拿的D盘的，因为D盘有时候磁盘老是满了）
        const that = this;
        this.http.get('/total/self/get/space.do').then((res) => {
          for(var d in res.data.plant){
            that.spaceinfo[d]=res.data.plant[d];
          }
        })
      },
      //清理磁盘（固定清理D盘的\WEBAPP\bakDB下的数据库备份，清理两天前的，因为D盘有时候磁盘老是满了）
      clearSapce(){
        const that = this;
        this.loading=true;
        this.http.get('/total/self/clear/space.do').then((res) => {
          this.loading=false
          if(res.data.success){
            that.global.success({message:"操作完成!"});
            that.loadSpace();//重新加载磁盘信息。
          }else{
            that.global.error({message:"操作失败!"});
          }
        })
      }
    }
}
</script>

<style scoped>
/* div{
   transform: translateX(-50%) rotate(-90deg); 
   transform: scale(0.8); 
} */
/* .test{
  transform: translateX(-50%) rotate(-20deg);
  text-align: center;
  width: 100px;
} */
.spaceinfo{
  height: 40px;line-height: 30px;margin-left: 15px;font-size: 12px;
}
.spaceinfo span,button{
  margin-left: 1px;
}
#map-chart{
  height: 250px;
}
#YearOnYear-chart,#RingRatio-chart{
  height: 250px;
}
.clo-2{
  height: 250px;
}
.clo-2>div{
  width: 30%;
  /* border: 1px solid; */
  height: 100%;
  float: left;
}
#productRanking{
  height: 250px;
}
#dayTrend-chart{
  height: 250px;
}
.clo-1{
  height: 250px;
  width: 100%;
}
.clo-1>div{
  width: 30%;
  /* border: 1px solid; */
  height: 100%;
  float: left;
}
.center{
  width: 40% !important;
}

.main-item1{
  /* background-color: white; */
  height: 200px;
}
.main-item-list{
  margin-left: 5px;
  height: 100px;
}
.main-item-list div{
    width: calc(30% - 5px);
  }
#data-overview{
  height: 150px;
  width: 100%;
}
@media screen and (min-width:1200px){
  #YearOnYear-chart,#RingRatio-chart{
    height: 350px;
  }
  .clo-2{
    height: 350px;
  }
  #productRanking{
    height: 300px;
  }
  #dayTrend-chart{
    height: 300px;
  }
  .clo-1{
    height: 300px;
  }
  .main-item-list{
    height: 120px;
  }
  .main-item-list div{
    height: 35px;
    padding-top: 1px;
  }
  .main-item-list div h6{
    line-height: 20px;
  }
  .main-item1{
    height: 300px;
  }
  .spaceinfo span,button{
    margin-left: 3px;
  }
}
@media screen and (min-width:1400px){
  #YearOnYear-chart,#RingRatio-chart{
    height: 400px;
  }
  .clo-2{
    height: 400px;
  }
  #productRanking{
    height: 350px;
  }
  #dayTrend-chart{
    height: 350px;
  }
  .clo-1{
    height: 350px;
  }
  .main-item-list{
    height: 170px;
  }
  .main-item-list div{
    height: 50px;
    padding-top: 3px;
  }
  .main-item-list div h6{
    line-height: 30px;
  }
  .main-item1{
    height: 400px;
  }
  .spaceinfo span,button{
    margin-left: 5px;
  }
}
</style>