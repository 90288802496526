<template>
    <el-dialog
        style="width:800px;height: 630px;"
      v-model="data.centerDialogVisible"
      title=""
      destroy-on-close
      @close="backclose"
      :close-on-click-modal="true"
      :draggable="true"
      center
    >
        <div style="float:left;width:35%;color: black;" v-loading="data.loading">
            <div style="font-size:12px;">
                <h3 style="margin: 0px;">{{data.customerBase.name}}</h3>
                <ul style="margin:0px;list-style:none;padding:5px;">
                    <li><strong>别名:</strong>{{data.customerBase.alias}} <strong>地区:</strong>{{data.customerBase.area}}({{data.customerBase.zh_country}})</li>
                    <li><strong>标签:</strong>{{data.customerBase.label}} <strong>级别:</strong>{{data.customerBase.level}}</li>
                    <li><strong>电话:</strong>{{data.customerBase.mobile}} </li>
                    <li><strong>邮箱:</strong>{{data.customerBase.email}}</li>
                    <el-tooltip
                    class="box-item"
                    effect="dark"
                    :content="data.customerBase.address"
                    placement="bottom-end"
                    >
                    <li style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis;"><strong>地址:</strong>{{data.customerBase.address}}</li>
                    </el-tooltip>
                </ul>
            </div>
            <div>
                <!-- 产品分布 -->
                <div id="productSpread-chart" :actived="data.actived=true" style="width:200px;height:200px;"></div>
            </div>
        </div>
        <div style="float:left;width:65%;color: black;" v-loading="data.loading">
            <div style="font-size:12px;">
                <table class="table-total">
                    <tr>
                        <th>订单量/占比(笔)</th><th>产品数量/占比(PCS)</th><th>产品覆盖/占比(数量)</th>
                    </tr>
                    <tr>
                        <td>{{data.customerBase.order_num}} / {{data.customerBase.order_ratio}}%</td><td>{{data.customerBase.product_num}} / {{data.customerBase.product_num_ratio}}%</td><td>{{data.customerBase.product_type_num}} / {{data.customerBase.product_ratio}}%</td>
                    </tr>
                    <tr>
                        <th>销售额/占比(￥)</th><th>销售成本/占比(￥)</th><th>利润/利润率/占比(￥)</th>
                    </tr>
                    <tr>
                        <td>{{data.customerBase.sale_price}} / {{data.customerBase.sale_price_ratio}}%</td><td>{{data.customerBase.cost_price}} / {{data.customerBase.current_cost_price_ratio}}%</td><td>{{data.customerBase.profit}} / {{data.customerBase.profit_rate}}% / {{data.customerBase.profit_ratio}}%</td>
                    </tr>
                </table>
            </div>
            <div>
                <div class="selectYeardiv">
                <el-date-picker  size="small" style="width:100px;"
                    v-model="data.yearOnYearConfig.defyear"
                    :clearable="false"
                    :editable="false"
                    type="year"
                    placeholder="选择一个年份"
                />
                <el-radio-group size="small" style="vertical-align: top;" v-model="data.yearOnYearConfig.yearonyearselect" >
                    <el-radio-button label="num">数量</el-radio-button>
                    <el-radio-button label="sum_price">金额</el-radio-button>
                </el-radio-group>
                </div>
                <!-- 同比增长 -->
                <div id="CustYearOnYear-chart" :activid="data.activedYearOnYear=true" style="width:500px;height:300px;"></div>
            </div>
        </div>
        <div style="float: left;width:100%;">
            <div id="HistoricalTrend-chart" :activid="data.activedHistorical=true" style="width:100%;height:200px;"></div>
        </div>
    </el-dialog>
  </template>

<script>
import {reactive,getCurrentInstance} from "vue"
export default {
    name:'CustomerPanel',
    setup(){
        const data = reactive({
            loading:true,
            centerDialogVisible:false,
            showcustomer:false,
            customerBase:{},//客户基本信息
            productSpread:[],//产品分布数据
            staffid:0,
            customerid:0,
            actived:false,
            activedHistorical:false,
            activedYearOnYear:false,
            yearOnYearData:[],
            yearOnYearConfig:{defyear:new Date().getFullYear()+'',yearonyearselect:'sum_price'},//同比配置
        })
        const {appContext} = getCurrentInstance();
        const global = appContext.config.globalProperties;
        const echarts=appContext.config.globalProperties.$echarts;
        const http=appContext.config.globalProperties.$http;
        
        return {
            data,
            echarts,
            http,
            global
        }
    },
    watch:{
        "data.actived"(newVal){
            if(newVal){
                this.loadProductSpread(this.data.customerid);
            }
           
        },
        "data.activedHistorical"(newVal){
            console.log(newVal);
            if(newVal){
                this.loadHistoricalTrend(this.data.customerid);
            }
        },
        "data.activedYearOnYear"(newVal){
            if(newVal){
                this.echarts.init(
                    document.getElementById('CustYearOnYear-chart')
                )
                this.loadYearOnYear(this.data.customerid);
            }
        },
        "data.yearOnYearConfig.defyear"(newVal){//选择年份时重新加载同比图
            if(newVal&&this.data.activedYearOnYear){
                this.loadYearOnYear(this.data.customerid);
            }
        },
        "data.yearOnYearConfig.yearonyearselect"(newVal){//选择数量金额切换时
            if(newVal){
                let yearOnYear = this.echarts.getInstanceByDom(
                    document.getElementById('CustYearOnYear-chart')
                )
                yearOnYear.setOption(this.getOptionByYearOnYear(this.data.yearOnYearData,newVal));
            }
        }
    },
    mounted(){
    },
    methods:{
        backclose(){
            let productSpreadchart = document.getElementById('productSpread-chart');
            if(productSpreadchart){
                productSpreadchart = this.echarts.getInstanceByDom(productSpreadchart)
                if(productSpreadchart){
                    productSpreadchart.dispose();
                }
            }
            let CustYearOnYear = document.getElementById('CustYearOnYear-chart');
            if(CustYearOnYear){
                CustYearOnYear = this.echarts.getInstanceByDom(CustYearOnYear)
                if(CustYearOnYear){
                    CustYearOnYear.dispose();
                }
            }
            let HistoricalTrend = document.getElementById('HistoricalTrend-chart');
            if(HistoricalTrend){
                HistoricalTrend = this.echarts.getInstanceByDom(HistoricalTrend)
                if(HistoricalTrend){
                    HistoricalTrend.dispose();
                }
            }
            this.data.showcustomer=false;
            this.data.customerid=0;
            this.data.staffid=0;
            this.data.actived=false;
            this.data.activedHistorical=false;
            this.data.activedYearOnYear=false;
        },
        open(staffid,customerid){
            const that=this;
            this.data.loading=true;
            this.data.centerDialogVisible=true;
            this.data.customerid=customerid;
            this.data.staffid=staffid;
            this.http.get('/total/sale/customer/get/Total.do',{params:{staffid:staffid,customerid:customerid}}).then((res) => {
                that.data.loading=false;
                if(res.data.success){
                    that.data.customerBase=res.data.plant[0];
                }
            })
        },
        loadProductSpread(customerid){//加载客户产品分布图
            if(!document.getElementById('productSpread-chart')){
                return;
            }
            const that = this
            //产品类型
            let productSpread = that.echarts.init(
                document.getElementById('productSpread-chart')
            )
            productSpread.showLoading();
            this.http.get('/total/sale/customer/get/productSpread.do',{params:{customerid:customerid}}).then((res) => {
                productSpread.hideLoading();
                that.data.productSpread= res.data.plant;
                let count = res.data.plant.length;
                productSpread.setOption(
                {
                    title: {
                        text: '分布产品',
                        left: 'left',
                        textStyle:{
                            fontSize: 13,
                        },
                        top:'0%'
                    },
                    toolbox: {
                        show: true,
                        feature: {
                            dataView: { show: true, readOnly: true },
                        }
                    },
                    tooltip: {
                        trigger: 'item',
                        confine:true,
                        formatter(params) {
                            let circle1 = `<span style="display:inline-block;margin-right:5px;border-radius:50%;width:10px;height:10px;left:5px;background-color:`;//小圆点样式
                            let data1 = `${circle1}${params.color}"></span>购买：  ${params.value} 个`;//第一行行显示
                            return `${params.name} <br/>${data1}<br/>`;
                        }
                    },
                    series: [
                        {
                        name: '购买产品',
                        type: 'pie',
                        radius: ['30%', '80%'],
                        label: { //  饼图图形上的文本标签
                            normal: { // normal 是图形在默认状态下的样式
                                show: true,
                                position: 'center',
                                color: '#000',
                                fontSize: 9,
                                fontWeight: 'bold',
                                formatter(){
                                    return `购买${count}种产品`;
                                }
                            }
                        },
                        data: that.data.productSpread,
                        emphasis: {
                            itemStyle: {
                            shadowBlur: 10,
                            shadowOffsetX: 0,
                            shadowColor: 'rgba(0, 0, 0, 0.5)'
                            }
                        }
                        }
                    ]
                    }
                )
            })
        },
        loadHistoricalTrend(customerid){//加载客户购买历史
            this.http.get('/total/sale/customer/get/historicalTrend.do',{params:{customerid:customerid}}).then((res) => {
                let plant = res.data.plant;
                console.log(1);
                let dayTrendchart= this.echarts.init(
                    document.getElementById('HistoricalTrend-chart')
                )
                dayTrendchart.setOption(
                    {
                        title: {
                        text: '历史销售'
                        },
                        legend: {
                        data: [ '销售额','销售成本','利润']
                        },
                        tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'cross',
                            label: {
                            backgroundColor: '#6a7985'
                            }
                        }
                        },
                        toolbox: this.global.echartsToolbox(),
                        grid: {
                        left: '3%',
                        right: '4%',
                        bottom: '3%',
                        containLabel: true
                        },
                        dataZoom: [
                            {
                                type: 'inside',
                                start: plant.length>30?70:0,
                                end: 100
                            },
                            {
                                type: 'inside',
                                start: plant.length>30?70:0,
                                end: 100
                            }
                        ],
                        xAxis: [
                        {
                            type: 'category',
                            data:(()=>{
                            let list = [];
                            plant.map((item)=>{
                                list.push(item.time);
                            })
                            return list;
                            })()
                        }
                        ],
                        yAxis: [
                        {
                            type: 'value'
                        }
                        ],
                        series: [
                        {
                            type: 'line',
                            stack: 'Total',
                            emphasis: {
                                focus: 'series'
                            },
                            ...this.global.echartLineColor("数量"),
                            name:'数量',
                            data:(()=>{
                            let list = [];
                            plant.map((item)=>{
                                list.push(item.product_num);
                            })
                            return list;
                            })()
                        },
                        {
                            type: 'line',
                            stack: 'Total',
                            emphasis: {
                            focus: 'series'
                            },
                            ...this.global.echartLineColor("销售额"),
                            name:'销售额',
                            data:(()=>{
                            let list = [];
                            plant.map((item)=>{
                                list.push(item.sale_price);
                            })
                            return list;
                            })()
                        },{
                            type: 'line',
                            stack: 'Total',
                            emphasis: {
                            focus: 'series'
                            },
                            ...this.global.echartLineColor("销售成本"),
                            name:'销售成本',
                            data:(()=>{
                            let list = [];
                            plant.map((item)=>{
                                list.push(item.cost_price);
                            })
                            return list;
                            })()
                        },
                        {
                            type: 'line',
                            stack: 'Total',
                            emphasis: {
                                focus: 'series'
                            },
                            ...this.global.echartLineColor("利润"),
                            name:'利润',
                            data:(()=>{
                            let list = [];
                            plant.map((item)=>{
                                list.push(item.profit);
                            })
                            return list;
                            })()
                        }
                        ]
                    }
                )
            })
        },
         //同比
        loadYearOnYear(customerid){
            const that =this;
            const echarts = this.echarts
            let yearOnYear = echarts.getInstanceByDom(
                document.getElementById('CustYearOnYear-chart')
            )
            yearOnYear.showLoading();
            this.http.get('/total/sale/get/cusYearOnYear.do',{params:{time:new Date(this.data.yearOnYearConfig.defyear).getFullYear(),cmd:1,customerid:customerid}}).then((res) => {
            let plant = res.data.plant;
            that.data.yearOnYearData=plant;
            yearOnYear.hideLoading();
            yearOnYear.setOption(
                this.getOptionByYearOnYear(plant,that.data.yearOnYearConfig.yearonyearselect)
            )
            })
            yearOnYear.on('click',(obj)=>{//向下钻取
            if(obj.name.indexOf('季度')!=-1&&obj.seriesIndex==1){
                yearOnYear.showLoading();
                this.http.get('/total/sale/get/cusYearOnYear.do',{params:{time:new Date(this.data.yearOnYearConfig.defyear).getFullYear(),cmd:2,customerid:customerid}}).then((res) => {
                let plant = res.data.plant;
                that.data.yearOnYearData=plant;
                yearOnYear.hideLoading();
                yearOnYear.setOption(
                    this.getOptionByYearOnYear(plant,that.data.yearOnYearConfig.yearonyearselect)
                )
                })
                // tread.setOption(this.saleTotalOption()) 
            }else if(obj.name.indexOf('月')!=-1&&obj.seriesIndex==1){
                yearOnYear.showLoading();
                this.http.get('/total/sale/get/cusYearOnYear.do',{params:{time:obj.name.substring(0,7),cmd:3,customerid:customerid}}).then((res) => {
                let plant = res.data.plant;
                that.data.yearOnYearData=plant;
                yearOnYear.hideLoading();
                yearOnYear.setOption(
                    this.getOptionByYearOnYear(plant,that.data.yearOnYearConfig.yearonyearselect)
                )
                })
            }
            
            })
            
        },
        //设置产品同比参数
        getOptionByYearOnYear(data,key){
            const that = this;
            return {
                title: {
                    text: '同比增长'
                },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                    type: 'shadow',
                    label: {
                        show: true
                    }
                    },
                    confine:true,
                    // position:[120,0],
                    extraCssText:this.global.$echartsconfig.tooltip.extraCssText,
                    formatter(params) {
                        let tags = that.data.yearOnYearConfig.yearonyearselect=='num'?'个':'￥'
                        let param1=params[0], param2=params[1];
                        let obj1=param1.data,obj2=param2.data;
                        let circle1 = `<span style="display:inline-block;margin-right:5px;border-radius:50%;width:10px;height:10px;left:5px;background-color:`;//小圆点样式
                        let data1 = `${circle1}${param1.color}"></span>${param1.seriesName}：  ${param1.value} ${tags}<br/>
                        <div style='border: 1px solid white;border-radius: 5px;padding: 5px;'><span style='font-size:12px;margin-left: 45px;'>交易订单：${obj1.order_num}笔,销售量：${obj1.num}个,销售额:${obj1.sum_price}￥</psan><br/>
                            <span style='font-size:12px;margin-left: 45px;'> 利润:${obj1.profit||0.00} ￥, 利润率:%${obj1.profit_rate||0.00} </span></div><br/>`;//第二行显示
                        let circle2 = `<span style="display:inline-block;margin-right:5px;border-radius:50%;width:10px;height:10px;left:5px;background-color:`;//小圆点样式
                        let data2 = `${circle2}${param2.color}"></span>${param2.seriesName}：  ${param2.value} ${tags}<br/>
                        <div style='border: 1px solid white;border-radius: 5px;padding: 5px;'><span style='font-size:12px;margin-left: 45px;'>交易订单：${obj2.order_num}笔,销售量：${obj2.num}个,销售额:${obj2.sum_price}￥</psan><br/>
                            <span style='font-size:12px;margin-left: 45px;'> 利润:${obj2.profit||0.00} ￥, 利润率:%${obj2.profit_rate||0.00} </span><br/>
                            <span style='font-size:12px;margin-left: 45px;'> 同比销售额增长：  ${param1.value<=0?'100.00%':((param2.value-param1.value)/param1.value*100).toFixed(2)+'%'},
                            同比利润率增长：${(obj2.profit_rate-obj1.profit_rate).toFixed(2)} % </span></div>`;//第一行显示
                        // let circle3 = `<span style="display:inline-block;margin-right:5px;border-radius:50%;width:10px;height:10px;left:5px;background-color:`;//小圆点样式
                        // let data3 = `${circle3}red"></span>同比增长：  ${param1.value<=0?'100.00%':((param2.value-param1.value)/param1.value*100).toFixed(2)+'%'}`;//第三行显示
                        //添加一个统计数据，展示选择这的年度总计
                        
                        let totaldata_text=`<div style='border: 1px solid white;border-radius: 5px;padding: 5px;'>${new Date(that.data.yearOnYearConfig.defyear).getFullYear()}年总计<div  style='font-size:12px;margin-left: 45px;'>交易订单：${obj2.sum_order_num}笔,销售量:${obj2.sum_num}<br/> 销售额:${obj2.sum_sale_price}￥成本:${obj2.sum_cost_price}￥<br/> 利润:${obj2.sum_profit}￥ 利润率:${obj2.sum_profit_rate}%</div></div>`
                        return `${totaldata_text}${param2.name}<br/>${data2}<br/>${param1.name}<br/>${data1}`;
                    }
                },
                toolbox: this.global.echartsToolbox(),
                calculable: true,
                grid: {
                    top: '12%',
                    left: '1%',
                    right: '10%',
                    bottom: '5%',
                    containLabel: true
                },
                xAxis: 
                {
                    type: 'category',
                    data:(()=>{
                    let list = [];
                    data.current.map((item,i)=>{
                        list.push(item.name+" 同比 "+data.SamePeriod[i].name);
                    })
                    return list;
                    })(),
                    axisLabel: {
                    show: true,
                    // interval: 0,
                    // color: '#ffffff',
                    rotate:40,
                    fontSize:9,
                    formatter: function(value) {
                        var current = value.split(" 同比 ")[0],samePeriod = value.split(" 同比 ")[1];
                        return samePeriod+"\n"+current
                    }
                    }
                },
                yAxis: [
                    {
                    type: 'value',
                    }
                ],
                series: [
                    {
                    name: '同期',
                    type: 'bar',
                    data:(()=>{
                        let list = [];
                        data.SamePeriod.map((item)=>{
                        list.push({value:item[key],...item});
                        })
                        return list;
                    })()
                    },
                    {
                    name: '本期',
                    type: 'bar',
                    data:(()=>{
                        let list = [];
                        data.current.map((item)=>{
                        list.push({value:item[key],...item});
                        })
                        return list;
                    })()
                    }
                ]
                }
        },
    },
    beforeUpdate(){
        this.data.yearOnYearConfig.defyear=new Date().getFullYear()+'';
        this.data.customerBase={
            "country": "NONE",
            "current_cost_price_ratio": "0.00",
            "current_profit": "0.00",
            "current_sale_price": "0.00",
            "profit_rate": "0.00",
            "alias": "NONE",
            "id": 0,
            "profit": "0.00",
            "email": "NONE",
            "cost_price": "0.00",
            "current_product_num": 0,
            "area": "NONE",
            "address": "NONE",
            "level": "NONE",
            "mobile": "NONE",
            "product_num": 0,
            "label": "NONE",
            "sum_product_type": 0,
            "sale_price": "0.00",
            "product_type_num": 0,
            "zh_country": "NONE",
            "current_cost_price": "0.80",
            "name": "NONE",
            "sale_price_ratio": "0.00",
            "order_num": 0,
            "profit_ratio": "0.00",
            "current_profit_rate": "0",
            "product_ratio": 0,
            "product_num_ratio":0.00,
            "order_ratio":0.00
        }
    },
    updated(){
        
    },
    beforeUnmount(){
        this.backclose();
    }
}
</script>

<style scoped>
.table-total{
    margin: 5px;
    width: 100%;
    text-align:left;
}
.table-total td,th{
    width: 80px;
}
.dialog-footer button:first-child {
  margin-right: 10px;
}
@media screen and (min-width:1200px){

}
@media screen and (min-width:1400px){

}
</style>