//定义公用函数
import { ElMessage } from 'element-plus'
export default function(app){
   const global =app.config.globalProperties;
    global.echartsToolbox=function(option){//echarts 配置Toolbox
        option=option||{magicType:true};
        return  {
            show: true,
            feature: {
                ...option.myTool,
                mark: { show: true },
                dataView: { show: true, readOnly: false },
                magicType: option.magicType?{ show: true, type: ['line', 'bar'] }:false,
                restore: { show: true },
            //   saveAsImage: { show: true },
              
            }
          }
        // return false;
    }
    global.grid=function(option){//echarts 配置grid
        option=option||{left: '3%',
        right: '4%',
        bottom: '13%',
        top:"18%",
        containLabel: true}
        return option;
    },
    global.success=function(option){//成功提示
        ElMessage({
            message: option.message||'操作成功!',
            type: 'success',
            duration:2000
        })
    },
    global.error=function(option){//失败提示
        ElMessage.error(option.message||'操作失败!')
    },
    global.echartLineColor=function(key){//返回k线图表线的颜色
        const echartcolors={
            "单价":{
                areaStyle: {color:'#9400d35e'},
                lineStyle:{
                    color:'darkviolet'
                },
                itemStyle:{color:'darkviolet'}
            },
            "销售额":{
                areaStyle: {color:'#ffa5006e'},
                lineStyle:{
                    color:'orange'
                },
                itemStyle:{color:'orange'}
            },
            "采购额":{
                areaStyle: {color:'#ffa5006e'},
                lineStyle:{
                    color:'orange'
                },
                itemStyle:{color:'orange'}
            },
            "数量":{
                areaStyle: {color:'#1e90ff6e'},
                lineStyle:{
                    color:'dodgerblue'
                },
                itemStyle:{color:'dodgerblue'}
            },
            "采购量":{
                areaStyle: {color:'#1e90ff6e'},
                lineStyle:{
                    color:'dodgerblue'
                },
                itemStyle:{color:'dodgerblue'}
            },
            "销售成本":{
                areaStyle: {color:'#ff00005e'},
                lineStyle:{
                    color:'red'
                },
                itemStyle:{color:'red'},
            },
            "利润":{
                areaStyle: {color:'#0080006e'},
                lineStyle:{
                    width:2,
                    color:'green',
                    type:'dotted'
                },
                itemStyle:{
                    color:'green'
                },
            }
        }
        return echartcolors[key];
    }
}