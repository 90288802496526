<template>
  <div class="main">
    <div class="left-div">
      <div>
        <div class="title">
          <h3>数据概览</h3>
        </div>
        <div class="main-item-process" style="position: relative;">
          <el-row>
            <el-col :span="14" class="main-item-process-right">采购产品目录:{{data.baseInfo.buy_product_list_num}} / {{data.baseInfo.finish_buy_product_ratio}}%</el-col>
          </el-row>
          <el-progress :percentage="100" status="success" />
        </div>
        <div class="main-item-list" v-loading="data.loadings.loading1">
          <div>
            <h6>订单</h6>
            <h3>{{data.baseInfo.order_num}}</h3>
          </div>
          <div>
            <h6>采购数量</h6>
            <h3>{{data.baseInfo.buy_product_num}}</h3>
          </div>
          <div>
            <h6>采购金额</h6>
            <h3>{{data.baseInfo.buy_price}}</h3>
          </div>
          <div>
            <h6>供应商</h6>
            <h3>{{data.baseInfo.supplier_num}}</h3>
          </div>
          <div>
            <h6>有交易</h6>
            <h3>{{data.baseInfo.trade_supplier_num}}</h3>
          </div>
          <div>
            <h6>未交易</h6>
            <h3>{{data.baseInfo.no_trade_supplier_num}}</h3>
          </div>
        </div>
        <div style="height:230px;width:100%">
          <div id="buy-sale-num-ratio" style="height: 100%;width: 150px;float: left;"></div>
          <div id="buy-sale-price-ratio" style="height: 100%;width: 150px;float: left;"></div>
        </div>
      </div>
      <!-- 按采购次数最多的供应商排行 -->
      <div>
        <div class="selectYeardiv">
          <el-date-picker  size="small" style="width:100px;"
            v-model="data.supplier_deftime"
            type="year"
            clearable
            :editable="false"
            placeholder="起始时间"
          />
        </div>
        <div id="supplier-top-chart"></div>
      </div>
      <!-- 按采购次数最多的产品排行 -->
      <div>
        <div class="selectYeardiv">
          <el-date-picker  size="small" style="width:100px;"
            v-model="data.producttop_deftime"
            type="year"
            clearable
            :editable="false"
            placeholder="起始时间"
          />
        </div>
        <div id="product-top-chart"></div>
      </div>
    </div>
    <div class="right-div">
      <!-- 销售基本数据 -->
      <div class="title">
        <el-radio-group v-model="data.report_selectbutton" size="small">
          <el-radio-button label="supplier">供应商报表</el-radio-button>
          <el-radio-button label="product">产品报表</el-radio-button>
        </el-radio-group>
        <!-- 供应商维度的报表 -->
      <div v-loading="data.loadings.loading2" v-show="data.report_selectbutton=='supplier'">
        <div style="position: relative;top: 0px;">
          <el-input v-model="data.supplier_query" style="width:150px;" 
          @clear="()=>{
            this.loadSupplierReport();
          }"
          size="small" 
          placeholder="supplier key" 
          clearable />
          <el-button size="small" style="margin-left: 5px;"
          @click="()=>{
            this.loadSupplierReport();
          }"
          >关键字搜索</el-button>
        </div>
        <el-table
          :data="data.supplier_reportdata"
          :highlight-current-row="true"
          @header-click="(column)=>{
              if(column.sortable){
                this.data.supplier_report_sort.property=column.property;
                this.data.supplier_report_sort.order=column.order;
                this.data.supplierreportconfig.start=0;//排序后重置分页起始页
                this.data.supplierreportconfig.defaultCurrentPage=1;//排序后设为当前第一页
                this.loadSupplierReport();
              }
          }"
          style="width: 100%" class="supplierreport_data" size="small" @row-dblclick="(row)=>{
            this.purPanel.open(row.id);
          }">
          <el-table-column
            column-key="id"
            prop="name"
            label="供应商"
            >
            <template #default="{row}">
              <el-tooltip
              class="box-item"
              effect="dark"
              :raw-content="true"
              :content="`<div>${row.name}</div><div>${row.email}</div><div>${row.mobile}</div>`"
              placement="bottom-end"
              >
                <div><span>{{row.name}}</span></div>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column label="采购量/采购额">
            <el-table-column
              sortable="true"
              :sort-orders="tablesort"
              prop="day10_price"
              label="10天 数量/金额"
              >
              <!-- 自定义渲染单列，使用vue插槽语法 -->
              <template #default="{row}">
                <div>{{row.day10_num}}/<span :style="row.day10_price>0?'color: red;':''">{{row.day10_price}}</span></div>
              </template>
            </el-table-column>
            <el-table-column
              sortable="true"
              prop="day30_price"
              label="30天 数量/金额"
              >
              <template #default="{row}">
                <div>{{row.day30_num}}/<span :style="row.day30_price>0?'color: red;':''">{{row.day30_price}}</span></div>
              </template>
            </el-table-column>
            <el-table-column
            sortable="true"
              prop="day120_price"
              label="季度 数量/金额"
              >
              <template #default="{row}">
                <div>{{row.day120_num}}/<span :style="row.day120_price>0?'color: red;':''">{{row.day120_price}}</span></div>
              </template>
            </el-table-column>
            <el-table-column
              sortable="true"
              prop="year1_price"
              label="年 数量/金额"
              >
              <template #default="{row}">
                <div>{{row.year1_num}}/<span :style="row.year1_price>0?'color: red;':''">{{row.year1_price}}</span></div>
              </template>
            </el-table-column>
            <el-table-column
              sortable="true"
              prop="sum_price"
              label="总数 数量/金额"
              >
              <template #default="{row}">
                <div>{{row.sum_num}}/<span :style="row.sum_price>0?'color: red;':''">{{row.sum_price}}</span></div>
              </template>
            </el-table-column>
          </el-table-column>
        </el-table>
        <div class="pagination">
          <el-pagination layout="prev, pager, next, jumper, ->, total" 
          :total="data.supplierreportconfig.totalProperty" 
          :page-size="data.supplierreportconfig.limit"
          :current-page="data.supplierreportconfig.defaultCurrentPage"
          @current-change="(page)=>{
            this.data.supplierreportconfig.start=(page-1)*this.data.supplierreportconfig.limit;
            this.data.supplierreportconfig.defaultCurrentPage=page;
            this.loadSupplierReport();
          }" />
        </div>
      </div>
      <!-- 产品维度的报表 -->
      <div v-loading="data.loadings.loading3" v-show="data.report_selectbutton=='product'">
        <div style="position: relative;top: 0px;">
          <el-input v-model="data.product_query" style="width:150px;" 
          @clear="()=>{
            this.loadProductReport();
          }"
          size="small" 
          placeholder="number key" 
          clearable />
          <el-button size="small" style="margin-left: 5px;"
          @click="()=>{
            this.loadProductReport();
          }"
          >编号搜索</el-button>
        </div>
        <el-table
          :data="data.product_reportdata"
          :highlight-current-row="true"
          @header-click="(column)=>{
              if(column.sortable){
                this.data.product_report_sort.property=column.property;
                this.data.product_report_sort.order=column.order;
                this.data.productreportconfig.start=0;//排序后重置分页起始页
                this.data.productreportconfig.defaultCurrentPage=1;//排序后设为当前第一页
                this.loadProductReport();
              }
          }"
          @row-dblclick="(row)=>{
            this.purProPanel.open(row.number);
          }"
          style="width: 100%" class="producntreport_data" size="small">
          <el-table-column
            column-key="id"
            prop="number"
            label="产品"
            >
          </el-table-column>
          <el-table-column label="采购量/采购额">
            <el-table-column
              sortable="true"
              :sort-orders="tablesort"
              prop="day10_price"
              label="10天 数量/金额"
              >
              <!-- 自定义渲染单列，使用vue插槽语法 -->
              <template #default="{row}">
                <div>{{row.day10_num}}/<span :style="row.day10_price>0?'color: red;':''">{{row.day10_price}}</span></div>
              </template>
            </el-table-column>
            <el-table-column
              sortable="true"
              prop="day30_price"
              label="30天 数量/金额"
              >
              <template #default="{row}">
                <div>{{row.day30_num}}/<span :style="row.day30_price>0?'color: red;':''">{{row.day30_price}}</span></div>
              </template>
            </el-table-column>
            <el-table-column
            sortable="true"
              prop="day120_price"
              label="季度 数量/金额"
              >
              <template #default="{row}">
                <div>{{row.day120_num}}/<span :style="row.day120_price>0?'color: red;':''">{{row.day120_price}}</span></div>
              </template>
            </el-table-column>
            <el-table-column
              sortable="true"
              prop="year1_price"
              label="年 数量/金额"
              >
              <template #default="{row}">
                <div>{{row.year1_num}}/<span :style="row.year1_price>0?'color: red;':''">{{row.year1_price}}</span></div>
              </template>
            </el-table-column>
            <el-table-column
              sortable="true"
              prop="sum_price"
              label="总数 数量/金额"
              >
              <template #default="{row}">
                <div>{{row.sum_num}}/<span :style="row.sum_price>0?'color: red;':''">{{row.sum_price}}</span></div>
              </template>
            </el-table-column>
          </el-table-column>
        </el-table>
        <div class="pagination">
          <el-pagination layout="prev, pager, next, jumper, ->, total" 
          :total="data.productreportconfig.totalProperty" 
          :page-size="data.productreportconfig.limit"
          :current-page="data.productreportconfig.defaultCurrentPage"
          @current-change="(page)=>{
            this.data.productreportconfig.start=(page-1)*this.data.productreportconfig.limit;
            this.data.productreportconfig.defaultCurrentPage=page;
            this.loadProductReport();
          }" />
        </div>
      </div>
      </div>
      <div class="item3">
        <!-- 同比增长率 -->
        <div style="width:50%;float:left;">
          <div class="selectYeardiv">
            <el-date-picker  size="small" style="width:100px;"
              v-model="data.yearOnyearconfig.defyear"
              :clearable="data.yearOnyearconfig.clearable"
              :editable="data.yearOnyearconfig.editable"
              type="year"
              placeholder="选择一个年份"
            />
            <el-radio-group v-model="data.yearOnyearconfig.yearonyearselect" size="small" style="vertical-align: top;" >
              <el-radio-button label="num">数量</el-radio-button>
              <el-radio-button label="sum_price">金额</el-radio-button>
            </el-radio-group>
          </div>
          <div id="YearOnYear-chart"></div>
        </div>
        <!-- 环比增长率 -->
        <div style="width:50%;float:left;">
          <div class="selectYeardiv">
            <el-date-picker  size="small" style="width:100px;"
              v-model="data.ringRatioConfig.defradio"
              type="year"
              :clearable="data.ringRatioConfig.clearable"
              :editable="data.ringRatioConfig.editable"
              placeholder="选择一个年份"
            />
            <el-radio-group v-model="data.ringRatioConfig.ringRatioselect" size="small" style="vertical-align: top;" >
              <el-radio-button label="num">数量</el-radio-button>
              <el-radio-button label="sum_price">金额</el-radio-button>
            </el-radio-group>
          </div>
          <div id="RingRatio-chart"></div>
        </div>
      </div>
      
      
    </div>
    <div class="item4">
        <!-- 采购统计 -->
        <div id="buyTotalTread-chart"></div>
        <!-- 近期采购 -->
        <div id="dayTrend-chart"></div>
      </div>
  </div>
  <PurPanel ref="purPanel"></PurPanel>
  <PurProPanel ref="purProPanel"></PurProPanel>
</template>

<script>
import {ref,reactive,getCurrentInstance} from "vue"
import PurPanel from "@/components/PurPanel.vue"
import PurProPanel from "@/components/PurProPanel.vue"
export default {
    name:'PurAnalysis',
    components:{PurPanel,PurProPanel},
    setup(){
      const {appContext} = getCurrentInstance();
      const echarts = appContext.config.globalProperties.$echarts;
      const global = appContext.config.globalProperties;
      const http=appContext.config.globalProperties.$http;
      const purPanel = ref(null);
      const purProPanel= ref(null);
      const data =reactive({
        baseInfo:{//页面基础数据
          buy_price:"0.00",
          buy_price_ratio:0.00,
          buy_product_list_num:0,
          buy_product_num:0,
          buy_product_num_ratio:0.00,
          finish_buy_product_ratio:0.00,
          no_trade_supplier_num:0,
          order_num:0,
          product_list_num:0,
          sale_price:"0.00",
          sale_price_ratio:"0.00",
          sale_product_num:0,
          sale_product_num_ratio:"0.00",
          supplier_num:0,
          trade_supplier_num:0
        },
        loadings:{
          loading1:true,
          loading2:false,
          loading3:false,
        },
        supplier_topdata:[],//供应商排行数据
        supplier_deftime:'',//供应商排行默认时间
        product_topdata:[],//产品采购排行数据
        producttop_deftime:'',//产品采购排行统计默认时间
        report_selectbutton:'supplier',//默认选择供应商报表按钮
        supplier_reportdata:[],//供应商数据报表
        supplier_report_sort:{property:'day10_price',order:'desc'},//记住供应商报表表格的排序方式
        supplierreportconfig:{//供应商数据报表配置项
          totalProperty:0,
          limit:10,
          start:0,
          defaultCurrentPage:1
        },
        supplier_query:'',//供应商报表查询文本
        product_reportdata:[],//产品报表数据
        product_report_sort:{property:'day10_price',order:'desc'},//记住产品报表表格的排序方式
        productreportconfig:{//产品数据报表配置项
          totalProperty:0,
          limit:10,
          start:0,
          defaultCurrentPage:1
        },
        product_query:'',//产品报表查询文本
        purTotaldata:[],//采购统计
        yearOnYearData:[],//采购同比
        yearOnyearconfig:{//采购同比选择配置
          defyear:(new Date().getFullYear())+'',
          clearable:false,
          editable:false,
          yearonyearselect:'sum_price'
        },
        ringRatioData:[],//采购环比
        ringRatioConfig:{//采购环比选择配置
          defradio:(new Date().getFullYear())+'',
          clearable:false,
          editable:false,
          ringRatioselect:'sum_price'
        }
      });
      //加载概览数据
      http.get('/total/pur/get/baseInfo.do').then((res) => {
        data.loadings.loading1=false;
        if(res.data.plant.length>0)
          data.baseInfo = res.data.plant[0];
      })
      return {
        data,
        tablesort:['ascending', 'descending'],
        echarts,
        global,
        http,
        purPanel,
        purProPanel
      }
    },
    mounted(){
      this.initPage();
      //加载供应商采购排行
      this.http.get('/total/pur/get/supplierTop.do',{params:{time:new Date(this.data.supplier_deftime||'1997-01-01').getFullYear()},orderby:'purchase_price desc'}).then((res) => {
        this.data.loadings.loading1=false;
        this.data.supplier_topdata = res.data.plant;
        this.loadSupplierTopChart(this.data.supplier_topdata);
      })
      //加载产品采购排行
      this.http.get('/total/pur/get/productTop.do',{params:{time:new Date(this.data.producttop_deftime||'1997-01-01').getFullYear()},orderby:'purchase_price desc'}).then((res) => {
        this.data.loadings.loading1=false;
        this.data.supplier_topdata = res.data.plant;
        this.loadProductTopChart(this.data.supplier_topdata);
      })
      //加载供应商报表数据
      this.loadSupplierReport();
      //近期采购数据
      this.dayTrendBuyChart();
      //采购统计
      this.loadPurTotal();
      //采购同比
      this.loadYearOnYear();
      //采购环比
      this.loadRingRatio();
    },
    watch:{
      "data.baseInfo.buy_product_num_ratio"(){
          let data = [{name:'采购占比',value:this.data.baseInfo.buy_product_num_ratio},
                      {name:'销售占比',value:this.data.baseInfo.sale_product_num_ratio}
                      ]
          this.setOptionBuySaleNumPie(data);
      },
      "data.baseInfo.buy_price_ratio"(){
        let data = [{name:'采购占比',value:this.data.baseInfo.buy_price_ratio},
                      {name:'销售占比',value:this.data.baseInfo.sale_price_ratio}
                      ]
        this.setOptionBuySalePricePie(data);
      },
      "data.supplier_deftime"(){
        let supplier = this.echarts.getInstanceByDom(
          document.getElementById('supplier-top-chart')
        )
        supplier.showLoading();
        this.http.get('/total/pur/get/supplierTop.do',{params:{time:new Date(this.data.supplier_deftime||'1997-01-01').getFullYear()},orderby:'purchase_price desc'}).then((res) => {
          supplier.hideLoading();
          this.data.supplier_topdata = res.data.plant;
          this.loadSupplierTopChart(this.data.supplier_topdata);
        })
      },
      "data.producttop_deftime"(){
        let product = this.echarts.getInstanceByDom(
          document.getElementById('product-top-chart')
        )
        product.showLoading();
        this.http.get('/total/pur/get/productTop.do',{params:{time:new Date(this.data.producttop_deftime||'1997-01-01').getFullYear()},orderby:'purchase_price desc'}).then((res) => {
          product.hideLoading();
          this.data.supplier_topdata = res.data.plant;
          this.loadProductTopChart(this.data.supplier_topdata);
        })
      },
      "data.report_selectbutton"(newVal){
        if(newVal=='supplier'){
          this.loadSupplierReport();
        }else{
          this.loadProductReport();
        }
      },
      "data.yearOnyearconfig.defyear"(newVal){//同比选择时间的组件值发生变化时重新获取数据
        if(newVal){
          const that = this;
          let yearonyear = this.echarts.getInstanceByDom(document.getElementById('YearOnYear-chart'));
          yearonyear.showLoading();
          this.http.get('/total/pur/get/yearOnYear.do',{params:{time:new Date(newVal).getFullYear(),cmd:1}}).then((res) => {
            let plant = res.data.plant;
            that.data.yearOnYearData=plant;
            yearonyear.hideLoading();
            yearonyear.setOption(
              this.getOptionByYearOnYear(plant,that.data.yearOnyearconfig.yearonyearselect)
            )
          })
        }
      },
      "data.yearOnyearconfig.yearonyearselect"(label){//切换数量和金额
        let yearonyear = this.echarts.getInstanceByDom(document.getElementById('YearOnYear-chart'));
        yearonyear.setOption(this.getOptionByYearOnYear(this.data.yearOnYearData,label));
      },
      "data.ringRatioConfig.defradio"(newVal){//环比选择时间的组件值发生变化时重新获取数据
        if(newVal){
          const that = this;
          let ringRatio = this.echarts.getInstanceByDom(document.getElementById('RingRatio-chart'));
          ringRatio.showLoading();
          this.http.get('/total/pur/get/ringRatio.do',{params:{year:new Date(newVal).getFullYear()}}).then((res) => {
            let plant = res.data.plant;
            that.data.ringRatioData=plant;
            ringRatio.hideLoading();
            ringRatio.setOption(
              this.getOptionByRingRatio(plant,that.data.ringRatioConfig.ringRatioselect)
            )
          })
        }
      },
      "data.ringRatioConfig.ringRatioselect"(label){//切换数量和金额
        let ringRatio = this.echarts.getInstanceByDom(document.getElementById('RingRatio-chart'));
        ringRatio.setOption(this.getOptionByRingRatio(this.data.ringRatioData,label));
      },
    },
    methods:{
      initPage(){
        this.echarts.init(
          document.getElementById('buy-sale-num-ratio')
        );
        this.echarts.init(
          document.getElementById('buy-sale-price-ratio')
        );
        this.echarts.init(
          document.getElementById('supplier-top-chart')
        )
        this.echarts.init(
          document.getElementById('product-top-chart')
        )
      },
      setOptionBuySaleNumPie(data){//加载供应商采购数量占比图
        const that=this
        let level = that.echarts.getInstanceByDom(
          document.getElementById('buy-sale-num-ratio')
        )
        level.setOption(
          {
            title: {
              text: '数量占比',
              left: 'left',
              textStyle:{
                fontSize: 13,
              },
              top:'0%'
            },
            legend: {
              orient: "vertical",
              left: "right",
              top: "0%",
              textStyle: {
                color: "#0043ffa6",
                fontSize: 10,
              },
              itemGap: 4,
              itemWidth: 10,
              itemHeight: 5,
              height: 300,
            },
            tooltip: {
              trigger: 'item',
              confine:true,
              formatter(params) {
                  let circle1 = `<span style="display:inline-block;margin-right:5px;border-radius:50%;width:10px;height:10px;left:5px;background-color:`;//小圆点样式
                  let data1 = `${circle1}${params.color}"></span>${params.name}：  ${params.value} %`;//第一行行显示
                  return `${data1}<br/>`;
              }
            },
            series: [
              {
                name: '采购与销售数量占比',
                type: 'pie',
                radius: ['30%', '80%'],
                label: {
                  position: 'inner',
                  fontSize: 9
                },
                data:data,
                emphasis: {
                  itemStyle: {
                    shadowBlur: 10,
                    shadowOffsetX: 0,
                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                  }
                }
              }
            ]
          }
        )
      },
      setOptionBuySalePricePie(data){//加载供应商采购金额占比图
        const that=this
        let label = that.echarts.getInstanceByDom(
          document.getElementById('buy-sale-price-ratio')
        )
        label.setOption(
          {
            title: {
              text: '金额占比',
              left: 'right',
              textStyle:{
                fontSize: 13,
              },
              top:'0%'
            },
            legend: {
              orient: "vertical",
              left: "top",
              top: "0%",
              textStyle: {
                color: "#0043ffa6",
                fontSize: 10,
              },
              itemGap: 4,
              itemWidth: 10,
              itemHeight: 5,
              height: 300,
            },
            tooltip: {
              trigger: 'item',
              confine:true,
              formatter(params) {
                  let circle1 = `<span style="display:inline-block;margin-right:5px;border-radius:50%;width:10px;height:10px;left:5px;background-color:`;//小圆点样式
                  let data1 = `${circle1}${params.color}"></span>${params.name}：  ${params.value} %`;//第一行行显示
                  return `${data1}<br/>`;
              }
            },
            series: [
              {
                name: '采购金额与销售金额占比',
                type: 'pie',
                radius: ['30%', '80%'],
                label: {
                  position: 'inner',
                  fontSize: 9
                },
                data:data,
                emphasis: {
                  itemStyle: {
                    shadowBlur: 10,
                    shadowOffsetX: 0,
                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                  }
                }
              }
            ]
          }
        )
      },
      loadSupplierTopChart(data){//加载供应商排行
        const that = this
        let supplier = that.echarts.getInstanceByDom(
          document.getElementById('supplier-top-chart')
        )
        supplier.setOption(
          {
            title: {
              text: 'Top供应商'
            },
            tooltip: {
              trigger: 'axis',
              confine:true,
              axisPointer: {
                type: 'shadow'
              },
              extraCssText:this.global.$echartsconfig.tooltip.extraCssText,
              formatter(params) {
                let param1=params[0];
                let circle1 = `<span style="display:inline-block;margin-right:5px;border-radius:50%;width:10px;height:10px;left:5px;background-color:`;//小圆点样式
                let data1 = `${circle1}${param1.color}"></span>${param1.seriesName}：  ${param1.value} ￥`;//第一行行显示
                let param2=params[1];
                let circle2 = `<span style="display:inline-block;margin-right:5px;border-radius:50%;width:10px;height:10px;left:5px;background-color:`;//小圆点样式
                let data2 = `${circle2}${param2.color}"></span>${param2.seriesName}：  ${param2.value/10} 个`;//第二行显示
                return `<h3>${param1.name}</h3> <br/>${data1}<br/>${data2}`;
              }
            },
            toolbox: this.global.echartsToolbox({mark:{show:false}}),
            // legend: {},
            dataZoom:[
              {
                yAxisIndex:0,
                type:'inside',
                startValue:0,
                endValue:9
              }
            ],
            grid: {
              left: '3%',
              right: '4%',
              bottom: '13%',
              top:"10%",
              containLabel: true
            },
            xAxis: {
              type: 'value',
              boundaryGap: [0, 0.01]
            },
            yAxis: {
              type: 'category',
              inverse:true,//排序
              data:(()=>{
                let list = [];
                data.map((item)=>{
                  list.push(item.name);
                })
                return list;
              })(),
              axisLabel: {
                show: true,
                formatter: function(value) {
                  if(value&&value.length>5){
                    return value.substring(0,5)+'...'
                  }
                  return value
                }
              }
            },
            series: [{
                name:'采购额',
                type: 'bar',
                stack: 'total',
                data:(()=>{
                    let list = [];
                    data.map((item)=>{
                      list.push({value:item.purchase_price,...item});
                    })
                    return list;
                  })()
            },{
                name:'采购数量',
                type: 'bar',
                stack: 'total',
                data:(()=>{
                    let list = [];
                    data.map((item)=>{
                      list.push({value:item.num*10,...item});
                    })
                    return list;
                  })()
            }]
          }
        )
      },
      loadProductTopChart(data){//加载采购产品排行
        const that = this
        let product = that.echarts.getInstanceByDom(
          document.getElementById('product-top-chart')
        )
        product.setOption(
          {
            title: {
              text: 'Top产品'
            },
            tooltip: {
              trigger: 'axis',
              confine:true,
              axisPointer: {
                type: 'shadow'
              },
              extraCssText:this.global.$echartsconfig.tooltip.extraCssText,
              formatter(params) {
                let param1=params[0];
                let circle1 = `<span style="display:inline-block;margin-right:5px;border-radius:50%;width:10px;height:10px;left:5px;background-color:`;//小圆点样式
                let data1 = `${circle1}${param1.color}"></span>${param1.seriesName}：  ${param1.value} ￥`;//第一行行显示
                let param2=params[1];
                let circle2 = `<span style="display:inline-block;margin-right:5px;border-radius:50%;width:10px;height:10px;left:5px;background-color:`;//小圆点样式
                let data2 = `${circle2}${param2.color}"></span>${param2.seriesName}：  ${param2.value/10} 个`;//第二行显示
                return `<h3>${param1.name}</h3> <br/>${data1}<br/>${data2}`;
              }
            },
            toolbox: this.global.echartsToolbox({mark:{show:false}}),
            // legend: {},
            dataZoom:[
              {
                yAxisIndex:0,
                type:'inside',
                startValue:0,
                endValue:9
              }
            ],
            grid: {
              left: '3%',
              right: '4%',
              bottom: '13%',
              top:"10%",
              containLabel: true
            },
            xAxis: {
              type: 'value',
              boundaryGap: [0, 0.01]
            },
            yAxis: {
              type: 'category',
              inverse:true,//排序
              data:(()=>{
                let list = [];
                data.map((item)=>{
                  list.push(item.name);
                })
                return list;
              })(),
              axisLabel: {
                show: true,
                formatter: function(value) {
                  if(value&&value.length>5){
                    return value.substring(0,5)+'...'
                  }
                  return value
                }
              }
            },
            series: [{
                name:'采购额',
                type: 'bar',
                stack: 'total',
                data:(()=>{
                    let list = [];
                    data.map((item)=>{
                      list.push({value:item.purchase_price,...item});
                    })
                    return list;
                  })()
            },{
                name:'采购数量',
                type: 'bar',
                stack: 'total',
                data:(()=>{
                    let list = [];
                    data.map((item)=>{
                      list.push({value:item.num*10,...item});
                    })
                    return list;
                  })()
            }]
          }
        )
      },
      loadSupplierReport(){
        //加载供应商数据报表
        this.data.loadings.loading2=true;
        this.http.get('/total/pur/get/totalSupplierReport.do',{params:{query:this.data.supplier_query,limit:this.data.supplierreportconfig.limit,
                                                                      start:this.data.supplierreportconfig.start,orderby:`${this.data.supplier_report_sort.property} ${this.data.supplier_report_sort.order=='ascending'?'asc':'desc'}`}}).then((res) => {
          this.data.loadings.loading2=false;
          this.data.supplier_reportdata=res.data.plant;
          this.data.supplierreportconfig.totalProperty=res.data.totalProperty[0].count;
        })
      },
      loadProductReport(){
        //加载产品采购数据报表
        this.data.loadings.loading3=true;
        this.http.get('/total/pur/get/totalForProduct.do',{params:{query:this.data.product_query,limit:this.data.productreportconfig.limit,
                                                                      start:this.data.productreportconfig.start,orderby:`${this.data.product_report_sort.property} ${this.data.product_report_sort.order=='ascending'?'asc':'desc'}`}}).then((res) => {
          this.data.loadings.loading3=false;
          this.data.product_reportdata=res.data.plant;
          this.data.productreportconfig.totalProperty=res.data.totalProperty[0].count;
        })
      },
      dayTrendBuyChart(){//近期采购
        const that = this;
        let dayTrendchart=that.echarts.init(
          document.getElementById('dayTrend-chart')
        )
        dayTrendchart.showLoading();
        this.http.get('/total/pur/get/recentProcurement.do',{params:{limit:50}}).then((res) => {
          dayTrendchart.hideLoading();
          let plant=res.data.plant;
          dayTrendchart.setOption(
            {
                  title: {
                  text: '近期采购'
                  },
                  legend: {
                  data: [ '采购量','采购额']
                  },
                  tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                      type: 'shadow',
                      label: {
                        show: true
                      }
                    },
                    confine:true,
                    // position:[120,0],
                    extraCssText:this.global.$echartsconfig.tooltip.extraCssText,
                    formatter(params) {
                      let param1=params[0];
                      let circle1 = `<span style="display:inline-block;margin-right:5px;border-radius:50%;width:10px;height:10px;left:5px;background-color:`;//小圆点样式
                      let data1 = `${circle1}${param1.color}"></span>${param1.seriesName}：  ${param1.value}`;//第二行显示
                      let data2="";
                      if(params.length>1){
                        let param2=params[1];
                        let circle2 = `<span style="display:inline-block;margin-right:5px;border-radius:50%;width:10px;height:10px;left:5px;background-color:`;//小圆点样式
                        data2 = `${circle2}${param2.color}"></span>${param2.seriesName}：  ${param2.value}`;//第一行显示
                      }
                      let data=param1.data;
                      let text=`<div style='font-size:12px; margin-left:40px;border: 1px solid white;border-radius: 5px;padding: 5px;'>采购商:${data.supplier_name}<br />公司:${data.supplier_company}<br />电话:${data.supplier_mobile}<br/>邮箱:${data.supplier_email}</div>`;
                      return `${param1.name}<br/>${data.number}<br/>${data2}<br/>${data1}<br/>${text}`;
                    }
                  },
                  toolbox: this.global.echartsToolbox({mark:{show:false}}),
                  grid: {
                  left: '3%',
                  right: '4%',
                  bottom: '3%',
                  containLabel: true
                  },
                  xAxis: [
                  {
                      type: 'category',
                      inverse:true,//反方向显示
                      data:(()=>{
                        let list = [];
                        plant.map((item)=>{
                            list.push(item.time);
                        })
                        return list;
                      })()
                  }
                  ],
                  yAxis: [
                  {
                      type: 'value'
                  }
                  ],
                  series: [
                  {
                      type: 'line',
                      stack: 'Total',
                      emphasis: {
                      focus: 'series'
                      },
                      ...this.global.echartLineColor("采购量"),
                      name:'采购量',
                      data:(()=>{
                      let list = [];
                      plant.map((item)=>{
                          list.push({value:item.num,...item});
                      })
                      return list;
                      })()
                  },
                  {
                      type: 'line',
                      stack: 'Total',
                      areaStyle: {},
                      emphasis: {
                      focus: 'series'
                      },
                      ...this.global.echartLineColor("采购额"),
                      name:'采购额',
                      data:(()=>{
                        let list = [];
                        plant.map((item)=>{
                            list.push({value:item.sum_price,...item});
                        })
                        return list;
                      })()
                  }]
              }
          )
        })
      },
      //采购统计
      loadPurTotal(){
        const that=this
        const echarts = this.echarts
        let buyTotalTread = echarts.init(
          document.getElementById('buyTotalTread-chart')
        )
        buyTotalTread.showLoading();
        this.http.get('/total/pur/get/purTotal.do',{params:{cmd:1}}).then((res) => {
          let plant = res.data.plant;
          that.data.purTotaldata=plant;
          buyTotalTread.hideLoading();
          buyTotalTread.setOption(that.saleTotalOption(that.data.purTotaldata))
        })
        buyTotalTread.on('click',(obj)=>{//当图形为年或月的时候，向下钻取
          if(obj.data.keys==='year'){
            buyTotalTread.showLoading();
            that.http.get('/total/pur/get/purTotal.do',{params:{cmd:2,time:obj.data.time}}).then((res) => {
              let plant = res.data.plant;
              that.data.purTotaldata=plant;
              buyTotalTread.hideLoading();
              buyTotalTread.setOption(that.saleTotalOption(that.data.purTotaldata))
            })
          }else if(obj.data.keys==='quarter'){//点击季度的时候
            buyTotalTread.showLoading();
            that.http.get('/total/pur/get/purTotal.do',{params:{cmd:3,time:obj.data.time.substring(0,4)}}).then((res) => {
              let plant = res.data.plant;
              that.data.purTotaldata=plant;
              buyTotalTread.hideLoading();
              buyTotalTread.setOption(that.saleTotalOption(that.data.purTotaldata))
            })
          }else if(obj.data.keys==='month'){//点击月的时候
            buyTotalTread.showLoading();
            that.http.get('/total/pur/get/purTotal.do',{params:{cmd:4,time:obj.data.time}}).then((res) => {
              let plant = res.data.plant;
              that.data.purTotaldata=plant;
              buyTotalTread.hideLoading();
              buyTotalTread.setOption(that.saleTotalOption(that.data.purTotaldata))
            })
          }
          
        })
      },
      saleTotalOption(data){//采购统计图形参数配置
        return {
            title: {
              text: '采购统计'
            },
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                type: 'shadow'
              },
              extraCssText:this.global.$echartsconfig.tooltip.extraCssText,
              formatter(params) {
                let param1=params[0],data=param1.data;
                let circle1 = `<span style="display:inline-block;margin-right:5px;border-radius:50%;width:10px;height:10px;left:5px;background-color:`;//小圆点样式
                let data1 = `${circle1}${param1.color}"></span>${param1.seriesName}：  ${param1.value/10}`;//第一行行显示
                let param2=params[1];
                let circle2 = `<span style="display:inline-block;margin-right:5px;border-radius:50%;width:10px;height:10px;left:5px;background-color:`;//小圆点样式
                let data2 = `${circle2}${param2.color}"></span>${param2.seriesName}：  ${param2.value}`;//第二行显示
                let text=`<div style='font-size:12px; margin-left:40px;border: 1px solid white;border-radius: 5px;padding: 5px;'>采购量:${data.num}个 采购额:${data.sum_price}￥</div>`
                return `${param1.name} <br/>${data1}<br/>${data2}<br/>${text}`;
              }
            },
            // legend: {
            //   data: [ '采购量','采购额']
            // },
            toolbox: this.global.echartsToolbox(),
            calculable: true,
            grid: {
              top: '12%',
              left: '1%',
              right: '10%',
              bottom: '5%',
              containLabel: true
            },
            xAxis: [
              {
                type: 'category',
                data:(()=>{
                  let list = [];
                  data.map((item)=>{
                    list.push(item.name);
                  })
                  return list;
                })(),
                axisLabel: {
                  show: true,
                  rotate:40,
                  fontSize:9,
                  formatter: function(value) {
                    return value
                  }
                }
              }
            ],
            yAxis: [
              {
                type: 'value'
              }
            ],
            series: [
              {
                name: '采购量',
                type: 'bar',
                stack: 'total',
                data:(()=>{
                  let list = [];
                  data.map((item)=>{
                    list.push({value:item.num*10,...item});
                  })
                  return list;
                })()
              },
              {
                name: '采购额',
                type: 'bar',
                stack: 'total',
                data:(()=>{
                  let list = [];
                  data.map((item)=>{
                    list.push({value:item.sum_price,...item});
                  })
                  return list;
                })()
              }
            ]
          }
      },
      //同比
      loadYearOnYear(){
        const that =this;
        const echarts = this.echarts
        let yearOnYear = echarts.init(
          document.getElementById('YearOnYear-chart')
        )
        yearOnYear.showLoading();
        this.http.get('/total/pur/get/yearOnYear.do',{params:{time:new Date(that.data.yearOnyearconfig.defyear).getFullYear(),cmd:1}}).then((res) => {
          let plant = res.data.plant;
          that.data.yearOnYearData=plant;
          yearOnYear.hideLoading();
          yearOnYear.setOption(
            this.getOptionByYearOnYear(plant,that.data.yearOnyearconfig.yearonyearselect)
          )
        })
        yearOnYear.on('click',(obj)=>{//向下钻取
          if(obj.name.indexOf('季度')!=-1&&obj.seriesIndex==1){
            yearOnYear.showLoading();
            this.http.get('/total/pur/get/yearOnYear.do',{params:{time:new Date(that.data.yearOnyearconfig.defyear).getFullYear(),cmd:2}}).then((res) => {
              let plant = res.data.plant;
              that.data.yearOnYearData=plant;
              yearOnYear.hideLoading();
              yearOnYear.setOption(
                this.getOptionByYearOnYear(plant,that.data.yearOnyearconfig.yearonyearselect)
              )
            })
            // tread.setOption(this.saleTotalOption()) 
          }else if(obj.name.indexOf('月')!=-1&&obj.seriesIndex==1){
            yearOnYear.showLoading();
            this.http.get('/total/pur/get/yearOnYear.do',{params:{time:obj.name.substring(0,7),cmd:3}}).then((res) => {
              let plant = res.data.plant;
              that.data.yearOnYearData=plant;
              yearOnYear.hideLoading();
              yearOnYear.setOption(
                this.getOptionByYearOnYear(plant,that.data.yearOnyearconfig.yearonyearselect)
              )
            })
          }
        })
        
      },
      //设置产品同比参数
      getOptionByYearOnYear(data,key){
        const that = this;
        return {
              title: {
                text: '同比增长'
              },
              tooltip: {
                trigger: 'axis',
                axisPointer: {
                  type: 'shadow',
                  label: {
                    show: true
                  }
                },
                confine:true,
                // position:[120,0],
                extraCssText:this.global.$echartsconfig.tooltip.extraCssText,
                formatter(params) {
                  let tags = that.data.yearOnyearconfig.yearonyearselect=='num'?'个':'￥'
                  let param1=params[0], param2=params[1];
                  let obj1=param1.data,obj2=param2.data;
                  let circle1 = `<span style="display:inline-block;margin-right:5px;border-radius:50%;width:10px;height:10px;left:5px;background-color:`;//小圆点样式
                  let data1 = `${circle1}${param1.color}"></span>${param1.seriesName}：  ${param1.value} ${tags}<br/>
                  <div style='border: 1px solid white;border-radius: 5px;padding: 5px;'><span style='font-size:12px;margin-left: 45px;'>采购量:${obj1.num}个,采购额:${obj1.sum_price}￥</psan><br/>`;//第二行显示
                  let circle2 = `<span style="display:inline-block;margin-right:5px;border-radius:50%;width:10px;height:10px;left:5px;background-color:`;//小圆点样式
                  let data2 = `${circle2}${param2.color}"></span>${param2.seriesName}：  ${param2.value} ${tags}<br/>
                  <div style='border: 1px solid white;border-radius: 5px;padding: 5px;'><span style='font-size:12px;margin-left: 45px;'>采购量:${obj2.num}个,采购额:${obj2.sum_price}￥</psan><br/>
                    <span style='font-size:12px;margin-left: 45px;'> 同比采购增长：  ${param1.value<=0?'100.00%':((param2.value-param1.value)/param1.value*100).toFixed(2)+'%'}</div>`;//第一行显示
                  return `${param2.name}<br/>${data2}<br/>${param1.name}<br/>${data1}`;
                }
              },
              toolbox: this.global.echartsToolbox(),
              calculable: true,
              grid: {
                top: '12%',
                left: '1%',
                right: '10%',
                bottom: '5%',
                containLabel: true
              },
              xAxis: 
              {
                type: 'category',
                data:(()=>{
                  let list = [];
                  data.current.map((item,i)=>{
                    list.push(item.name+" 同比 "+data.SamePeriod[i].name);
                  })
                  return list;
                })(),
                axisLabel: {
                  show: true,
                  // interval: 0,
                  // color: '#ffffff',
                  rotate:40,
                  fontSize:9,
                  formatter: function(value) {
                    var current = value.split(" 同比 ")[0],samePeriod = value.split(" 同比 ")[1];
                    return samePeriod+"\n"+current
                  }
                }
              },
              yAxis: [
                {
                  type: 'value',
                  // axisLabel: {
                  //   formatter: function (a) {
                  //     a = +a;
                  //     return isFinite(a) ? echarts.format.addCommas(+a / 1000) : '';
                  //   }
                  // }
                }
              ],
              series: [
                {
                  name: '同期',
                  type: 'bar',
                  data:(()=>{
                    let list = [];
                    data.SamePeriod.map((item)=>{
                      list.push({value:item[key],...item});
                    })
                    return list;
                  })()
                },
                {
                  name: '本期',
                  type: 'bar',
                  data:(()=>{
                    let list = [];
                    data.current.map((item)=>{
                      list.push({value:item[key],...item});
                    })
                    return list;
                  })()
                }
              ]
            }
      },
      //环比
      loadRingRatio(){
        const echarts=this.echarts,that=this;
        let RingRatio = echarts.init(
          document.getElementById('RingRatio-chart')
        )
        RingRatio.showLoading();
        this.http.get('/total/pur/get/ringRatio.do',{params:{year:that.data.ringRatioConfig.defradio}}).then((res) => {
          let plant = res.data.plant;
          that.data.ringRatioData=plant;
          RingRatio.hideLoading();
          RingRatio.setOption(
            this.getOptionByRingRatio(that.data.ringRatioData,that.data.ringRatioConfig.ringRatioselect)
          )
        })
      },
      getOptionByRingRatio(data,key){
        const that=this;
        return {
            title: {
              text: '环比增长'
            },
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                type: 'shadow',
                label: {
                  show: true
                }
              },
              extraCssText:this.global.$echartsconfig.tooltip.extraCssText,
              formatter(params) {
                let tags = that.data.ringRatioConfig.ringRatioselect=='num'?'个':'￥'
                let param1=params[0], param2=params[1];
                let obj1=param1.data,obj2=param2.data;
                let circle1 = `<span style="display:inline-block;margin-right:5px;border-radius:50%;width:10px;height:10px;left:5px;background-color:`;//小圆点样式
                let data1 = `${circle1}${param1.color}"></span>${param1.seriesName}：  ${param1.value} ${tags}<br/>
                  <div style='border: 1px solid white;border-radius: 5px;padding: 5px;'><span style='font-size:12px;margin-left: 45px;'>
                    采购量：${obj1.num}个,采购额:${obj1.sum_price}￥</psan></div>`;//第二行显示
                let circle2 = `<span style="display:inline-block;margin-right:5px;border-radius:50%;width:10px;height:10px;left:5px;background-color:`;//小圆点样式
                let data2 = `${circle2}${param2.color}"></span>${param2.seriesName}：  ${param2.value} ${tags}<br/>
                  <div style='border: 1px solid white;border-radius: 5px;padding: 5px;'><span style='font-size:12px;margin-left: 45px;'>采购量：${obj2.num}个,采购额:${obj2.sum_price}￥</psan><br/>
                    <span style='font-size:12px;margin-left: 45px;'> 采购环比增长：  ${param1.value<=0?'100.00%':((param2.value-param1.value)/param1.value*100).toFixed(2)+'%'}</div>`;//第三行显示
                //let circle3 = `<span style="display:inline-block;margin-right:5px;border-radius:50%;width:10px;height:10px;left:5px;background-color:`;//小圆点样式
                //let data3 = `${circle3}red"></span>环比增长：  ${param1.value<=0?'100.00%':((param2.value-param1.value)/param1.value*100).toFixed(2)+'%'}`;//第三行显示
                return `${param2.name}<br/>${data2}<br/>${param1.name}<br/>${data1}`;
              }
            },
            toolbox: this.global.echartsToolbox(),
            calculable: true,
            grid: {
              top: '12%',
              left: '1%',
              right: '10%',
              bottom: '5%',
              containLabel: true
            },
            xAxis: 
            {
              type: 'category',
              data:(()=>{
                let list = [];
                data.current.map((item,i)=>{
                  list.push(item.name+"环比"+data.previous[i].name);
                })
                return list;
              })(),
              axisLabel: {
                show: true,
                fontSize:9,
                rotate:40,
                formatter: function(value) {
                  var current = value.split("环比")[0],previous = value.split("环比")[1];
                  return previous+"\n"+current
                }
              }
            },
            yAxis: [
              {
                type: 'value'
              }
            ],
            series: [
              {
                name: '上期',
                type: 'bar',
                data:(()=>{
                  let list = [];
                  data.previous.map((item)=>{
                    list.push({value:item[key],...item});
                  })
                  return list;
                })()
              },
              {
                name: '本期',
                type: 'bar',
                data:(()=>{
                  let list = [];
                  data.current.map((item)=>{
                    list.push({value:item[key],...item});
                  })
                  return list;
                })()
              }
            ]
          }
      }, 
    }
}
</script>

<style scoped>
#YearOnYear-chart,#RingRatio-chart{
  height: 250px;
  width: 100%;
  
}
.item3>div{
  float: left;
}
.item4{
  width: 70% !important;
}
.item4>div{
  float: left;
}
#dayTrend-chart,#buyTotalTread-chart{
  height: 250px;
  width: 50%;
}
.supplierreport_data,.producntreport_data{
  height: 220px;
}
#supplier-top-chart{
  height: 250px;
}
#product-top-chart{
  height: 250px;
}
.main-item-list{
  height: 60px;
}
.main-item-list div {
    width: calc(30%);
}
.main >div{
  width: 80px;
  height: 100%;
  /* background-color: red; */
  /* border: 1px solid black; */
  float: left;
  font-size: 12px;
}
.main>.left-div{
  width: 30%;
}
.main>.right-div{
  width: 70%;
}
@media screen and (min-width:1200px){
  .supplierreport_data,.producntreport_data{
    height: 260px;
  }
  #dayTrend-chart,#buyTotalTread-chart{
    height: 350px;
  }
  #supplier-top-chart{
    height: 350px;
  }
  #product-top-chart{
    height: 350px;
  }
  .main-item-list{
    height: 100px;
  }
  .main-item-list> div{
    height: 50px;
    padding-top: 10px;
  }
  #YearOnYear-chart,#RingRatio-chart{
    height: 350px;
  }
}
@media screen and (min-width:1400px){
  .main>.left-div{
    width: 25%;
  }
  .main>.right-div{
    width: 75%;
  }

}
</style>