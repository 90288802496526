<template>
  <div class="main">
    <div class="left-div">
      <div>
        <el-select v-show="showselectuser" v-model="selectuser" placeholder="未选择" size="large">
          <el-option
            v-for="item in baseinfo.staffs"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
        <div v-show="!showselectuser" style="height: 30px;font-size: 15px;background: beige;line-height: 30px;"><strong style="margin-left: 5px;">当前用户:</strong><span>{{username}}</span></div>
      </div>
      <div v-loading="loading.loading1">
        <div class="title">
          <h3>数据概览</h3>
        </div>
        <div class="main-item-process" style="position: relative;">
          <el-row>
            <el-tooltip
              class="box-item"
              effect="dark"
              :content="'销售额:'+data_obj.sale+'  总销售占比:'+data_obj.sale_ratio+'%'"
              placement="bottom-end"
            >
              <el-col :span="12"  class="main-item-process-left">销售:{{data_obj.sale}}/{{data_obj.sale_ratio}}%</el-col>
            </el-tooltip>
            <el-tooltip
              class="box-item"
              effect="dark"
              :content="'销售品种数量:'+data_obj.cover_num+' 总品种占比:'+data_obj.cover_ratio+'%'"
              placement="bottom-end"
            >
              <el-col :span="12" class="main-item-process-right">品种:{{data_obj.cover_num}} / {{data_obj.cover_ratio}}%</el-col>
            </el-tooltip>
          </el-row>
          <el-progress :percentage="100" status="success" />
        </div>
        <div class="main-item-list" >
          <div v-for="(item,i) in data" :key="i">
            <h6>{{item.title}}</h6>
            <h3>{{ item.num }}</h3>
          </div>
        </div>
      </div>
      <!-- 客户级别分类饼图 -->
      <div style="height: 150px;">
        <div id="customer-level-chart" style="height:150px;width: 50%;float: left;"></div>
        <div id="customer-label-chart" style="height:150px;width: 50%;float: left;"></div>
      </div>
      <!-- 客户成交排行 -->
      <div>
          <div class="selectYeardiv">
            <el-date-picker  size="small" style="width:100px;"
              v-model="pickerconfig.defcustoermsort"
              type="year"
              clearable
              :editable="pickerconfig.editable"
              placeholder="起始时间"
            />
          </div>
        <div id="customer-top-chart"></div>
      </div>
    </div>
    <div class="right-div">
      <!-- 销售基本数据 -->
      <div class="title">
        <el-radio-group v-model="pageconfig.reprotlabel" size="small">
          <el-radio-button label="customer">客户报表</el-radio-button>
          <el-radio-button label="product">产品报表</el-radio-button>
        </el-radio-group>
      </div>
      <!-- 客户维度的报表 -->
      <div v-loading="loading.loading2" v-show="pageconfig.table_customer">
        <div style="position: relative;top: 0px;">
          <el-input v-model="table_data.customer_data_config.query" style="width:150px;" 
          @clear="()=>{
            this.loadCustomerTotal(this.selectuser);
          }"
          size="small" 
          placeholder="customer key" 
          clearable />
          <el-button size="small" style="margin-left: 5px;"
          @click="()=>{
            this.loadCustomerTotal(this.selectuser);
          }"
          >关键字搜索</el-button>
        </div>
        <el-table
          :data="table_data.customer_data"
          :highlight-current-row="true"
          @header-click="(column)=>{
              if(column.sortable){
                this.table_data.customer_data_sort.property=column.property;
                this.table_data.customer_data_sort.order=column.order;
                this.table_data.customer_data_config.start=0;//排序后重置分页起始页
                this.table_data.customer_data_config.defaultCurrentPage=1;//排序后设为当前第一页
                this.loadCustomerTotal(this.selectuser);
              }
          }"
          style="width: 100%" class="customer_data" size="small" @row-dblclick="(row)=>{
            this.customerPanel.open(this.selectuser,row.id);
          }">
          <el-table-column
            column-key="id"
            prop="name"
            label="客户"
            >
          </el-table-column>
          <el-table-column label="销售量/销售额">
            <el-table-column
              sortable="true"
              :sort-orders="tablesort"
              prop="day10_price"
              label="10天 数量/金额"
              >
              <!-- 自定义渲染单列，使用vue插槽语法 -->
              <template #default="{row}">
                <div>{{row.day10_num}}/<span :style="row.day10_price>0?'color: red;':''">{{row.day10_price}}</span></div>
              </template>
            </el-table-column>
            <el-table-column
              sortable="true"
              prop="day30_price"
              label="30天 数量/金额"
              >
              <template #default="{row}">
                <div>{{row.day30_num}}/<span :style="row.day30_price>0?'color: red;':''">{{row.day30_price}}</span></div>
              </template>
            </el-table-column>
            <el-table-column
            sortable="true"
              prop="day120_price"
              label="季度 数量/金额"
              >
              <template #default="{row}">
                <div>{{row.day120_num}}/<span :style="row.day120_price>0?'color: red;':''">{{row.day120_price}}</span></div>
              </template>
            </el-table-column>
            <el-table-column
              sortable="true"
              prop="year1_price"
              label="年 数量/金额"
              >
              <template #default="{row}">
                <div>{{row.year1_num}}/<span :style="row.year1_price>0?'color: red;':''">{{row.year1_price}}</span></div>
              </template>
            </el-table-column>
            <el-table-column
              sortable="true"
              prop="sum_price"
              label="总数 数量/金额"
              >
              <template #default="{row}">
                <div>{{row.sum_num}}/<span :style="row.sum_price>0?'color: red;':''">{{row.sum_price}}</span></div>
              </template>
            </el-table-column>
          </el-table-column>
        </el-table>
        <div class="pagination">
          <el-pagination layout="prev, pager, next, jumper, ->, total" 
          :total="table_data.customer_data_config.totalProperty" 
          :page-size="table_data.customer_data_config.limit"
          :current-page="table_data.customer_data_config.defaultCurrentPage"
          @current-change="(page)=>{
            this.table_data.customer_data_config.start=(page-1)*this.table_data.customer_data_config.limit;
            this.table_data.customer_data_config.defaultCurrentPage=page;
            this.loadCustomerTotal(this.selectuser);
          }" />
        </div>
      </div>
      <!-- 产品维度的报表 -->
      <div v-loading="loading.loading3" v-show="pageconfig.table_product">
        <div style="position: relative;top: 0px;">
          <el-input v-model="table_data.product_data_config.query" style="width:150px;" 
          @clear="()=>{
            this.loadProductTotal(this.selectuser);
          }"
          size="small" 
          placeholder="number key" 
          clearable />
          <el-button size="small" style="margin-left: 5px;"
          @click="()=>{
            this.loadProductTotal(this.selectuser);
          }"
          >编号搜索</el-button>
        </div>
        <el-table
          :data="table_data.product_data"
          :highlight-current-row="true"
          @header-click="(column)=>{
              if(column.sortable){
                this.table_data.product_data_sort.property=column.property;
                this.table_data.product_data_sort.order=column.order;
                this.table_data.product_data_config.start=0;//排序后重置分页起始页
                this.table_data.product_data_config.defaultCurrentPage=1;//排序后设为当前第一页
                this.loadProductTotal(this.selectuser);
              }
          }"
          @row-dblclick="(row)=>{
            this.custProPanel.open(this.selectuser,row.number);
          }"
          style="width: 100%" class="customer_data" size="small">
          <el-table-column
            column-key="id"
            prop="number"
            label="产品"
            >
          </el-table-column>
          <el-table-column label="销售量/销售额">
            <el-table-column
              sortable="true"
              :sort-orders="tablesort"
              prop="day10_price"
              label="10天 数量/金额"
              >
              <!-- 自定义渲染单列，使用vue插槽语法 -->
              <template #default="{row}">
                <div>{{row.day10_num}}/<span :style="row.day10_price>0?'color: red;':''">{{row.day10_price}}</span></div>
              </template>
            </el-table-column>
            <el-table-column
              sortable="true"
              prop="day30_price"
              label="30天 数量/金额"
              >
              <template #default="{row}">
                <div>{{row.day30_num}}/<span :style="row.day30_price>0?'color: red;':''">{{row.day30_price}}</span></div>
              </template>
            </el-table-column>
            <el-table-column
            sortable="true"
              prop="day120_price"
              label="季度 数量/金额"
              >
              <template #default="{row}">
                <div>{{row.day120_num}}/<span :style="row.day120_price>0?'color: red;':''">{{row.day120_price}}</span></div>
              </template>
            </el-table-column>
            <el-table-column
              sortable="true"
              prop="year1_price"
              label="年 数量/金额"
              >
              <template #default="{row}">
                <div>{{row.year1_num}}/<span :style="row.year1_price>0?'color: red;':''">{{row.year1_price}}</span></div>
              </template>
            </el-table-column>
            <el-table-column
              sortable="true"
              prop="sum_price"
              label="总数 数量/金额"
              >
              <template #default="{row}">
                <div>{{row.sum_num}}/<span :style="row.sum_price>0?'color: red;':''">{{row.sum_price}}</span></div>
              </template>
            </el-table-column>
          </el-table-column>
        </el-table>
        <div class="pagination">
          <el-pagination layout="prev, pager, next, jumper, ->, total" 
          :total="table_data.product_data_config.totalProperty" 
          :page-size="table_data.product_data_config.limit"
          :current-page="table_data.product_data_config.defaultCurrentPage"
          @current-change="(page)=>{
            this.table_data.product_data_config.start=(page-1)*this.table_data.product_data_config.limit;
            this.table_data.product_data_config.defaultCurrentPage=page;
            this.loadProductTotal(this.selectuser);
          }" />
        </div>
      </div>
      <div>
        <div style="float: left; width: 50%;">
          <div class="selectYeardiv">
            <el-date-picker  size="small" style="width:100px;"
              v-model="pickerconfig.defyear"
              :clearable="pickerconfig.clearable"
              :editable="pickerconfig.editable"
              type="year"
              placeholder="选择一个年份"
            />
            <el-radio-group v-model="table_data.yearonyearselect" size="small" style="vertical-align: top;" >
              <el-radio-button label="num">数量</el-radio-button>
              <el-radio-button label="sum_price">金额</el-radio-button>
            </el-radio-group>
          </div>
          <!-- 同比增长率 -->
          <div id="YearOnYear-chart"></div>
        </div>
        <div style="float: left; width: 50%;">
          <div class="selectYeardiv">
            <el-date-picker  size="small" style="width:100px;"
              v-model="pickerconfig.defradio"
              type="year"
              :clearable="pickerconfig.clearable"
              :editable="pickerconfig.editable"
              placeholder="选择一个年份"
            />
            <el-radio-group v-model="table_data.ringRatioselect" size="small" style="vertical-align: top;" >
              <el-radio-button label="num">数量</el-radio-button>
              <el-radio-button label="sum_price">金额</el-radio-button>
            </el-radio-group>
          </div>
          <!-- 环比增长率 -->
          <div id="RingRatio-chart"></div>
        </div>
      </div>
    </div>
    <div style="width:100%;height:450px;">
      <div style="width:33.3%;height:100%;float: left;">
        <h4>员工<strong>{{rankingtitle}}</strong>排行Top10({{rankingcycle}})</h4>
        <div class="selectYeardiv" style="left: 20px;width: 300px;">
          <el-radio-group size="default" v-model="sales_ranking_config.cycle" style="vertical-align: top;" >
            <el-radio-button label="sum">总</el-radio-button>
            <el-radio-button label="year">年</el-radio-button>
            <el-radio-button label="quarter">季度</el-radio-button>
            <el-radio-button label="month">月</el-radio-button>
          </el-radio-group>
          <el-select style="width:100px;margin-left: 5px;" v-model="sales_ranking_config.rankingOptiondef" class="m-2" placeholder="要排行的数据" size="default">
              <el-option
                v-for="item in sales_ranking_config.rankingOption"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
          </el-select>
        </div>
        <div>
          <ul class="salerankingpanel" :style="{background: 'url('+saletop+')'}">
            <li>
              <div style="width: 100%;text-align: center;line-height: 38px;font-weight: 900;color: darkorange;font-size: 17px;" >{{rankingcycle}}{{rankingtitle}}排名前10位</div>
            </li>
            <li  v-for="(item) in table_data.rankingList" :key="item.id">
              <div style="width:35px;height:35px;float: left;">
                <img :src='item.header_img' style="width:35px;height:35px;border-radius: 50px;"/>
              </div>
              <div style="width:150px;line-height: 30px;float: left;">
                <span style="margin-left: 65px;font-weight: 900;">{{item.name}}</span>
              </div>
              <div style="width:110px;line-height: 30px;font-size: 12px;float: left;text-align: right;color: burlywood;font-weight: 900;">
                <span>{{item[sales_ranking_config.rankingOptiondef]  }} {{company}}</span>
              </div>
                
            </li>
            
          </ul>
        </div>  
      </div>
      <div style="width:33.3%;height:100%;float: left;">
        <h4>客户<strong>{{rankingtitle}}</strong>排行Top10({{rankingcycle}})</h4>
        <div class="selectYeardiv" style="left: 20px;width: 300px;">
          <el-radio-group size="default" v-model="cus_ranking_config.cycle" style="vertical-align: top;" >
            <el-radio-button label="sum">总</el-radio-button>
            <el-radio-button label="year">年</el-radio-button>
            <el-radio-button label="quarter">季度</el-radio-button>
            <el-radio-button label="month">月</el-radio-button>
          </el-radio-group>
          <el-select style="width:100px;margin-left: 5px;" v-model="cus_ranking_config.rankingOptiondef" class="m-2" placeholder="要排行的数据" size="default">
              <el-option
                v-for="item in cus_ranking_config.rankingOption"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
          </el-select>
        </div>
        <div>
          <ul class="salerankingpanel" :style="{background: 'url('+saletop+')'}">
            <li>
              <div style="width: 100%;text-align: center;line-height: 38px;font-weight: 900;color: darkorange;font-size: 17px;" >{{cusRankingcycle}}{{cusRankingtitle}}排名前10位</div>
            </li>
            <li  v-for="(item) in table_data.cusRankingList" :key="item.id">
              <div style="width:35px;height:35px;float: left;">
                
              </div>
              <el-tooltip
                    class="box-item"
                    effect="dark"
                    :content="item.name"
                    placement="bottom-end"
                    >
              <div style="width:150px;line-height: 30px;float: left;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">
                <span style="margin-left: 65px;font-weight: 900;">{{item.name}}</span>
              </div>
              </el-tooltip>
              <div style="width:110px;line-height: 30px;font-size: 12px;float: left;text-align: right;color: burlywood;font-weight: 900;">
                <span>{{item[cus_ranking_config.rankingOptiondef]  }} {{cusCompany}}</span>
              </div>
                
            </li>
            
          </ul>
        </div>  
      </div>
      <div style="width:33.3%;height:100%;float: left;">
        <h4>产品<strong>{{productRankingtitle}}</strong>排行Top10({{productRankingcycle}})</h4>
        <div class="selectYeardiv" style="left: 20px;width: 300px;">
          <el-radio-group size="default" v-model="product_ranking_config.cycle" style="vertical-align: top;" >
            <el-radio-button label="sum">总</el-radio-button>
            <el-radio-button label="year">年</el-radio-button>
            <el-radio-button label="quarter">季度</el-radio-button>
            <el-radio-button label="month">月</el-radio-button>
          </el-radio-group>
          <el-select style="width:100px;margin-left: 5px;" v-model="product_ranking_config.rankingOptiondef" class="m-2" placeholder="要排行的数据" size="default">
              <el-option
                v-for="item in product_ranking_config.rankingOption"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
          </el-select>
        </div>
        <div>
          <ul class="salerankingpanel" :style="{background: 'url('+saletop+')'}">
            <li>
              <div style="width: 100%;text-align: center;line-height: 38px;font-weight: 900;color: darkorange;font-size: 17px;" >{{productRankingcycle}}{{productRankingtitle}}排名前10个</div>
            </li>
            <li  v-for="(item) in table_data.productRankingList" :key="item.id">
              <div style="width:35px;height:35px;float: left;">
                <img :src='item.first_img_url' style="width:35px;height:35px;border-radius: 50px;"/>
              </div>
              <el-tooltip
                    class="box-item"
                    effect="dark"
                    :content="item.number"
                    placement="bottom-end"
                    >
              <div style="width:150px;line-height: 30px;float: left;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">
                <span style="margin-left: 65px;font-weight: 900;">{{item.number}}</span>
              </div>
              </el-tooltip>
              <div style="width:110px;line-height: 30px;font-size: 12px;float: left;text-align: right;color: burlywood;font-weight: 900;">
                <span>{{item[product_ranking_config.rankingOptiondef]  }} {{productCompany}}</span>
              </div>
                
            </li>
            
          </ul>
        </div>  
      </div>
    </div>
  </div>
  <CustomerPanel ref="customerPanel"></CustomerPanel>
  <CustProPanel ref="custProPanel"></CustProPanel>
</template>

<script>
import {ref,reactive,getCurrentInstance} from "vue"
import CustomerPanel from '@/components/CustomerPanel.vue'
import CustProPanel from '@/components/CustProPanel.vue'
import saletop from '@/assets/img/saletop.png'
let showselectuser = ref(false);
const loading = reactive({
    loading1:false,//数据概览的加载
    loading2:false,//客户报表的加载
    loading3:false,//产品报表的加载
})
const defbutton1=ref('同比增长率')
export default {
    name:'SalesAnalysis',
    components:{CustomerPanel,CustProPanel},
    setup() {
      const {appContext} = getCurrentInstance();
      const echarts = appContext.config.globalProperties.$echarts;
      const global = appContext.config.globalProperties;
      const http = global.$http;
      let selectuser = ref(null);
      let username=ref(null);
      let customerPanel=ref(null);//客户分析面板
      let custProPanel = ref(null);//产品分析面板
      //同环比的日期选择
      const pickerconfig = reactive({editable:false,clearable:false,defyear:(new Date().getFullYear())+'',defradio:(new Date().getFullYear())+'',defcustoermsort:''});
      const baseinfo=reactive({
          staffs:[],
          user:{}
      });
      const pageconfig=reactive({reprotlabel:'customer',table_customer:true,table_product:false});
      const sales_ranking_config=reactive({
          cycle:'sum',//默认跨度为总数据排行
          rankingOption:[//可以排行的数据
              {value:'order_num',label:'交易订单'},
              {value:'product_num',label:'产品数量'},
              {value:'sale_price',label:'销售额'},
              {value:'profit',label:'利润'},
              {value:'profit_rate',label:'利润率'}
          ],
          rankingOptiondef:'sale_price',//默认选择的排行数据
      });
      const cus_ranking_config=reactive({
          cycle:'sum',//默认跨度为总数据排行
          rankingOption:[//可以排行的数据
              {value:'order_num',label:'交易订单'},
              {value:'product_num',label:'产品数量'},
              {value:'sale_price',label:'销售额'},
              {value:'profit',label:'利润'},
              {value:'profit_rate',label:'利润率'}
          ],
          rankingOptiondef:'sale_price',//默认选择的排行数据
      });
      const product_ranking_config=reactive({
          cycle:'sum',//默认跨度为总数据排行
          rankingOption:[//可以排行的数据
              {value:'order_num',label:'交易订单'},
              {value:'product_num',label:'产品数量'},
              {value:'sale_price',label:'销售额'},
              {value:'profit',label:'利润'},
              {value:'profit_rate',label:'利润率'}
          ],
          rankingOptiondef:'sale_price',//默认选择的排行数据
      });
      const data =reactive([
        {key:'sale_price',title:'销售额',num:"0"},
        {key:'profit',title:'利润额',num:"0"},
        {key:'profit_ratio',title:'利润率%',num:"0"},
        {key:'order_num',title:'交易',num:"0"},
        {key:'out_num',title:'出库',num:"0"},
        {key:'customer_num',title:'客户',num:"0"},
        {key:'effective_customer',title:'成交客户',num:"0"},
        {key:'potential_customer',title:'未成交客户',num:"0"}
      ])
      //数据概览线条上面的数据
      const data_obj = reactive({sale:0.00,sale_ratio:0.00,cover_num:0,cover_ratio:0});
      const table_data=reactive({
        customer_data:[],
        customer_data_config:{limit:10,start:0,totalProperty:0,defaultCurrentPage:1,query:''},//表格配置
        customer_data_sort:{property:'day10_price',order:'desc'},//记住客户报表表格的排序方式
        product_data:[],
        product_data_config:{limit:10,start:0,totalProperty:0,defaultCurrentPage:1,query:''},//表格配置
        product_data_sort:{property:'day10_price',order:'desc'},//记住产品报表表格的排序方式
        yearOnYearData:[],//同比数据
        ringRatioData:[],//环比数据
        yearonyearselect:'sum_price',//同比按钮切换
        ringRatioselect:'sum_price',//环比按钮切换数据
        rankingList:[],//排行列表
        cusRankingList:[],//客户排行榜
        productRankingList:[],//产品排行榜
      });
      return{
        baseinfo,
        selectuser,
        username,
        table_data,
        echarts,
        defbutton1,
        data,
        data_obj,
        tablesort:['ascending', 'descending'],
        global,
        showselectuser,
        http,
        loading,
        pageconfig,
        pickerconfig,
        customerPanel,
        custProPanel,
        sales_ranking_config,
        cus_ranking_config,
        product_ranking_config,
        saletop
      }
    },
    computed:{
      rankingtitle(){
        let result="";
        this.sales_ranking_config.rankingOption.map((d)=>{
          if(d.value==this.sales_ranking_config.rankingOptiondef){
            result=d.label;
          }
        });
        return result;
      },
      rankingcycle(){
        let result="";
        if("sum"==this.sales_ranking_config.cycle){
          result= "总";
        }else if("year"==this.sales_ranking_config.cycle){
          result= "当年";
        }else if("quarter"==this.sales_ranking_config.cycle){
          result= "当季度";
        }else if("month"==this.sales_ranking_config.cycle){
          result= "当月";
        }
        return result;
      },
      company(){//计算排行榜单位
        let result="";
        if(this.sales_ranking_config.rankingOptiondef=='order_num'){
          result='笔';
        }else if(this.sales_ranking_config.rankingOptiondef=='product_num'){
          result='PCS';
        }else if(this.sales_ranking_config.rankingOptiondef=='sale_price'){
          result='￥';
        }else if(this.sales_ranking_config.rankingOptiondef=='profit'){
          result='￥';
        }else if(this.sales_ranking_config.rankingOptiondef=='profit_rate'){
          result='%';
        }
        return result;
      },
      cusRankingtitle(){//客户排行榜
        let result="";
        this.cus_ranking_config.rankingOption.map((d)=>{
          if(d.value==this.cus_ranking_config.rankingOptiondef){
            result=d.label;
          }
        });
        return result;
      },
      cusRankingcycle(){
        let result="";
        if("sum"==this.cus_ranking_config.cycle){
          result= "总";
        }else if("year"==this.cus_ranking_config.cycle){
          result= "当年";
        }else if("quarter"==this.cus_ranking_config.cycle){
          result= "当季度";
        }else if("month"==this.cus_ranking_config.cycle){
          result= "当月";
        }
        return result;
      },
      cusCompany(){//计算排行榜单位
        let result="";
        if(this.cus_ranking_config.rankingOptiondef=='order_num'){
          result='笔';
        }else if(this.cus_ranking_config.rankingOptiondef=='product_num'){
          result='PCS';
        }else if(this.cus_ranking_config.rankingOptiondef=='sale_price'){
          result='￥';
        }else if(this.cus_ranking_config.rankingOptiondef=='profit'){
          result='￥';
        }else if(this.cus_ranking_config.rankingOptiondef=='profit_rate'){
          result='%';
        }
        return result;
      },
      productRankingtitle(){//产品排行榜
        let result="";
        this.product_ranking_config.rankingOption.map((d)=>{
          if(d.value==this.product_ranking_config.rankingOptiondef){
            result=d.label;
          }
        });
        return result;
      },
      productRankingcycle(){
        let result="";
        if("sum"==this.product_ranking_config.cycle){
          result= "总";
        }else if("year"==this.product_ranking_config.cycle){
          result= "当年";
        }else if("quarter"==this.product_ranking_config.cycle){
          result= "当季度";
        }else if("month"==this.product_ranking_config.cycle){
          result= "当月";
        }
        return result;
      },
      productCompany(){//计算排行榜单位
        let result="";
        if(this.product_ranking_config.rankingOptiondef=='order_num'){
          result='笔';
        }else if(this.product_ranking_config.rankingOptiondef=='product_num'){
          result='PCS';
        }else if(this.product_ranking_config.rankingOptiondef=='sale_price'){
          result='￥';
        }else if(this.product_ranking_config.rankingOptiondef=='profit'){
          result='￥';
        }else if(this.product_ranking_config.rankingOptiondef=='profit_rate'){
          result='%';
        }
        return result;
      }
    },
    watch:{
      "selectuser"(newVal){
          this.clearData();
          this.initPage(newVal);
      },
      "pageconfig.reprotlabel"(newVal){
        if(newVal=='customer'){//选择客户报表时（客户为维度统计）
          this.pageconfig.table_customer=true;
          this.pageconfig.table_product=false;
          this.loadCustomerTotal(this.selectuser);//重新加载一下表数据
        }else if(newVal=='product'){//选择产品报表时（产品为维度统计）
          this.pageconfig.table_customer=false;
          this.pageconfig.table_product=true;
          this.loadProductTotal(this.selectuser);//重新加载一下表数据
        }
      },
      "pickerconfig.defyear"(newVal){//同比选择时间的组件值发生变化时重新获取数据
        if(newVal){
          const that = this;
          let yearonyear = this.echarts.getInstanceByDom(document.getElementById('YearOnYear-chart'));
          yearonyear.showLoading();
          this.http.get('/total/sale/get/yearOnYear.do',{params:{staffid:that.selectuser,time:new Date(newVal).getFullYear(),cmd:1}}).then((res) => {
            let plant = res.data.plant;
            that.table_data.yearOnYearData=plant;
            yearonyear.hideLoading();
            yearonyear.setOption(
              this.getOptionByYearOnYear(plant,that.table_data.yearonyearselect)
            )
          })
        }
      },
      "table_data.yearonyearselect"(label){//切换数量和金额
        let yearonyear = this.echarts.getInstanceByDom(document.getElementById('YearOnYear-chart'));
        yearonyear.setOption(this.getOptionByYearOnYear(this.table_data.yearOnYearData,label));
      },
      "pickerconfig.defradio"(newVal){//环比选择时间的组件值发生变化时重新获取数据
        if(newVal){
          const that = this;
          let ringRatio = this.echarts.getInstanceByDom(document.getElementById('RingRatio-chart'));
          ringRatio.showLoading();
          this.http.get('/total/sale/get/ringRatio.do',{params:{staffid:that.selectuser,year:new Date(newVal).getFullYear()}}).then((res) => {
            let plant = res.data.plant;
            that.table_data.ringRatioData=plant;
            ringRatio.hideLoading();
            ringRatio.setOption(
              this.getOptionByRingRatio(plant,that.table_data.ringRatioselect)
            )
          })
        }
      },
      "table_data.ringRatioselect"(label){//切换数量和金额
        let ringRatio = this.echarts.getInstanceByDom(document.getElementById('RingRatio-chart'));
        ringRatio.setOption(this.getOptionByRingRatio(this.table_data.ringRatioData,label));
      },
      "pickerconfig.defcustoermsort"(newVal){
        const that = this;
        const topchart = this.echarts.getInstanceByDom(
          document.getElementById('customer-top-chart')
        )
        topchart.showLoading();
        this.http.get('/total/sale/get/customerTop.do',{params:{time:new Date(newVal||"1979-01-01").getFullYear(),orderby:' sum_price desc',staffid:that.selectuser}}).then((res) => {
          topchart.hideLoading();
          if(res.data.success){
            that.setOptionCustomerTop(res.data.plant);
          }
        })
      },
      "sales_ranking_config.cycle"(newVal){// 排行维度切换
        this.loadRankingList(newVal,this.sales_ranking_config.rankingOptiondef);
      },
      "sales_ranking_config.rankingOptiondef"(newVal){//排行字段切换
        this.loadRankingList(this.sales_ranking_config.cycle,newVal);
      },
      "cus_ranking_config.cycle"(newVal){// 排行维度切换(客户)
        this.loadCusRankingList(newVal,this.cus_ranking_config.rankingOptiondef,this.selectuser);
      },
      "cus_ranking_config.rankingOptiondef"(newVal){//排行字段切换(客户)
        this.loadCusRankingList(this.cus_ranking_config.cycle,newVal,this.selectuser);
      },
      "product_ranking_config.cycle"(newVal){// 排行维度切换(产品)
        this.loadProductRankingList(newVal,this.product_ranking_config.rankingOptiondef);
      },
      "product_ranking_config.rankingOptiondef"(newVal){//排行字段切换(产品)
        this.loadProductRankingList(this.product_ranking_config.cycle,newVal,this.selectuser);
      },
    },
    mounted(){
      const that = this;
      if(!this.global.$user){//页面挂载之前还没有加载用户信息时重新加载一下用户信息
        that.http.get('/total/overview/get/users.do').then((res) => {
          let plant = res.data.plant;
          if(res.data.success){//初始化路由
            that.global.$user=plant.user;
            that.global.$list_user=plant.list_users;
            that.baseinfo.staffs = that.global.$list_user;
            that.baseinfo.user=that.global.$user;
            that.selectuser=that.global.$user.id;
            that.username=this.baseinfo.user.name;
          }else{
            that.global.error({message:"获取信息失败!"});
          }
        });
      }else{
        this.baseinfo.staffs = this.global.$list_user;
        this.baseinfo.user=this.global.$user;
        this.username=this.baseinfo.user.name
        this.selectuser=this.global.$user.id;
      }
      this.renderCharts();
      this.loadRankingList(this.sales_ranking_config.cycle,this.sales_ranking_config.rankingOptiondef);//加载销售员排行列表
      this.loadProductRankingList(this.product_ranking_config.cycle,this.product_ranking_config.rankingOptiondef);//加载产品排行
    },
    methods:{
      renderCharts(){//渲染化图表，图标无需多次渲染
        //渲染客户级别和标签分组图形表 start
        this.echarts.init(
          document.getElementById('customer-level-chart')
        )
        this.echarts.init(
          document.getElementById('customer-label-chart')
        )
        //渲染客户排行图标 start
        this.echarts.init(
          document.getElementById('customer-top-chart')
        )
        //渲染同比增长 start
        const yearOnYear = this.echarts.init(
          document.getElementById('YearOnYear-chart')
        )
        this.loadYearOnYearClick(yearOnYear);//图表钻取，点击事件
        //渲染环比增长 start
        this.echarts.init(
          document.getElementById('RingRatio-chart')
        )
      },
      clearData(){//清除一些初始化数据
        this.table_data.customer_data_config.query='';//清除过滤条件值(客户)
        this.table_data.product_data_config.query='';//清除过滤条件值(产品)
      },
      initPage(staffid){//初始化页面数据
        const that =this;
        if(this.global.$user.login=='admin'||this.global.$user.role_id==10||this.global.$user.role_id==8){
          this.showselectuser=true;
        }else{
          this.showselectuser=false;
        }
        //加载概览数据
        that.loading.loading1=true;
        this.http.get('/total/sale/get/staffTotal.do',{params:{staffid:staffid}}).then((res) => {
          that.loading.loading1=false;
          let obj = res.data.plant[0];
          if(obj){
            for(var d in that.data){
              that.data[d].num=obj[that.data[d].key];
            }
            that.data_obj.sale=obj.sale_price;
            that.data_obj.sale_ratio=obj.sale_ratio;
            that.data_obj.cover_num=obj.cover_num;
            that.data_obj.cover_ratio=obj.cover_ratio;
          }else{
            that.data_obj.sale=0.00;that.data_obj.sale_ratio=0.00;that.data_obj.cover_num=0;that.data_obj.cover_ratio=0;
            that.data.map((item)=>{
              item.num="0";
            }) 
          }
        })
        //初始化客户级别分组图形表 start
        const levelchart = this.echarts.getInstanceByDom(
          document.getElementById('customer-level-chart')
        )
        this.setOptionLevelPie([{value:0,name:'无'}]);
        levelchart.showLoading();
        this.http.get('/total/sale/get/customerGroup.do',{params:{cmd:1,staffid:staffid}}).then((res) => {
          levelchart.hideLoading();
          if(res.data.success){
            that.setOptionLevelPie(res.data.plant);
          }
        })
        const labelchart = this.echarts.getInstanceByDom(
          document.getElementById('customer-label-chart')
        )
        this.setOptionLabelPie([{value:0,name:'无'}]);
        labelchart.showLoading();
        this.http.get('/total/sale/get/customerGroup.do',{params:{cmd:2,staffid:staffid}}).then((res) => {
          labelchart.hideLoading();
          if(res.data.success){
            that.setOptionLabelPie(res.data.plant);
          }
        })
        //初始化客户级别分组图形表 end
        //初始化客户排行图标 start
        const topchart = this.echarts.getInstanceByDom(
          document.getElementById('customer-top-chart')
        )
        topchart.showLoading();
        this.http.get('/total/sale/get/customerTop.do',{params:{time:new Date(that.pickerconfig.defcustoermsort||"1979-01-01").getFullYear(),orderby:' sum_price desc',staffid:staffid}}).then((res) => {
          topchart.hideLoading();
          if(res.data.success){
            that.setOptionCustomerTop(res.data.plant);
          }
        })
        this.setOptionCustomerTop([{num:0,sum_price:0.0,name:'无'}]);
        //初始化客户排行图标 end
        //初始化表数据 start
        this.loadCustomerTotal(staffid);
        //初始化表数据 end
        //初始化同比增长 start
        this.echarts.getInstanceByDom(
          document.getElementById('YearOnYear-chart')
        )
        this.loadYearOnYear(staffid);
        //初始化同比增长 end
        //初始化环比比增长 start
        this.loadRingRatio(staffid);
        //初始化环比比增长 end
        //销售员客户排行榜
        this.loadCusRankingList(this.cus_ranking_config.cycle,this.cus_ranking_config.rankingOptiondef,staffid);
      }, 
      setOptionLevelPie(data){//加载客户级别饼图
        const that=this
        let level = that.echarts.getInstanceByDom(
          document.getElementById('customer-level-chart')
        )
        level.setOption(
          {
            title: {
              text: '客户级别占比',
              left: 'left',
              textStyle:{
                fontSize: 13,
              },
              top:'0%'
            },
            legend: {
              orient: "vertical",
              left: "right",
              top: "0%",
              textStyle: {
                color: "#0043ffa6",
                fontSize: 10,
              },
              itemGap: 4,
              itemWidth: 10,
              itemHeight: 5,
              height: 300,
            },
            tooltip: {
              trigger: 'item',
              confine:true,
            },
            series: [
              {
                name: '客户级别',
                type: 'pie',
                radius: ['30%', '80%'],
                label:{
                  show:false
                },
                data:data,
                emphasis: {
                  itemStyle: {
                    shadowBlur: 10,
                    shadowOffsetX: 0,
                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                  }
                }
              }
            ]
          }
        )
      },
      setOptionLabelPie(data){//加载客户标签分类饼图
        const that=this
        let label = that.echarts.getInstanceByDom(
          document.getElementById('customer-label-chart')
        )
        label.setOption(
          {
            title: {
              text: '客户标签占比',
              left: 'right',
              textStyle:{
                fontSize: 13,
              },
              top:'0%'
            },
            legend: {
              orient: "vertical",
              left: "top",
              top: "0%",
              textStyle: {
                color: "#0043ffa6",
                fontSize: 10,
              },
              itemGap: 4,
              itemWidth: 10,
              itemHeight: 5,
              height: 300,
            },
            tooltip: {
              trigger: 'item'
            },
            series: [
              {
                name: '客户标签',
                type: 'pie',
                radius: ['30%', '80%'],
                label:{
                  show:false
                },
                data:data,
                emphasis: {
                  itemStyle: {
                    shadowBlur: 10,
                    shadowOffsetX: 0,
                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                  }
                }
              }
            ]
          }
        )
      },
      setOptionCustomerTop(data){//加载客户成交排行
        const that = this
        let customerTop = that.echarts.getInstanceByDom(
          document.getElementById('customer-top-chart')
        )
        customerTop.setOption(
          {
            title: {
              text: 'Top客户'
            },
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                type: 'shadow'
              },
              confine:true,
              extraCssText:that.global.$echartsconfig.tooltip.extraCssText,
              formatter(params) {
                let param1=params[0],data=param1.data;
                let circle1 = `<span style="display:inline-block;margin-right:5px;border-radius:50%;width:10px;height:10px;left:5px;background-color:`;//小圆点样式
                let data1 = `${circle1}${param1.color}"></span>${param1.seriesName}：  ${param1.value}`;//第一行行显示
                let param2=params[1];
                let circle2 = `<span style="display:inline-block;margin-right:5px;border-radius:50%;width:10px;height:10px;left:5px;background-color:`;//小圆点样式
                let data2 = `${circle2}${param2.color}"></span>${param2.seriesName}：  ${param2.value/10}`;//第二行显示
                let text=`<div style='font-size:12px; margin-left:40px;border: 1px solid white;border-radius: 5px;padding: 5px;'>购买数量:${data.num}个 购买金额:${data.sum_price}￥<br/>产生利润:${data.profit}￥ 利润率:${data.profit_rate}% </div>`
                return `<h3>${param1.name}</h3> 级别:${param1.data.title_level}  标签:${param1.data.title_label}<br/>${data1}<br/>${data2}${text}`;
              }
            },
            dataZoom:[
              {
                yAxisIndex:0,
                type:'inside',
                startValue:0,
                endValue:9
              }
            ],
            toolbox: this.global.echartsToolbox(),
            grid: {
              left: '1%',
              right: '4%',
              bottom: '1%',
              top:"15%",
              containLabel: true
            },
            xAxis: {
              type: 'value',
              boundaryGap: [0, 0.01],
              axisLabel:{
                fontSize:9,
              }
            },
            yAxis: {
              type: 'category',
              inverse:true,//排序
              data:(()=>{
                let list = [];
                data.map((item)=>{
                  list.push(item.name);
                })
                return list;
              })(),
              axisLabel: {
                show: true,
                // rotate:40,
                formatter: function(value) {
                  if(value&&value.length>5){
                    return value.substring(0,5)+'...'
                  }
                  return value
                }
              }
            },
            series:[{
                name:'销售额',
                type: 'bar',
                stack: 'total',
                data:(()=>{
                    let list = [];
                    data.map((item)=>{
                      list.push({value:item.sum_price,...item});
                    })
                    return list;
                  })()
            },{
                name:'产品数量',
                type: 'bar',
                stack: 'total',
                data:(()=>{
                    let list = [];
                    data.map((item)=>{
                      list.push({value:item.num*10,...item});
                    })
                    return list;
                  })()
            }]
          }
        )
      },
      loadCustomerTotal(staffid){//加载客户统计表数据
        const that = this;
        this.loading.loading2=true;
        this.http.get('/total/sale/get/totalForCustomer.do',
        {params:{...that.table_data.customer_data_config,
          orderby:`${that.table_data.customer_data_sort.property} ${that.table_data.customer_data_sort.order=='ascending'? 'asc':'desc'}`,staffid:staffid}})
          .then((res) => {
          that.loading.loading2=false;
          if(res.data.success){
            that.table_data.customer_data=res.data.plant;
            that.table_data.customer_data_config.totalProperty=res.data.totalProperty[0].count;
          }
        })
      },
      loadProductTotal(staffid){//加载产品统计表数据
        const that = this;
        this.loading.loading3=true;
        this.http.get('/total/sale/get/totalForProduct.do',
        {params:{...that.table_data.product_data_config,
          orderby:`${that.table_data.product_data_sort.property} ${that.table_data.product_data_sort.order=='ascending'? 'asc':'desc'}`,staffid:staffid}})
          .then((res) => {
          that.loading.loading3=false;
          if(res.data.success){
            that.table_data.product_data=res.data.plant;
            that.table_data.product_data_config.totalProperty=res.data.totalProperty[0].count;
          }
        })
      },
      loadYearOnYear(staffid){
        const that =this;
        const echarts = this.echarts
        let yearOnYear = echarts.getInstanceByDom(
          document.getElementById('YearOnYear-chart')
        )
        yearOnYear.showLoading();
        this.http.get('/total/sale/get/yearOnYear.do',{params:{staffid:staffid,time:new Date(this.pickerconfig.defyear).getFullYear(),cmd:1}}).then((res) => {
          let plant = res.data.plant;
          that.table_data.yearOnYearData=plant;
          yearOnYear.hideLoading();
          yearOnYear.setOption(
            this.getOptionByYearOnYear(plant,that.table_data.yearonyearselect)
          )
        })
      },
      loadYearOnYearClick(yearOnYear){//初始图表的点击事件
        const that =this;
        yearOnYear.on('click',(obj)=>{//向下钻取
          if(obj.name.indexOf('季度')!=-1&&obj.seriesIndex==1){
            yearOnYear.showLoading();
            this.http.get('/total/sale/get/yearOnYear.do',{params:{staffid:that.selectuser,time:new Date(that.pickerconfig.defyear).getFullYear(),cmd:2}}).then((res) => {
              let plant = res.data.plant;
              that.table_data.yearOnYearData=plant;
              yearOnYear.hideLoading();
              yearOnYear.setOption(
                this.getOptionByYearOnYear(plant,that.table_data.yearonyearselect)
              )
            })
            // tread.setOption(this.saleTotalOption()) 
          }else if(obj.name.indexOf('月')!=-1&&obj.seriesIndex==1){
            yearOnYear.showLoading();
            this.http.get('/total/sale/get/yearOnYear.do',{params:{staffid:that.selectuser,time:obj.name.substring(0,7),cmd:3}}).then((res) => {
              let plant = res.data.plant;
              that.table_data.yearOnYearData=plant;
              yearOnYear.hideLoading();
              yearOnYear.setOption(
                this.getOptionByYearOnYear(plant,that.table_data.yearonyearselect)
              )
            })
          }
          
        })
      },
      //设置产品同比参数
      getOptionByYearOnYear(data,key){
        const that = this;
        return {
              title: {
                text: '同比增长'
              },
              tooltip: {
                trigger: 'axis',
                axisPointer: {
                  type: 'shadow',
                  label: {
                    show: true
                  }
                },
                extraCssText:this.global.$echartsconfig.tooltip.extraCssText,
                formatter(params) {
                  let tags = that.table_data.yearonyearselect=='num'?'个':'￥'
                  let param1=params[0], param2=params[1];
                  let obj1=param1.data,obj2=param2.data;
                  let circle1 = `<span style="display:inline-block;margin-right:5px;border-radius:50%;width:10px;height:10px;left:5px;background-color:`;//小圆点样式
                  let data1 = `${circle1}${param1.color}"></span>${param1.seriesName}：  ${param1.value} ${tags}<br/>
                  <div style='border: 1px solid white;border-radius: 5px;padding: 5px;'><span style='font-size:12px;margin-left: 45px;'>交易订单：${obj1.order_num}笔,销售量：${obj1.num}个,销售额:${obj1.sum_price}￥</psan><br/>
                    <span style='font-size:12px;margin-left: 45px;'> 利润:${obj1.profit||0.00} ￥, 利润率:%${obj1.profit_rate||0.00} </span></div><br/>`;//第二行显示
                  let circle2 = `<span style="display:inline-block;margin-right:5px;border-radius:50%;width:10px;height:10px;left:5px;background-color:`;//小圆点样式
                  let data2 = `${circle2}${param2.color}"></span>${param2.seriesName}：  ${param2.value} ${tags}<br/>
                  <div style='border: 1px solid white;border-radius: 5px;padding: 5px;'><span style='font-size:12px;margin-left: 45px;'>交易订单：${obj2.order_num}笔,销售量：${obj2.num}个,销售额:${obj2.sum_price}￥</psan><br/>
                    <span style='font-size:12px;margin-left: 45px;'> 利润:${obj2.profit||0.00} ￥, 利润率:%${obj2.profit_rate||0.00} </span><br/>
                    <span style='font-size:12px;margin-left: 45px;'> 同比销售额增长：  ${param1.value<=0?'100.00%':((param2.value-param1.value)/param1.value*100).toFixed(2)+'%'},
                      同比利润率增长：${(obj2.profit_rate-obj1.profit_rate).toFixed(2)} % </span></div>`;//第一行显示
                 // let circle3 = `<span style="display:inline-block;margin-right:5px;border-radius:50%;width:10px;height:10px;left:5px;background-color:`;//小圆点样式
                 // let data3 = `${circle3}red"></span>同比增长：  ${param1.value<=0?'100.00%':((param2.value-param1.value)/param1.value*100).toFixed(2)+'%'}`;//第三行显示
                 let totaldata_text=`<div style='border: 1px solid white;border-radius: 5px;padding: 5px;'>${(that.pickerconfig.defyear instanceof Date)? new Date(that.pickerconfig.defyear).getFullYear():that.pickerconfig.defyear}年总计<div  style='font-size:12px;margin-left: 45px;'>交易订单：${obj2.sum_order_num}笔,销售量:${obj2.sum_num}<br/> 销售额:${obj2.sum_sale_price}￥成本:${obj2.sum_cost_price}￥<br/> 利润:${obj2.sum_profit}￥ 利润率:${obj2.sum_profit_rate}%</div></div>`
                  return `${totaldata_text}${param2.name}<br/>${data2}<br/>${param1.name}<br/>${data1}`;
                }
              },
              toolbox: this.global.echartsToolbox(),
              calculable: true,
              grid: {
                top: '12%',
                left: '1%',
                right: '10%',
                bottom: '5%',
                containLabel: true
              },
              xAxis: 
              {
                type: 'category',
                data:(()=>{
                  let list = [];
                  data.current.map((item,i)=>{
                    list.push(item.name+" 同比 "+data.SamePeriod[i].name);
                  })
                  return list;
                })(),
                axisLabel: {
                  show: true,
                  // interval: 0,
                  // color: '#ffffff',
                  fontSize:9,
                  rotate:40,
                  formatter: function(value) {
                    var current = value.split(" 同比 ")[0],samePeriod = value.split(" 同比 ")[1];
                    return samePeriod+"\n"+current
                  }
                }
              },
              yAxis: [
                {
                  type: 'value',
                  // axisLabel: {
                  //   formatter: function (a) {
                  //     a = +a;
                  //     return isFinite(a) ? echarts.format.addCommas(+a / 1000) : '';
                  //   }
                  // }
                }
              ],
              series: [
                {
                  name: '同期',
                  type: 'bar',
                  data:(()=>{
                    let list = [];
                    data.SamePeriod.map((item)=>{
                      list.push({value:item[key],...item});
                    })
                    return list;
                  })()
                },
                {
                  name: '本期',
                  type: 'bar',
                  data:(()=>{
                    let list = [];
                    data.current.map((item)=>{
                      list.push({value:item[key],...item});
                    })
                    return list;
                  })()
                }
              ]
            }
      },
      loadRingRatio(staffid){
        const echarts=this.echarts,that=this;
        let RingRatio = echarts.getInstanceByDom(
          document.getElementById('RingRatio-chart')
        )
        RingRatio.showLoading();
        this.http.get('/total/sale/get/ringRatio.do',{params:{staffid:staffid,year:that.pickerconfig.defradio}}).then((res) => {
          let plant = res.data.plant;
          that.table_data.ringRatioData=plant;
          RingRatio.hideLoading();
          RingRatio.setOption(
            this.getOptionByRingRatio(that.table_data.ringRatioData,that.table_data.ringRatioselect)
          )
        })
      },
      getOptionByRingRatio(data,key){
        const that=this;
        return {
            title: {
              text: '环比增长'
            },
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                type: 'shadow',
                label: {
                  show: true
                }
              },
              extraCssText:this.global.$echartsconfig.tooltip.extraCssText,
              formatter(params) {
                let tags = that.table_data.ringRatioselect=='num'?'个':'￥'
                let param1=params[0], param2=params[1];
                let obj1=param1.data,obj2=param2.data;
                let circle1 = `<span style="display:inline-block;margin-right:5px;border-radius:50%;width:10px;height:10px;left:5px;background-color:`;//小圆点样式
                let data1 = `${circle1}${param1.color}"></span>${param1.seriesName}：  ${param1.value} ${tags}<br/>
                  <div style='border: 1px solid white;border-radius: 5px;padding: 5px;'><span style='font-size:12px;margin-left: 45px;'>
                    销售量：${obj1.num}个,销售额:${obj1.sum_price}￥</psan><br/>
                    <span style='font-size:12px;margin-left: 45px;'> 利润:${obj1.profit||0.00} ￥, 利润率:%${obj1.profit_ratio||0.00} </span></div><br/>`;//第二行显示
                let circle2 = `<span style="display:inline-block;margin-right:5px;border-radius:50%;width:10px;height:10px;left:5px;background-color:`;//小圆点样式
                let data2 = `${circle2}${param2.color}"></span>${param2.seriesName}：  ${param2.value} ${tags}<br/>
                  <div style='border: 1px solid white;border-radius: 5px;padding: 5px;'><span style='font-size:12px;margin-left: 45px;'>销售量：${obj2.num}个,销售额:${obj2.sum_price}￥</psan><br/>
                    <span style='font-size:12px;margin-left: 45px;'> 利润:${obj2.profit||0.00} ￥, 利润率:%${obj2.profit_ratio||0.00} </span><br/>
                    <span style='font-size:12px;margin-left: 45px;'> 销售环比增长：  ${param1.value<=0?'100.00%':((param2.value-param1.value)/param1.value*100).toFixed(2)+'%'},
                      利润率环比增长：${(obj2.profit_ratio-obj1.profit_ratio).toFixed(2)} % </span></div>`;//第三行显示
                //let circle3 = `<span style="display:inline-block;margin-right:5px;border-radius:50%;width:10px;height:10px;left:5px;background-color:`;//小圆点样式
                //let data3 = `${circle3}red"></span>环比增长：  ${param1.value<=0?'100.00%':((param2.value-param1.value)/param1.value*100).toFixed(2)+'%'}`;//第三行显示
                return `${param2.name}<br/>${data2}<br/>${param1.name}<br/>${data1}`;
              }
            },
            toolbox: this.global.echartsToolbox(),
            calculable: true,
            grid: {
              top: '12%',
              left: '1%',
              right: '10%',
              bottom: '5%',
              containLabel: true
            },
            xAxis: 
            {
              type: 'category',
              data:(()=>{
                let list = [];
                data.current.map((item,i)=>{
                  list.push(item.name+"环比"+data.previous[i].name);
                })
                return list;
              })(),
              axisLabel: {
                show: true,
                fontSize:9,
                rotate:40,
                formatter: function(value) {
                  var current = value.split("环比")[0],previous = value.split("环比")[1];
                  return previous+"\n"+current
                }
              }
            },
            yAxis: [
              {
                type: 'value'
              }
            ],
            series: [
              {
                name: '上期',
                type: 'bar',
                data:(()=>{
                  let list = [];
                  data.previous.map((item)=>{
                    list.push({value:item[key],...item});
                  })
                  return list;
                })()
              },
              {
                name: '本期',
                type: 'bar',
                data:(()=>{
                  let list = [];
                  data.current.map((item)=>{
                    list.push({value:item[key],...item});
                  })
                  return list;
                })()
              }
            ]
          }
      },  
      loadRankingList(cmd,orderby){//获取排行列表
        const that = this;
        this.http.get('/total/sale/get/rankingList.do',{params:{cmd:cmd,orderby:orderby+' desc'}}).then((res) => {
          that.table_data.rankingList = res.data.plant;
        })
      },
      loadCusRankingList(cmd,orderby,staffid){//获取当前员工的客户排行榜
        const that = this;
        this.http.get('/total/sale/get/cusRankingList.do',{params:{cmd:cmd,orderby:orderby+' desc',staffid:staffid}}).then((res) => {
          that.table_data.cusRankingList = res.data.plant;
        })
      },
      loadProductRankingList(cmd,orderby){//获取产品销售排行榜
        const that = this;
        this.http.get('/total/sale/get/productRankingList.do',{params:{cmd:cmd,orderby:orderby+' desc'}}).then((res) => {
          that.table_data.productRankingList = res.data.plant;
        })
      }
    }
}
</script>

<style scoped>
.salerankingpanel{
  width:340px; 
  height:510px;
  list-style:none;
  padding-left:18px;
  margin-top: 50px;
}
.salerankingpanel >li{
  width: 100%;
  height: 45px;
}
#YearOnYear-chart,#RingRatio-chart{
  height: 230px;
  width: 100%;
}
.customer_data{
  height: 220px;
}
#customer-top-chart{
  height: 200px;
}
.main >div{
  width: 33%;
  height: 100%;
  /* background-color: red; */
  /* border: 1px solid black; */
  float: left;
}
.main-item-list{
  height: 100px;
}
.main>.left-div{
  width: 30%;
}
.main>.right-div{
  width: 70%;
}
@media screen and (min-width:1200px){
  #customer-top-chart{
    height: 230px;
  }
  .customer_data{
    height: 250px;
  }
  .main-item-list{
    height: 130px;
  }
  .main-item-list> div{
    height: 40px;
    padding-top: 2px;
  }
  #YearOnYear-chart,#RingRatio-chart{
    height: 260px;
    width: 100%;
  }
}
@media screen and (min-width:1400px){
  #customer-top-chart{
    height: 350px;
  }
  .customer_data{
    height: 280px;
  }
  .main-item-list{
    height: 160px;
  }
  .main-item-list> div{
    height: 50px;
    padding-top: 10px;
  }
  .main>.left-div{
    width: 25%;
  }
  .main>.right-div{
    width: 75%;
  }
  #YearOnYear-chart,#RingRatio-chart{
    height: 370px;
    width: 100%;
  }
}
</style>